import React from 'react';
import { Chip } from '@mui/material';

const InvoiceStatusChip = ({ invoiceStatus }) => {
  console.log(invoiceStatus);
  if (invoiceStatus === 'Open') {
    return <Chip label="Open" color="secondary" size="small" variant="outlined" />;
  }
  if (invoiceStatus === 'Past Due') {
    return <Chip label="Past Due" color="error" size="small" variant="outlined" />;
  }
  return <Chip label="Unknown State" color="error" size="small"></Chip>;
};

export default InvoiceStatusChip;
