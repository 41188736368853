import * as Yup from 'yup';

export const MYSInvoiceValidationSchema = Yup.object().shape({
  document_number: Yup.string().required('Document number is required'),
  document_date: Yup.date().required('Document date is required'),
  payment_mode: Yup.object().required('Payment mode is required'),
  payment_terms: Yup.string().required('Payment terms is required'),
  billed_by: Yup.object().required('Billed by is required'),
  billed_to: Yup.object().required('Billed to is required'),
  document_currency_code: Yup.string().required('Currency is required'),
  item_details: Yup.array().required('Item details is required').min(1, 'Item details is required'),
});

export default MYSInvoiceValidationSchema;
