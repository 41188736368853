import React from 'react';
import { Autocomplete, TextField } from '@mui/material';
import { Controller } from 'react-hook-form';
import { useSelector } from 'react-redux';

const UOMSelect = ({ name, label = 'Unit of Measure', control, errors, readOnly = false }) => {
  const codes = useSelector((state) => state.uomCodes.uomCodes);
  const options = codes.map((item) => ({ key: item.Code, value: item.Name }));

  return (
    <Controller
      name={name}
      control={control}
      render={({ field }) => (
        <Autocomplete
          {...field}
          options={options}
          readOnly={readOnly}
          getOptionLabel={(option) => option.value}
          renderInput={(params) => (
            <TextField {...params} label={label} error={!!errors[name]} helperText={errors[name]?.message} />
          )}
          isOptionEqualToValue={(option, value) => option.value === value.value}
          onChange={(_, value) => field.onChange(value)}
          value={field.value || null} // handle default value
        />
      )}
    />
  );
};

export default UOMSelect;
