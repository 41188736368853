import React from 'react';
import { Autocomplete, TextField } from '@mui/material';
import { Controller } from 'react-hook-form';
import { useSelector } from 'react-redux';

const CurrencySelect = ({ name, label = 'Currency', control, errors, readOnly = false }) => {
  const currencies = useSelector((state) => state.currencies.currencies);
  const currenciesOptions = currencies.map((item) => {
    return item.Code;
  });

  return (
    <Controller
      name={name}
      control={control}
      render={({ field }) => (
        <Autocomplete
          {...field}
          name={name}
          options={currenciesOptions}
          readOnly={readOnly}
          renderInput={(params) => (
            <TextField {...params} label={label} error={!!errors[name]} helperText={errors[name]?.message} />
          )}
          onChange={(_, value) => field.onChange(value)}
          value={field.value || null} // handle default value
        />
      )}
    />
  );
};

export default CurrencySelect;
