import React from 'react';
import { Typography, IconButton } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';

const OpenInvoicesAlert = ({ dueInvoices, onClose }) => (
  <div style={{ textAlign: 'left', flex: 1 }}>
    <Typography variant="body1" sx={{ fontSize: '1.2rem', fontWeight: 'bold', textAlign: 'center', mr: '5rem' }}>
      Reminder: You have an open invoice due by {''}
      {dueInvoices
        ?.filter((invoice) => invoice.status === 'open')
        ?.map((invoice) => new Date(invoice.due_date * 1000).toLocaleDateString())
        .join(', ')}
      . Please make the payment to avoid service disruptions.
    </Typography>
    <IconButton
      aria-label="close"
      color="inherit"
      size="small"
      onClick={onClose}
      sx={{
        position: 'absolute',
        top: '1.5rem',
        right: '5rem',
      }}
    >
      <CloseIcon />
    </IconButton>
  </div>
);

export default OpenInvoicesAlert;
