import { call, put, takeLatest } from 'redux-saga/effects';
import {
  cancelRefundFailure,
  cancelRefundRequest,
  cancelRefundSuccess,
  refundEDocFailure,
  refundEDocRequest,
  refundEDocSuccess,
  createRefundFailure,
  createRefundRequest,
  createRefundSuccess,
  fetchRefundById,
  fetchRefundByIdFailure,
  fetchRefundByIdSuccess,
  fetchRefundsFailure,
  fetchRefundsRequest,
  fetchRefundsSuccess,
} from './refundsSlice';
import { apiClient } from '../../utils/axios';
import { showSnackbar } from '../snackbarSlice';

function* fetchRefundsSaga(action) {
  try {
    const { query } = action.payload;
    const response = yield call(apiClient.get, '/refunds', { params: query });
    yield put(fetchRefundsSuccess(response.data));
  } catch (error) {
    yield put(fetchRefundsFailure(error.message));
  }
}

function* fetchRefundByIdSaga(action) {
  try {
    const response = yield call(apiClient.get, `/refunds/${action.payload}`);
    yield put(fetchRefundByIdSuccess(response.data));
  } catch (error) {
    yield put(fetchRefundByIdFailure(error.message));
  }
}

function* createRefundSaga(action) {
  try {
    const response = yield call(apiClient.post, '/refunds', action.payload);
    yield put(createRefundSuccess(response.data));
    yield put(showSnackbar({ message: 'Refund created successfully!', severity: 'success' }));
  } catch (error) {
    yield put(createRefundFailure(error.response?.data?.errors));
  }
}

function* refundEDocSaga(action) {
  try {
    const response = yield call(apiClient.put, `/refunds/${action.payload.document_id}`, action.payload);
    yield put(refundEDocSuccess(response.data));
    yield put(
      showSnackbar({
        message: 'Conversion request for Staged Refund to E-Refund accepted!',
        severity: 'success',
      }),
    );
  } catch (error) {
    yield put(refundEDocFailure(JSON.stringify(error.response?.data?.errors)));
  }
}

function* cancelRefundSaga(action) {
  try {
    const { url, reason } = action.payload;
    const data = { reason };
    const response = yield call(apiClient.put, url, data);
    yield put(cancelRefundSuccess(response.data));
  } catch (error) {
    yield put(cancelRefundFailure(error.response?.data?.errors));
  }
}

function* refundsSaga() {
  yield takeLatest(fetchRefundsRequest.type, fetchRefundsSaga);
  yield takeLatest(fetchRefundById.type, fetchRefundByIdSaga);
  yield takeLatest(createRefundRequest.type, createRefundSaga);
  yield takeLatest(refundEDocRequest.type, refundEDocSaga);
  yield takeLatest(cancelRefundRequest.type, cancelRefundSaga);
}

export default refundsSaga;
