import React from 'react';
import Box from '@mui/material/Box';
import { Divider, Grid, List, ListItem, ListItemText, Paper, TextField, Typography } from '@mui/material';
import { Controller, useFieldArray, useForm } from 'react-hook-form';
import Button from '@mui/material/Button';
import { yupResolver } from '@hookform/resolvers/yup';
import { lineItemValidationSchema } from '../../validations/LineItemValidation';
import { lineItemCalculation } from '../../utils/pricingEngineForDocuments';
import UOMSelect from './UOMSelect';
import ItemCodeSelect from './ItemCodeSelect';
import TaxTypeSelect from './TaxTypeSelect';
import NumberOrPercentageInput from '../NumberOrPercentageInput';
import { currencyFormatter } from '../../utils/currencyFormat';

const defaultState = {
  item_code: null,
  unit_of_measure: null,
  description: '',
  discount_amount: 0,
  charge_amount: 0,
  tax_amount: 0,
  quantity: 1,
  unit_rate: 1,
  discounts: [
    {
      value: 0,
      amount: 0,
      type: 'percentage',
    },
  ],
  charges: [
    {
      value: 0,
      amount: 0,
      type: 'percentage',
    },
  ],
  taxes: [
    {
      amount: 0,
      type: 'percentage',
      value: 0,
      category: null,
    },
  ],
};

const LineItemForm = ({ submitForm, handleClose, initialData = defaultState }) => {
  const {
    register,
    handleSubmit,
    watch,
    control,
    reset,
    formState: { errors },
  } = useForm({
    defaultValues: initialData,
    resolver: yupResolver(lineItemValidationSchema),
  });

  const {
    fields: discountFields,
    append: appendDiscount,
    remove: removeDiscount,
  } = useFieldArray({
    control: control,
    name: 'discounts',
  });

  const {
    fields: chargeFields,
    append: appendCharge,
    remove: removeCharge,
  } = useFieldArray({
    control: control,
    name: 'charges',
  });

  const {
    fields: taxFields,
    append: appendTax,
    remove: removeTax,
  } = useFieldArray({
    control: control,
    name: 'taxes',
  });

  const lineItemsData = watch();
  let pricingEngine = lineItemCalculation(lineItemsData);

  const handleSave = (data) => {
    const itemData = {
      ...data,
      item_code: data.item_code.key,
      unit_of_measure: data.unit_of_measure.key,
      taxes: data.taxes.map(({ category, ...rest }) => ({ category: category.key, ...rest })),
    };

    const lineItem = lineItemCalculation(itemData);
    submitForm(lineItem);
  };

  const handleSinglePopupFormSubmit = (data) => {
    handleSave(data);
    handleClose();
  };

  const handleMultiplePopupFormSubmit = (data) => {
    handleSave(data);
    reset(defaultState);
  };

  return (
    <Paper elevation={0} sx={{ p: '1rem' }}>
      <Box sx={{ p: 2 }}>
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <ItemCodeSelect control={control} errors={errors} name="item_code" />
          </Grid>

          {/* Item Description */}
          <Grid item xs={12}>
            <Controller
              name="description"
              control={control}
              render={({ field }) => (
                <TextField
                  label="Item Description"
                  variant="outlined"
                  name={'description'}
                  fullWidth
                  {...field}
                  error={!!errors.description}
                  helperText={errors.description?.message}
                />
              )}
            />
          </Grid>

          {/* Unit of Measure */}
          <Grid item xs={4}>
            <UOMSelect control={control} errors={errors} name="unit_of_measure" />
          </Grid>

          {/* Quantity */}
          <Grid item xs={4}>
            <Controller
              name="quantity"
              control={control}
              render={({ field }) => (
                <TextField
                  {...field}
                  label="Quantity"
                  variant="outlined"
                  fullWidth
                  name="quantity"
                  type="number"
                  error={!!errors.quantity}
                  helperText={errors.quantity?.message}
                  onBlur={() => {
                    if (field.value) {
                      const trimmedValue = parseFloat(field.value);
                      field.onChange(trimmedValue);
                    }
                  }}
                  onChange={(event) => {
                    const inputValue = event.target.value;
                    if (/^\d*\.?\d{0,8}$/.test(inputValue) || inputValue === '') {
                      field.onChange(inputValue);
                    }
                  }}
                />
              )}
            />
          </Grid>

          {/* Rate */}
          <Grid item xs={4}>
            <Controller
              name="unit_rate"
              control={control}
              render={({ field }) => (
                <TextField
                  label="Unit Price"
                  variant="outlined"
                  fullWidth
                  type="number"
                  name="unit_rate"
                  {...field}
                  error={!!errors.unit_rate}
                  helperText={errors.unit_rate?.message}
                  onBlur={() => {
                    if (field.value) {
                      const trimmedValue = parseFloat(field.value);
                      field.onChange(trimmedValue);
                    }
                  }}
                  onChange={(event) => {
                    const inputValue = event.target.value;
                    if (/^\d*\.?\d{0,8}$/.test(inputValue) || inputValue === '') {
                      field.onChange(inputValue);
                    }
                  }}
                />
              )}
            />
          </Grid>
        </Grid>

        <Typography variant="h6" sx={{ mb: '1.5rem', pt: '1rem' }}>
          Discounts & Charges
          <Divider></Divider>
        </Typography>

        {discountFields.map((charge, discountIndex) => (
          <Grid key={charge.id} container spacing={2} rowspacing={2} sx={{ pb: 3 }}>
            <Grid item md={6}>
              <NumberOrPercentageInput
                value={`discounts.${discountIndex}.value`}
                type={`discounts.${discountIndex}.type`}
                id={'discounts-value'}
                label={'Discounts Value'}
                control={control}
                errors={!!errors.discounts?.[discountIndex]}
                helperText={errors.discounts?.[discountIndex]?.value?.message}
                decimal={8}
              />
            </Grid>
            <Grid item md={6}>
              <Controller
                name={`discounts.${discountIndex}.reason`}
                control={control}
                render={({ field }) => (
                  <TextField
                    {...field}
                    id="charge-reason"
                    fullWidth
                    name="reason"
                    label="Discount Reason"
                    variant="outlined"
                  />
                )}
              />
            </Grid>
          </Grid>
        ))}
        {chargeFields.map((charge, chargeIndex) => (
          <Grid key={charge.id} container spacing={2} rowspacing={2}>
            <Grid item md={6}>
              <NumberOrPercentageInput
                value={`charges.${chargeIndex}.value`}
                type={`charges.${chargeIndex}.type`}
                id={'charges-value'}
                label={'Charges Value'}
                control={control}
                error={!!errors.charges?.[chargeIndex]?.value}
                helperText={errors.charges?.[chargeIndex]?.value?.message}
                decimal={8}
              />
            </Grid>
            <Grid item md={6}>
              <Controller
                name={`charges.${chargeIndex}.reason`}
                control={control}
                render={({ field }) => (
                  <TextField
                    {...field}
                    id="charge-reason"
                    fullWidth
                    name="reason"
                    label="Charge Reason"
                    variant="outlined"
                  />
                )}
              />
            </Grid>
          </Grid>
        ))}

        <Typography variant="h6" sx={{ mb: '1.5rem', pt: '1rem' }}>
          Taxes
          <Divider></Divider>
        </Typography>

        {taxFields.map((charge, taxIndex) => (
          <Grid key={charge.id} container spacing={2} rowspacing={2}>
            <Grid item md={6}>
              <TaxTypeSelect control={control} errors={errors} name={`taxes.${taxIndex}.category`} />
            </Grid>
            <Grid item md={6}>
              <NumberOrPercentageInput
                value={`taxes.${taxIndex}.value`}
                type={`taxes.${taxIndex}.type`}
                id={'taxes-value'}
                label={'Taxes Value'}
                control={control}
                error={!!errors.taxes?.[taxIndex]?.value}
                helperText={errors.taxes?.[taxIndex]?.value?.message}
              />
            </Grid>
          </Grid>
        ))}
      </Box>
      <Divider sx={{ py: 1 }} />
      <Box sx={{ display: 'flex', justifyContent: 'flex-end' }}>
        <List sx={{ width: '50%' }}>
          <ListItem>
            <ListItemText primary="Base amount" />
            <Typography variant="body1">{currencyFormatter().format(pricingEngine.base_amount)}</Typography>
          </ListItem>
          <ListItem>
            <ListItemText primary="Discount" />
            <Typography variant="body1" color="error">
              -{currencyFormatter().format(pricingEngine.discount_amount)}
            </Typography>
          </ListItem>
          <ListItem>
            <ListItemText primary="Charge" />
            <Typography variant="body1">{currencyFormatter().format(pricingEngine.charge_amount)}</Typography>
          </ListItem>
          <ListItem>
            <ListItemText primary="Total before tax" />
            <Typography variant="body1">{currencyFormatter().format(pricingEngine.net_amount)}</Typography>
          </ListItem>
          <ListItem>
            <ListItemText primary="Tax" />
            <Typography variant="body1">{currencyFormatter().format(pricingEngine.tax_amount)}</Typography>
          </ListItem>
          <Divider />
          <ListItem>
            <ListItemText primaryTypographyProps={{ variant: 'h6' }} primary="Sub Total" />
            <Typography variant="h6">{currencyFormatter().format(pricingEngine.total_amount)}</Typography>
          </ListItem>
        </List>
      </Box>
      <Divider />
      <Box
        sx={{
          px: 2,
          py: 1,
          borderTop: '1px solid #e0e0e0',
          display: 'flex',
          justifyContent: 'flex-end',
          gap: 1,
        }}
      >
        <Button
          type="button"
          variant="contained"
          color="primary"
          onClick={handleSubmit(handleSinglePopupFormSubmit)} // Add the item when clicked
        >
          Save Item
        </Button>
        <Button
          type="button"
          variant="contained"
          color="primary"
          onClick={handleSubmit(handleMultiplePopupFormSubmit)} // Add the item when clicked
        >
          Save & Add new item
        </Button>
      </Box>
    </Paper>
  );
};

export default LineItemForm;
