import { call, put, takeEvery } from 'redux-saga/effects';
import { apiClient } from '../../utils/axios';
import {
  fetchParticipantRequest,
  fetchParticipantSuccess,
  fetchParticipantFailure,
  fetchEndpointIdRequest,
  fetchEndpointIdSuccess,
  fetchEndpointIdFailure,
  createParticipantRequest,
  createParticipantSuccess,
  createParticipantFailure,
  kycSignRequest,
  kycSignSuccess,
  kycSignFailure,
  activateSmlRequest,
  activateSmlSuccess,
  activateSmlFailure,
  activatePeppolDirectoryRequest,
  activatePeppolDirectorySuccess,
  activatePeppolDirectoryFailure,
} from './participantsSlice';
import { showSnackbar } from '../snackbarSlice';

function* fetchParticipantSaga(action) {
  try {
    const { special_identifier, identifier, identifier_code } = action.payload;
    const response = yield call(
      apiClient.get,
      `/participants?special_identifier=${special_identifier}&identifier=${identifier}&identifier_code=${identifier_code}`,
    );
    yield put(fetchParticipantSuccess(response.data));
    yield put(showSnackbar({ message: 'Successfully retrieved participant details', severity: 'success' }));
  } catch (error) {
    yield put(fetchParticipantFailure(error.message));
    yield put(showSnackbar({ message: 'Failed to fetch participant details', severity: 'error' }));
  }
}

function* fetchEndpointIdSaga() {
  try {
    const response = yield call(apiClient.get, `/participants/endpoint`);
    yield put(fetchEndpointIdSuccess(response.data.endpoint[0].endpointId));
  } catch (error) {
    yield put(fetchEndpointIdFailure(error.message));
  }
}

function* createParticipantSaga(action) {
  try {
    const response = yield call(apiClient.post, '/participants', action.payload);
    yield put(createParticipantSuccess(response.data));
    yield put(showSnackbar({ message: 'Participant created successfully', severity: 'success' }));
  } catch (error) {
    yield put(createParticipantFailure(error?.response.data.message || error.message));
    yield put(showSnackbar({ message: error?.response.data.message, severity: 'error' }));
  }
}

function* kycSignSaga(action) {
  try {
    const { pid, file } = action.payload;
    const formData = new FormData();
    formData.append('file', file);
    yield call(apiClient.post, `/participants/${pid}/kyc-sign`, formData, {
      headers: {
        'Content-Type': 'multipart/form-data',
      },
    });
    yield put(kycSignSuccess());
    yield put(showSnackbar({ message: 'KYC signed successfully', severity: 'success' }));
  } catch (error) {
    yield put(kycSignFailure(error?.response.data.message || error.message));
    yield put(showSnackbar({ message: error?.response.data.message, severity: 'error' }));
  }
}

function* activateSmlSaga(action) {
  try {
    const { pid } = action.payload;
    yield call(apiClient.put, `/participants/${pid}/activate-sml`);
    yield put(activateSmlSuccess());
    yield put(showSnackbar({ message: 'Activated in SML successfully', severity: 'success' }));
  } catch (error) {
    yield put(activateSmlFailure(error?.response.data.message || error.message));
    yield put(showSnackbar({ message: error?.response.data.message, severity: 'error' }));
  }
}

function* activatePeppolDirectorySaga(action) {
  try {
    const { pid } = action.payload;
    yield call(apiClient.put, `/participants/${pid}/activate-peppol-directory`);
    yield put(activatePeppolDirectorySuccess());
    yield put(showSnackbar({ message: 'Activated in Peppol Directory successfully', severity: 'success' }));
  } catch (error) {
    yield put(activatePeppolDirectoryFailure(error?.response.data.message || error.message));
    yield put(showSnackbar({ message: error?.response.data.message, severity: 'error' }));
  }
}

export default function* participantsSaga() {
  yield takeEvery(fetchParticipantRequest.type, fetchParticipantSaga);
  yield takeEvery(fetchEndpointIdRequest.type, fetchEndpointIdSaga);
  yield takeEvery(createParticipantRequest.type, createParticipantSaga);
  yield takeEvery(kycSignRequest.type, kycSignSaga);
  yield takeEvery(activateSmlRequest.type, activateSmlSaga);
  yield takeEvery(activatePeppolDirectoryRequest.type, activatePeppolDirectorySaga);
}
