import { all } from 'redux-saga/effects';
import invoicesSaga from './invoices/invoicesSaga';
import loginSaga from './auth/loginSaga';
import pdfTemplateSaga from './pdfTemplates/pdfTemplateSaga';
import creditNotesSaga from './creditNotes/creditNotesSaga';
import refundsSaga from './refunds/refundsSaga';
import debitNotesSaga from './debitNotes/debitNotesSaga';
import businessProfilesSaga from './businessProfiles/businessProfilesSaga';
import constantsSaga from './constants/constantsSaga';
import customersSaga from './customers/customersSaga';
import suppliersSaga from './suppliers/suppliersSaga';
import orgSaga from './organizations/orgSaga';
import bulkUploadSaga from './bulkUpload/bulkUploadSaga';
import participantsSaga from './participants/participantsSaga';
import dashboardSaga from './dashboard/dashboardSaga';
import peppolMessagesSaga from './peppolMessages/peppolMessagesSaga';

export default function* rootSaga() {
  yield all([
    invoicesSaga(),
    loginSaga(),
    orgSaga(),
    pdfTemplateSaga(),
    creditNotesSaga(),
    refundsSaga(),
    debitNotesSaga(),
    businessProfilesSaga(),
    constantsSaga(),
    customersSaga(),
    suppliersSaga(),
    bulkUploadSaga(),
    participantsSaga(),
    dashboardSaga(),
    peppolMessagesSaga(),
  ]);
}
