import React, { useEffect } from 'react';
import { Box, Button, Divider, Fade, Grid, IconButton, Modal, TextField, Typography } from '@mui/material';
import { Controller, useFieldArray, useForm } from 'react-hook-form';
import CloseIcon from '@mui/icons-material/Close';
import { yupResolver } from '@hookform/resolvers/yup';
import { DiscountItemValidationSchema } from '../../../validations/DiscountValidation';
import pricingEngineForDocuments from '../../../utils/pricingEngineForDocuments';
import NumberOrPercentageInput from '../../../components/NumberOrPercentageInput';

const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: '500px',
  bgcolor: 'background.paper',
  borderRadius: '15px',
  boxShadow: 24,
};

export default function InvoiceDiscountPopup({
  watch,
  open,
  onClose,
  discountEditing,
  replaceDiscount,
  setDiscountEditing,
  discountAppend,
  openPopup,
}) {
  useEffect(() => {
    if (discountEditing) {
      resetPopupForm({
        discounts: watch('discounts'),
      });
      openPopup();
    }
  }, [discountEditing]);

  const {
    handleSubmit: handlePopupSubmit,
    control: popupControl,
    reset: resetPopupForm,
    formState: { errors: popupErrors },
  } = useForm({
    defaultValues: {
      discounts: [
        {
          type: 'percentage',
          amount: 0,
          value: 0,
          reason: '',
        },
      ],
    },
    resolver: yupResolver(DiscountItemValidationSchema),
  });

  useEffect(() => {
    if (open) return;
    resetPopupForm({
      discounts: [
        {
          type: 'percentage',
          amount: 0,
          value: 0,
          reason: '',
        },
      ],
    });
    setDiscountEditing(false);
  }, [open]);

  const { fields: discountFields } = useFieldArray({
    control: popupControl,
    name: 'discounts',
  });

  const handleSubmitForm = (data) => {
    const { discounts } = pricingEngineForDocuments(watch('item_details'), watch('charges'), data.discounts);
    if (discountEditing) {
      replaceDiscount(discounts);
      setDiscountEditing(false);
      onClose();
    } else {
      discountAppend(discounts);
    }
    onClose();
  };

  return (
    <Modal
      open={open}
      onClose={(event, reason) => {
        if (reason !== 'backdropClick') {
          onClose;
        }
      }}
      className={'backdrop-model'}
    >
      <Fade in={open}>
        <Box sx={style}>
          <Box
            sx={{
              display: 'flex',
              justifyContent: 'space-between',
              alignItems: 'center',
              px: 2,
              py: 2,
              borderBottom: '1px solid #e0e0e0',
            }}
          >
            <Typography variant="h6">Add Discount</Typography>
            <IconButton onClick={onClose}>
              <CloseIcon />
            </IconButton>
          </Box>
          <Divider />
          <Box sx={{ p: 2 }}>
            {discountFields.map((discount, discountIndex) => (
              <Grid key={discount.id} container spacing={2} rowspacing={2}>
                <Grid item md={12}>
                  <NumberOrPercentageInput
                    value={`discounts.${discountIndex}.value`}
                    type={`discounts.${discountIndex}.type`}
                    id={'discounts-value'}
                    label={'Discounts Value'}
                    control={popupControl}
                    errors={!!popupErrors.discounts?.[discountIndex]}
                    helperText={popupErrors.discounts?.[discountIndex]?.value?.message}
                  />
                </Grid>
                <Grid item md={12}>
                  <Controller
                    name={`discounts.${discountIndex}.reason`}
                    control={popupControl}
                    render={({ field }) => (
                      <TextField {...field} id="discount-reason" fullWidth label="Discount Reason" variant="outlined" />
                    )}
                  />
                </Grid>
              </Grid>
            ))}
          </Box>
          <Divider />
          <Box
            sx={{
              px: 2,
              py: 1,
              borderTop: '1px solid #e0e0e0',
              display: 'flex',
              justifyContent: 'flex-end',
              gap: 1,
            }}
          >
            <Button type="button" variant="contained" color="primary" onClick={handlePopupSubmit(handleSubmitForm)}>
              Add Discount
            </Button>
          </Box>
        </Box>
      </Fade>
    </Modal>
  );
}
