import * as React from 'react';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import Box from '@mui/material/Box';
import ComplianceSection from '../components/complianceSection';
import { Grid2 as Grid } from '@mui/material';

export default function SettingsPage() {
  const [value, setValue] = React.useState('compliance');

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  return (
    <Grid>
      <Box sx={{ width: '100%' }}>
        <Tabs
          value={value}
          onChange={handleChange}
          textColor="primary"
          indicatorColor="primary"
          aria-label="settings tabs"
        >
          <Tab value="compliance" label="Compliance" />
        </Tabs>

        <Box sx={{ p: 2 }}>{value === 'compliance' && <ComplianceSection />}</Box>
      </Box>
    </Grid>
  );
}
