import React from 'react';
import { Typography } from '@mui/material';

const InvoicesDueAlert = () => (
  <div style={{ textAlign: 'left', flex: 1 }}>
    <Typography variant="body1" sx={{ fontSize: '1.2rem', fontWeight: 'bold', textAlign: 'center', mr: '5rem' }}>
      You have an overdue invoice. Please clear the payment to continue using the platform.
    </Typography>
  </div>
);

export default InvoicesDueAlert;
