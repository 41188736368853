export const formatCurrency = (value) => {
  const num = typeof value === 'number' ? value : parseFloat(value);
  if (isNaN(num)) return '0.00';
  if (Number.isInteger(num)) {
    return `${num}.00`;
  }

  let formatted = num.toFixed(8).replace(/(\.\d*?[1-9])0+$/g, '$1');
  const decimalPart = formatted.split('.')[1] || '';

  if (decimalPart.length < 2) {
    formatted += '0'.repeat(2 - decimalPart.length);
  }

  return formatted;
};
