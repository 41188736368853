import React, { useEffect } from 'react';
import useDocumentTitle from '../../../hooks/useDocumentTitle';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation, useNavigate } from 'react-router-dom';
import {
  createDebitNoteRequest,
  debitNoteEDocRequest,
  markDebitNoteCreated,
} from '../../../api/debitNotes/debitNotesSlice';
import SBDebitNoteForm from '../components/SBDebitNoteForm';
import { Stack } from '@mui/material';
import moment from 'moment';

export default function CreateSBDebitNotePage() {
  useDocumentTitle('Self Billed Debit Notes Form');
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const location = useLocation();
  const { isDebitNoteCreated, error } = useSelector((state) => state.debitNotes);
  const document = location.state.document;

  useEffect(() => {
    dispatch(markDebitNoteCreated(false));
  }, [dispatch]);

  useEffect(() => {
    if (isDebitNoteCreated) {
      navigate('/self-billed-documents/debit-notes');
    }
  }, [isDebitNoteCreated, navigate]);

  const onSubmit = async (data) => {
    const payload = {
      ...data,
      document_date: moment(data.document_date).format('YYYY-MM-DD'),
      payment_mode: data.payment_mode.value,
      item_details: data.item_details.map(({ id, ...item }) => ({
        ...item,
      })),
    };

    if (payload.status === 'draft') {
      payload.item_details.forEach((item) => {
        delete item._id;
      });
      await dispatch(debitNoteEDocRequest(payload));
    } else {
      await dispatch(createDebitNoteRequest(payload));
    }
  };

  const onCancel = () => {
    navigate(location.state?.from || '/self-billed-documents/debit-notes');
  };

  return (
    <Stack direction="column" sx={{ mt: '-2rem' }}>
      <SBDebitNoteForm initialData={document || {}} onSubmit={onSubmit} onCancel={onCancel} apiErrors={error} />
    </Stack>
  );
}
