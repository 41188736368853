import React, { useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { clearSelectedInvoice, fetchInvoiceById } from '../../../api/invoices/invoicesSlice';
import { Box, Stack, useTheme } from '@mui/material';
import '../../../styles/DocumentDetails.css';
import TopBar from '../../../components/TopBar';
import useDocumentTitle from '../../../hooks/useDocumentTitle';
import SBInvoiceDetailsPageActions from '../components/SBInvoiceDetailsPageActions';
import SBInvoiceForm from '../components/SBInvoiceForm';
import IrbmStatusCard from '../../common/IrbmStatusCard';
import PeppolResponseStatusCard from '../../common/PeppolResponseStatusCard';

export default function SBInvoiceDetailsPage() {
  useDocumentTitle('Self Billed Invoice Details');
  const { document_id } = useParams();
  const dispatch = useDispatch();
  const countryCodes = useSelector((state) => state.countryCodes.countryCodes);
  const invoice = useSelector((state) => state.invoices.selectedInvoice);
  const theme = useTheme();

  useEffect(() => {
    if (document_id) {
      dispatch(fetchInvoiceById(document_id));
    }
    //The function which is returned by useEffect will act as cleanup of what useEffect does
    return () => {
      dispatch(clearSelectedInvoice());
    };
  }, [dispatch, document_id]);
  if (!invoice) return null;

  return (
    <Stack direction="column" sx={{ mt: '-1rem' }}>
      {invoice && (
        <Box sx={{ pb: 4 }}>
          <TopBar title="Self Billed Invoice Details" subTitle={invoice?.document_id}>
            <SBInvoiceDetailsPageActions />
          </TopBar>
        </Box>
      )}
      <IrbmStatusCard document={invoice} />
      <PeppolResponseStatusCard document={invoice} />
      <SBInvoiceForm onSubmit={() => {}} onCancel={() => {}} initialData={invoice} readOnly={true} />
    </Stack>
  );
}
