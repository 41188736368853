import React, { useState, useEffect } from 'react';
import { DataGrid } from '@mui/x-data-grid';
import { useDispatch, useSelector } from 'react-redux';
import { fetchBulkUploadRequest } from '../../../api/bulkUpload/bulkUploadSlice';
import { Box } from '@mui/material';
import BulkUploadActionsMenu from './BulkUploadActionsMenu';

const BulkUploadTable = ({ searchQuery = {} }) => {
  const { bulkUploads, totalDocuments } = useSelector((state) => state.bulkUploads);
  const [page, setPage] = useState(0);
  const [limit, setLimit] = useState(10);
  const dispatch = useDispatch();

  useEffect(() => {
    const query = {
      ...searchQuery,
      page: page + 1,
      limit,
    };

    dispatch(fetchBulkUploadRequest({ query }));
  }, [dispatch, page, limit, searchQuery]);

  const columns = [
    {
      field: 'filename',
      headerName: 'File Name',
      flex: 1.5,
    },
    {
      field: 'source',
      headerName: 'Source',
      flex: 0.5,
      valueFormatter: (params) => {
        return params === 'bulk_upload' ? 'WEB' : params?.toUpperCase();
      },
    },
    {
      field: 'created_on',
      headerName: 'Date',
      flex: 1,
      valueFormatter: (params) => {
        const timestamp = params;
        if (!timestamp) return '';
        const date = new Date(timestamp);
        return date.toLocaleString();
      },
    },
    {
      field: 'total_documents_count',
      headerName: 'Total',
      flex: 0.4,
    },
    {
      field: 'processed_documents_count',
      headerName: 'Proceeded',
      flex: 0.5,
    },
    {
      field: 'error_documents_count',
      headerName: 'Failed',
      flex: 0.4,
    },
    {
      field: 'status',
      headerName: 'Status',
      flex: 0.8,
      valueFormatter: (params) => {
        return params
          ?.split('_')
          .map((word) => word.charAt(0).toUpperCase() + word.slice(1))
          .join(' ');
      },
    },
    {
      field: 'row',
      headerName: 'Actions',
      flex: 0.5,
      sortable: false,
      renderCell: ({ row }) => <BulkUploadActionsMenu row={row} />,
    },
  ];

  return (
    <Box>
      <DataGrid
        rows={[...bulkUploads].sort((a, b) => {
          const dateA = new Date(a.created_on).toLocaleString();
          const dateB = new Date(b.created_on).toLocaleString();
          return new Date(dateB).getTime() - new Date(dateA).getTime();
        })}
        columns={columns}
        getRowId={(row) => row._id}
        paginationMode="server"
        rowCount={totalDocuments}
        initialState={{
          pagination: {
            paginationModel: {
              pageSize: limit,
            },
          },
        }}
        pageSizeOptions={[10, 25]}
        disableRowSelectionOnClick
        onPaginationModelChange={(model) => {
          setPage(model.page);
          setLimit(model.pageSize);
        }}
      />
    </Box>
  );
};

export default BulkUploadTable;
