import React, { useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { fetchSupplierById } from '../../../api/suppliers/suppliersSlice';
import { Box, Stack, useTheme } from '@mui/material';
import '../../../styles/DocumentDetails.css';
import TopBar from '../../../components/TopBar';
import SupplierDetailsPageActionButtons from '../components/SupplierDetailsPageActionButtons';
import useDocumentTitle from '../../../hooks/useDocumentTitle';
import SupplierForm from '../components/SupplierForm';

export default function SupplierDetailsPage() {
  useDocumentTitle('Supplier Details');
  const { profile_id } = useParams();
  const dispatch = useDispatch();
  const supplier = useSelector((state) => state.suppliers.selectedSupplier);

  const theme = useTheme();

  const states = useSelector((state) => state.states.states);
  const countryCodes = useSelector((state) => state.countryCodes.countryCodes);
  const msicCodes = useSelector((state) => state.msicCodes.msicCodes);

  useEffect(() => {
    if (profile_id) {
      dispatch(fetchSupplierById(profile_id));
    }
  }, [dispatch, profile_id]);

  if (!supplier) return null;

  return (
    <Stack direction="column" sx={{ mt: '-1rem' }}>
      {supplier && (
        <Box sx={{ pb: 4 }}>
          <TopBar title="Supplier Details" subTitle={supplier?.profile_id}>
            <SupplierDetailsPageActionButtons />
          </TopBar>
        </Box>
      )}

      <SupplierForm
        onSubmit={() => {}}
        apiErrors={{}}
        initialData={supplier}
        readOnly={true}
        edit={false}
        onCancel={() => {}}
      />
    </Stack>
  );
}
