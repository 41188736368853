import React, { useState, useEffect, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useForm, Controller, useFieldArray } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from 'yup';
import {
  Button,
  TextField,
  Switch,
  Stepper,
  Step,
  StepLabel,
  Box,
  Typography,
  Autocomplete,
  MenuItem,
  Chip,
  Grid,
  IconButton,
  FormControlLabel,
} from '@mui/material';
import { Check } from '@mui/icons-material';
import {
  activatePeppolDirectoryRequest,
  activateSmlRequest,
  createParticipantRequest,
  fetchEndpointIdRequest,
  kycSignRequest,
} from '../../api/participants/participantsSlice';
import { fetchAdditionalIdentifiersRequest } from '../../api/constants/additionalIdentifiersSlice';
import { profileIdMap } from '../../utils/profileIdMap';
import ParticipantIdentifier, { specialIdentifiers } from './ParticipantIdentifier';
import FileUploadBox from './FileUploadBox';
import store from '../../store';

const steps = ['Peppol Details', 'PDF Authorization Doc', 'Publish Data'];

const schema = yup.object().shape({
  business_entity: yup.array().of(
    yup.object().shape({
      name: yup.string().required('Business name is required'),
    }),
  ),
  identifier: yup.string().required('Business identifier is required'),
  special_identifier: yup.string().required('Special identifier is required'),
  additional_identifiers: yup.array().of(
    yup.object().shape({
      identifier_code: yup.string().required('Identifier code is required'),
      identifier: yup.string().required('Identifier is required'),
      special_identifier: yup.string().required('Special identifier is required'),
    }),
  ),
  contacts: yup.array().of(
    yup.object().shape({
      name: yup.string().required('Name is required'),
      email: yup.string().email('Invalid email').required('Email is required'),
      phone: yup.string().required('Phone is required'),
      type: yup.string().required('Type is required'),
    }),
  ),
  websites: yup.array().of(yup.string().url('Invalid URL').required('Website URL is required')),
});

const PeppolRegistrationSteps = ({ profileId, setParticipantId, handleFetchParticipants }) => {
  const dispatch = useDispatch();
  const endpointId = useSelector((state) => state.participants.endpoint_id);
  const additionalIdentifiers = useSelector((state) => state.additionalIdentifiers.additionalIdentifiers);
  const [activeStep, setActiveStep] = useState(0);
  const [completedSteps, setCompletedSteps] = useState([]);
  const [errorSteps, setErrorSteps] = useState([]);
  const [pdfFile, setPdfFile] = useState(null);

  const {
    trigger,
    watch,
    control,
    setValue,
    getValues,
    handleSubmit,
    formState: { errors },
    resetField,
  } = useForm({
    resolver: yupResolver(schema),
    defaultValues: {
      profile_ids: [288, 287, 286, 285, 275, 274, 273, 272, 261, 257],
      endpoint_id: '',
      business_entity: [{ language: 'en', name: '' }],
      country_code: 'MY',
      additional_identifiers: [],
      additional_information: '',
      contacts: [],
      geographical_information: '',
      websites: [],
      identifier_code: '0230',
      special_identifier: '',
      identifier: '',
      scheme: 'iso6523-actorid-upis',
      is_active_in_pd: false,
      is_active_in_smk: false,
    },
  });

  useEffect(() => {
    dispatch(fetchEndpointIdRequest());
    dispatch(fetchAdditionalIdentifiersRequest());
  }, [dispatch]);

  useEffect(() => {
    if (endpointId) {
      setValue('endpoint_id', endpointId);
    }
  }, [endpointId, setValue]);

  useEffect(() => {
    const savedData = localStorage.getItem(profileId);
    if (savedData) {
      const parsedData = JSON.parse(savedData);
      if (parsedData) {
        setActiveStep(parsedData.currentStep);
        setValue('identifier_code', parsedData.participantId.split(':')[0]);
        setValue('special_identifier', parsedData.participantId.split(':')[1].slice(0, 2));
        setValue('identifier', parsedData.participantId.split(':')[1].slice(2));
        const completed = [];
        for (let i = 0; i < parsedData.currentStep; i++) {
          completed.push(i);
        }
        setCompletedSteps(completed);
      }
    }
  }, [profileId, setValue, activeStep]);

  const handleNext = async (data) => {
    try {
      const handleStateChange = (unsubscribe) => {
        const state = store.getState().participants;
        const { error, loading } = state;

        if (!loading) {
          if (error) {
            setErrorSteps((prev) => [...new Set([...prev, activeStep])]);
          } else {
            setErrorSteps((prev) => prev.filter((step) => step !== activeStep));
            setCompletedSteps((prev) => [...new Set([...prev, activeStep])]);
            setActiveStep((prev) => prev + 1);

            const participantId = `${data.identifier_code}:${data.special_identifier}${data.identifier}`;
            localStorage.setItem(profileId, JSON.stringify({ participantId, currentStep: activeStep + 1 }));
          }
          unsubscribe();
        }
      };

      if (activeStep === 0) {
        dispatch(createParticipantRequest(data));
        const unsubscribe = store.subscribe(() => handleStateChange(unsubscribe));
      } else if (activeStep === 1) {
        if (!pdfFile) {
          return;
        }
        dispatch(
          kycSignRequest({
            pid: `iso6523-actorid-upis::${JSON.parse(localStorage.getItem(profileId)).participantId}`,
            file: pdfFile,
          }),
        );
        const unsubscribe = store.subscribe(() => handleStateChange(unsubscribe));
      }

      if (activeStep === steps.length - 1 && !completedSteps.includes(activeStep)) {
        setParticipantId(JSON.parse(localStorage.getItem(profileId)).participantId);
        localStorage.removeItem(profileId);
        await handleFetchParticipants();
      }
    } catch (error) {
      setErrorSteps((prev) => [...new Set([...prev, activeStep])]);
    }
  };

  const {
    fields: identifierFields,
    append: appendIdentifier,
    remove: removeIdentifier,
  } = useFieldArray({
    control,
    name: 'additional_identifiers',
  });

  const {
    fields: contactFields,
    append: appendContact,
    remove: removeContact,
  } = useFieldArray({
    control,
    name: 'contacts',
  });

  const {
    fields: websiteFields,
    append: appendWebsite,
    remove: removeWebsite,
  } = useFieldArray({
    control,
    name: 'websites',
  });

  const handleFileUpload = (file) => {
    if (file?.type === 'application/pdf' && file.size <= 5 * 1024 * 1024) {
      setPdfFile(file);
    }
  };

  const isActiveInSMK = watch('is_active_in_smk');
  const isActiveInPD = watch('is_active_in_pd');

  const handleSMKToggle = async () => {
    const newValue = !isActiveInSMK;
    setValue('is_active_in_smk', newValue);
    await trigger('is_active_in_smk');
    dispatch(
      activateSmlRequest({
        pid: `iso6523-actorid-upis::${JSON.parse(localStorage.getItem(profileId)).participantId}`,
        isActive: newValue,
      }),
    );
  };

  const handlePDToggle = async () => {
    const newValue = !isActiveInPD;
    setValue('is_active_in_pd', newValue);
    await trigger('is_active_in_pd');
    dispatch(
      activatePeppolDirectoryRequest({
        pid: `iso6523-actorid-upis::${JSON.parse(localStorage.getItem(profileId)).participantId}`,
        isActive: newValue,
      }),
    );
  };
  const StepIcon = ({ active, completed, icon }) => {
    const isError = errorSteps.includes(icon - 1);
    const isSuccess = completedSteps.includes(icon - 1);

    return isError ? (
      <Typography color="error">X</Typography>
    ) : isSuccess ? (
      <Check color="success" />
    ) : (
      <div style={{ color: active ? 'blue' : 'inherit' }}>{icon}</div>
    );
  };

  const handleFormSubmit = async () => {
    if (activeStep === 1 || activeStep === 2) {
      resetField('business_entity[0].name', { keepError: false });
      handleNext(getValues());
    }
  };

  return (
    <Box>
      <Stepper activeStep={activeStep} sx={{ my: 3 }} alternativeLabel>
        {steps.map((label, index) => (
          <Step key={index} completed={completedSteps.includes(index)}>
            <StepLabel StepIconComponent={StepIcon}>{label}</StepLabel>
          </Step>
        ))}
      </Stepper>

      <form onSubmit={handleSubmit(handleNext)}>
        {activeStep === 0 && (
          <Box>
            <ParticipantIdentifier control={control} errors={errors} readonly={false} setValue={setValue} />
            <Grid container spacing={2}>
              <Grid item xs={12} sm={6}>
                <Controller
                  name="business_entity.0.name"
                  control={control}
                  render={({ field }) => (
                    <TextField
                      {...field}
                      label="Business Name"
                      fullWidth
                      sx={{ my: 2 }}
                      error={!!errors.business_entity?.[0]?.name}
                      helperText={errors.business_entity?.[0]?.name?.message}
                    />
                  )}
                />
              </Grid>
              <Grid item xs={12} sm={6}>
                <Controller
                  name="endpoint_id"
                  control={control}
                  render={({ field }) => (
                    <TextField
                      {...field}
                      label="Endpoint ID"
                      fullWidth
                      sx={{ my: 2, backgroundColor: '#fbf8f8' }}
                      disabled
                    />
                  )}
                />
              </Grid>
            </Grid>
            <Grid container spacing={2}>
              <Grid item xs={12} sm={12}>
                <Controller
                  name="profile_ids"
                  control={control}
                  render={({ field }) => (
                    <TextField
                      label="Selected Profiles"
                      fullWidth
                      sx={{ my: 2, p: 1 }}
                      InputProps={{
                        readOnly: true,
                        startAdornment: (
                          <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 0.5, mt: 1, mb: 1 }}>
                            {field.value?.map((id) => {
                              return <Chip key={id} label={`${id} - ${profileIdMap[id]}`} />;
                            })}
                          </Box>
                        ),
                      }}
                    />
                  )}
                />
              </Grid>
            </Grid>
            <Grid container spacing={2}>
              <Grid item xs={12} sm={12}>
                <Controller
                  name="additional_information"
                  control={control}
                  render={({ field }) => <TextField {...field} label="Additional Info" fullWidth />}
                />
              </Grid>
            </Grid>
            <Grid container spacing={2}>
              <Grid item xs={12} sx={{ mt: 2 }}>
                <Button
                  variant="outlined"
                  onClick={() =>
                    appendIdentifier({
                      identifier_code: '',
                      identifier: '',
                      special_identifier: '',
                      scheme: 'iso6523-actorid-upis',
                    })
                  }
                >
                  + Add Additional Identifiers
                </Button>
              </Grid>
              <Grid item xs={12}>
                {identifierFields.map((identifier, index) => (
                  <Grid container spacing={2} key={identifier.id} sx={{ mt: 0.2 }}>
                    <Grid item xs={12} sm={3.9}>
                      <Controller
                        name={`additional_identifiers.${index}.identifier_code`}
                        control={control}
                        render={({ field }) => (
                          <TextField
                            {...field}
                            select
                            label="Identifier Code"
                            fullWidth
                            error={!!errors.additional_identifiers?.[index]?.identifier_code}
                          >
                            {additionalIdentifiers?.map((option) => (
                              <MenuItem key={option.code} value={option.code}>
                                ({option.code}) - {option.description}
                              </MenuItem>
                            ))}
                          </TextField>
                        )}
                      />
                    </Grid>
                    <Grid item xs={12} sm={3.9}>
                      <Controller
                        name={`additional_identifiers.${index}.special_identifier`}
                        control={control}
                        render={({ field }) => (
                          <TextField
                            {...field}
                            select
                            label="Special Identifier"
                            fullWidth
                            error={!!errors.additional_identifiers?.[index]?.special_identifier}
                          >
                            {specialIdentifiers.map((option) => (
                              <MenuItem key={option.value} value={option.value}>
                                ({option.value}) - {option.label}
                              </MenuItem>
                            ))}
                          </TextField>
                        )}
                      />
                    </Grid>
                    <Grid item xs={12} sm={3.8}>
                      <Controller
                        name={`additional_identifiers.${index}.identifier`}
                        control={control}
                        render={({ field }) => (
                          <TextField
                            {...field}
                            label="Identifier"
                            fullWidth
                            error={!!errors.additional_identifiers?.[index]?.identifier}
                          />
                        )}
                      />
                    </Grid>
                    <Grid item xs={12} sm={0.3} display="flex" alignItems="center">
                      <IconButton onClick={() => removeIdentifier(index)}>x</IconButton>
                    </Grid>
                  </Grid>
                ))}
              </Grid>

              <Grid item xs={12} sx={{ mt: 2 }}>
                <Button
                  variant="outlined"
                  onClick={() =>
                    appendContact({
                      email: '',
                      name: '',
                      phone: '',
                      type: '',
                    })
                  }
                >
                  + Add Contact
                </Button>
              </Grid>
              <Grid item xs={12}>
                {contactFields.map((contact, index) => (
                  <Grid container spacing={2} key={contact.id} sx={{ mt: 0.2 }}>
                    <Grid item xs={12} sm={2.9}>
                      <Controller
                        name={`contacts.${index}.name`}
                        control={control}
                        render={({ field }) => (
                          <TextField {...field} label="Name" fullWidth error={!!errors.contacts?.[index]?.name} />
                        )}
                      />
                    </Grid>
                    <Grid item xs={12} sm={2.9}>
                      <Controller
                        name={`contacts.${index}.email`}
                        control={control}
                        render={({ field }) => (
                          <TextField {...field} label="Email" fullWidth error={!!errors.contacts?.[index]?.email} />
                        )}
                      />
                    </Grid>
                    <Grid item xs={12} sm={2.9}>
                      <Controller
                        name={`contacts.${index}.phone`}
                        control={control}
                        render={({ field }) => (
                          <TextField {...field} label="Phone" fullWidth error={!!errors.contacts?.[index]?.phone} />
                        )}
                      />
                    </Grid>
                    <Grid item xs={12} sm={2.9}>
                      <Controller
                        name={`contacts.${index}.type`}
                        control={control}
                        render={({ field }) => (
                          <TextField {...field} label="Type" fullWidth error={!!errors.contacts?.[index]?.type} />
                        )}
                      />
                    </Grid>
                    <Grid item xs={12} sm={0.4} display="flex" alignItems="center">
                      <IconButton onClick={() => removeContact(index)}>x</IconButton>
                    </Grid>
                  </Grid>
                ))}
              </Grid>
            </Grid>
            <Grid container spacing={2}>
              <Grid item xs={12} sm={12} sx={{ mt: 2 }}>
                <Controller
                  name="geographical_information"
                  control={control}
                  render={({ field }) => <TextField {...field} label="Geographical Information" fullWidth />}
                />
              </Grid>
            </Grid>
            <Grid container spacing={2}>
              <Grid item xs={12} sx={{ mt: 2 }}>
                <Button variant="outlined" onClick={() => appendWebsite('http://')}>
                  + Add Website
                </Button>
              </Grid>

              <Grid item xs={12}>
                {websiteFields.map((website, index) => (
                  <Grid container spacing={2} key={website.id} sx={{ mt: 0.2 }}>
                    <Grid item xs={12} sm={11.6}>
                      <Controller
                        name={`websites.${index}`}
                        control={control}
                        render={({ field }) => (
                          <TextField {...field} label="Website" fullWidth error={!!errors.websites?.[index]} />
                        )}
                      />
                    </Grid>
                    <Grid item xs={12} sm={0.4} display="flex" alignItems="center">
                      <IconButton onClick={() => removeWebsite(index)}>x</IconButton>
                    </Grid>
                  </Grid>
                ))}
              </Grid>
            </Grid>
          </Box>
        )}

        {activeStep === 1 && (
          <Box>
            <ParticipantIdentifier control={control} errors={errors} readonly={true} setValue={setValue} />
            <Typography variant="h6" mt={2}>
              Sign with PDF
            </Typography>
            <Typography variant="body2" mt={1} mb={2}>
              Upload the proof for your Business Identification.
            </Typography>
            <FileUploadBox
              handleFileUpload={handleFileUpload}
              pdfFile={pdfFile}
              setPdfFile={setPdfFile}
              errors={errors}
            />
          </Box>
        )}

        {activeStep === 2 && (
          <Box>
            <ParticipantIdentifier control={control} errors={errors} readonly={true} setValue={setValue} />

            <Typography sx={{ mt: 2 }}>Activate in SMK</Typography>
            <FormControlLabel
              control={
                <>
                  <Typography>Yes</Typography>
                  <Switch checked={isActiveInSMK} onChange={handleSMKToggle} disabled={isActiveInSMK} />
                  <Typography>No</Typography>
                </>
              }
              label=""
              labelPlacement="start"
            />

            <Typography sx={{ mt: 2 }}>Activate in Peppol Directory</Typography>
            <FormControlLabel
              control={
                <>
                  <Typography>Yes</Typography>
                  <Switch checked={isActiveInPD} onChange={handlePDToggle} disabled={isActiveInPD} />
                  <Typography>No</Typography>
                </>
              }
              label=""
              labelPlacement="start"
            />
          </Box>
        )}

        <Box mt={3} display="flex" justifyContent="flex-end">
          {activeStep < steps.length - 1 && (
            <Button
              variant="contained"
              color="primary"
              type="submit"
              onClick={handleFormSubmit}
              disabled={activeStep === 1 && !pdfFile}
            >
              Save & Proceed
            </Button>
          )}
          {activeStep === steps.length - 1 && !completedSteps.includes(activeStep) && (
            <Button variant="contained" color="primary" type="submit" onClick={handleFormSubmit}>
              Complete & Fetch Participant
            </Button>
          )}
        </Box>
      </form>
    </Box>
  );
};
export default PeppolRegistrationSteps;
