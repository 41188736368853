import React, { useState } from 'react';
import { DataGrid } from '@mui/x-data-grid';
import { useDispatch, useSelector } from 'react-redux';
import CustomerActionsMenu from './CustomerActionsMenu';
import { fetchCustomersRequest } from '../../../api/customers/customersSlice';
import { Box } from '@mui/material';

const CustomersTable = ({ searchQuery = {} }) => {
  const { customers, totalDocuments } = useSelector((state) => state.customers);
  const countryCodes = useSelector((state) => state.countryCodes.countryCodes);
  const [page] = useState(0);
  const [limit] = useState(10);
  const dispatch = useDispatch();

  const getCountryName = (code) => {
    const country = countryCodes.find((country) => country.Code === code);
    return country ? country.Country : code;
  };

  const columns = [
    {
      field: 'name',
      headerName: 'Name / Customer Name',
      flex: 1,
    },
    {
      field: 'email',
      headerName: 'Contact Info',
      flex: 1,
    },
    {
      field: 'registration_number',
      headerName: 'Business Identifier',
      flex: 1,
      renderCell: (params) => {
        return `${params.row.registration_number} (${params.row.registration_type})`;
      },
    },
    {
      field: 'country_code',
      headerName: 'Country',
      flex: 1,
      valueGetter: (data) => {
        return getCountryName(data);
      },
    },
    {
      field: 'actions',
      headerName: 'Actions',
      flex: 0.5,
      sortable: false,
      filterable: false,
      renderCell: (params) => {
        return <CustomerActionsMenu customer={params.row} />;
      },
    },
  ];

  return (
    <Box>
      <DataGrid
        rows={customers}
        columns={columns}
        getRowId={(row) => row._id}
        paginationMode="server"
        rowCount={totalDocuments}
        initialState={{
          pagination: {
            paginationModel: {
              pageSize: limit,
            },
          },
        }}
        pageSizeOptions={[10, 25]}
        disableRowSelectionOnClick
        onPaginationModelChange={(model) => {
          dispatch(
            fetchCustomersRequest({
              query: {
                ...searchQuery,
                page: model.page + 1,
                limit: model.pageSize,
              },
            }),
          );
        }}
      />
    </Box>
  );
};

export default CustomersTable;
