import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Box, Button, CircularProgress, Grid2 as Grid, IconButton, Modal, Paper, Typography } from '@mui/material';
import { CheckCircle, Close, CloudUpload } from '@mui/icons-material';
import {
  downloadTemplateRequest,
  fetchBulkUploadRequest,
  proceedBulkUploadRequest,
} from '../../../api/bulkUpload/bulkUploadSlice';
import useSubscriptionCheck from '../../common/SubscriptionCheck';
import { showSnackbar } from '../../../api/snackbarSlice';

const MAX_FILE_SIZE = 5 * 1024 * 1024; // 5MB

function BulkUploadListPageActions() {
  const dispatch = useDispatch();
  const {
    isTemplateDownloaded,
    isBulkUploadProceeded,
    isBulkUploadReportDownloaded,
    error: uploadError,
  } = useSelector((state) => state.bulkUploads);

  const [open, setOpen] = useState(false);
  const [file, setFile] = useState(null);
  const [error, setError] = useState(null);
  const [loading, setLoading] = useState(false);
  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState('');
  const [snackbarSeverity, setSnackbarSeverity] = useState('success');
  const { checkSubscription, SubscriptionModal } = useSubscriptionCheck();

  useEffect(() => {
    if (isTemplateDownloaded) {
      setSnackbarMessage('Template downloaded successfully');
      setSnackbarSeverity('success');
      setSnackbarOpen(true);
    }
  }, [isTemplateDownloaded]);

  useEffect(() => {
    if (isBulkUploadProceeded) {
      setSnackbarMessage('Bulk upload completed successfully');
      setSnackbarSeverity('success');
      setSnackbarOpen(true);
      handleClose();
      setLoading(false);
      dispatch(fetchBulkUploadRequest({ query: { page: 1, limit: 10 } })); // Refresh the list
    } else if (uploadError) {
      setSnackbarMessage(uploadError);
      setSnackbarSeverity('error');
      setSnackbarOpen(true);
      setLoading(false);
      handleClose();
    }
  }, [isBulkUploadProceeded, uploadError, dispatch]);

  useEffect(() => {
    if (isBulkUploadReportDownloaded) {
      dispatch(showSnackbar({ message: 'Download Completed!', severity: 'success' }));
    }
  }, [isBulkUploadReportDownloaded]);

  const handleOpen = () => {
    if (!checkSubscription()) {
      return;
    }
    setOpen(true);
  };
  const handleClose = () => {
    setOpen(false);
    setFile(null);
    setError(null);
    setLoading(false);
    dispatch(fetchBulkUploadRequest({ query: { page: 1, limit: 10 } })); // Refresh the list on modal close
  };

  const handleFileUpload = (uploadedFile) => {
    if (!uploadedFile) return;

    if (uploadedFile.size > MAX_FILE_SIZE) {
      setError('File exceeds max size (5MB).');
      setFile(null);
      return;
    }

    if (!uploadedFile.name.endsWith('.xlsx') && !uploadedFile.name.endsWith('.csv')) {
      setError('File format not supported. Please upload .xlsx or .csv files only.');
      setFile(null);
      return;
    }

    setError(null);
    setFile(uploadedFile);
  };

  const onDrop = (event) => {
    event.preventDefault();
    const droppedFile = event.dataTransfer.files?.[0];
    handleFileUpload(droppedFile);
  };

  const handleClearFile = () => {
    setFile(null);
  };

  const handleDownloadTemplate = (format) => {
    setSnackbarMessage(`Downloading ${format} template...`);
    setSnackbarSeverity('info');
    setSnackbarOpen(true);
    dispatch(downloadTemplateRequest({ format }));
  };

  const handleUploadAndStartProcessing = () => {
    if (file) {
      setLoading(true);
      dispatch(proceedBulkUploadRequest({ file }));
    }
  };

  return (
    <>
      <Button variant="contained" color="primary" onClick={handleOpen}>
        + Bulk Upload
      </Button>

      <Modal open={open} onClose={handleClose}>
        <Paper
          style={{
            position: 'absolute',
            top: '50%',
            left: '50%',
            transform: 'translate(-50%, -50%)',
            width: 500,
            padding: 16,
            boxShadow: 24,
            outline: 'none',
          }}
        >
          <Box display="flex" justifyContent="space-between" alignItems="center">
            <Typography variant="h6" fontWeight="bold">
              Bulk Upload E-Documents
            </Typography>
            <IconButton onClick={handleClose}>
              <Close />
            </IconButton>
          </Box>

          <Typography variant="body1" mt={2} mb={2}>
            Upload your spreadsheet to generate documents in bulk.
          </Typography>

          {!file ? (
            <Box
              onDragOver={(e) => e.preventDefault()}
              onDrop={onDrop}
              style={{
                border: '2px dashed #ccc',
                borderRadius: '8px',
                textAlign: 'center',
                padding: '20px',
                backgroundColor: '#fafafa',
              }}
            >
              <CloudUpload fontSize="large" color="action" />
              <Typography variant="body2" mt={1}>
                Drag & Drop files here
              </Typography>
              <Typography variant="caption" color="textSecondary">
                Supported formats: .xlsx, .csv
              </Typography>

              <Box mt={2}>
                <input
                  type="file"
                  accept=".xlsx,.csv"
                  style={{ display: 'none' }}
                  id="file-input"
                  onChange={(e) => handleFileUpload(e.target.files?.[0])}
                />
                <label htmlFor="file-input">
                  <Button variant="contained" color="primary" component="span" size="small">
                    Browse
                  </Button>
                </label>
              </Box>
            </Box>
          ) : (
            <Grid
              display="flex"
              alignItems="center"
              justifyContent="space-between"
              p={2}
              style={{
                border: '2px solid #4caf50',
                borderRadius: '8px',
                backgroundColor: '#e8f5e9',
              }}
            >
              <Grid
                display="flex"
                flexDirection="row"
                alignItems="center"
                justifyContent="space-evenly"
                sx={{ width: '100%' }}
                gap={1}
              >
                <CheckCircle color="success" />
                <Typography
                  variant="body2"
                  style={{ fontWeight: 'bold', wordBreak: 'break-word', whiteSpace: 'normal' }}
                >
                  {file.name}
                </Typography>
                <Typography variant="body2" style={{ color: '#4caf50', wordBreak: 'break-word', whiteSpace: 'normal' }}>
                  File successfully uploaded
                </Typography>
              </Grid>
              <IconButton onClick={handleClearFile}>
                <Close />
              </IconButton>
            </Grid>
          )}

          {error && (
            <Typography variant="body2" mt={2} color="error">
              {error}
            </Typography>
          )}

          <Grid mt={3} style={{ display: 'flex', justifyContent: 'space-evenly' }}>
            <Typography variant="body2">
              <Button
                variant="text"
                color="primary"
                onClick={() => handleDownloadTemplate('xlsx')}
                sx={{ textDecoration: 'underline' }}
              >
                Download XLSX Template
              </Button>
              {' | '}
              <Button
                variant="text"
                color="primary"
                onClick={() => handleDownloadTemplate('csv')}
                sx={{ textDecoration: 'underline' }}
              >
                Download CSV Template
              </Button>
            </Typography>
          </Grid>

          <Grid mt={3} textAlign="center">
            <Button
              variant="contained"
              color="primary"
              disabled={!file || loading}
              onClick={handleUploadAndStartProcessing}
              startIcon={loading ? <CircularProgress size={20} /> : null}
            >
              {loading ? 'Processing...' : 'Upload & Start Processing'}
            </Button>
          </Grid>
        </Paper>
      </Modal>
      <SubscriptionModal />
    </>
  );
}

export default BulkUploadListPageActions;
