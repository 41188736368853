import React from 'react';
import {
  Card,
  CardContent,
  Chip,
  CircularProgress,
  Divider,
  Grid2,
  IconButton,
  Skeleton,
  Typography,
} from '@mui/material';
import { Refresh } from '@mui/icons-material';
import { profileIdMap } from '../../utils/profileIdMap';
import CustomTextInput from '../CustomTextInput';

export default function PeppolDetails({
  control,
  errors,
  getValues,
  setParticipantId,
  handleFetchParticipants,
  loading,
  readOnly,
  loadingParticipant,
  isPeppolEmpty,
  peppolParticipant,
}) {
  return (
    <>
      <Typography variant="h6" sx={{ mb: '1.5rem', pt: '1rem' }}>
        Peppol Details
        <Divider></Divider>
      </Typography>

      <Grid2 container spacing={2}>
        <Grid2 size={{ xs: 12, sm: 6 }}>
          <CustomTextInput
            name="participant_id"
            control={control}
            errors={errors}
            label="Participant ID"
            onChange={() => setParticipantId(getValues('participant_id'))}
            readOnly={readOnly}
          />
        </Grid2>
        <Grid2 item xs={12} sm={6}>
          <IconButton onClick={handleFetchParticipants} color="success" disabled={loading || readOnly}>
            {loading ? <CircularProgress size={24} color="inherit" /> : <Refresh />}
          </IconButton>
        </Grid2>
      </Grid2>

      {loadingParticipant ? (
        <Card sx={{ mt: 4, p: 2 }}>
          <Skeleton variant="text" width="40%" height={30} />
          <Skeleton variant="text" width="60%" height={25} />
          <Skeleton variant="rectangular" width="100%" height={50} sx={{ mt: 2 }} />
        </Card>
      ) : (
        !isPeppolEmpty && (
          <Card sx={{ mt: 4 }}>
            <CardContent>
              <Grid2 container spacing={2}>
                <Grid2 container size={12}>
                  <Grid2 size={6} display={'flex'} justifyContent={'space-between'}>
                    <Typography variant="subtitle" fontWeight={'bold'}>
                      Scheme:
                    </Typography>
                    {peppolParticipant.scheme}
                  </Grid2>
                  <Grid2 size={6} display={'flex'} justifyContent={'space-between'}>
                    <Typography variant="subtitle" fontWeight={'bold'}>
                      Identifier Code:
                    </Typography>
                    {peppolParticipant.identifier_code}
                  </Grid2>
                  <Grid2 size={6} display={'flex'} justifyContent={'space-between'}>
                    <Typography variant="subtitle" fontWeight={'bold'}>
                      Special Identifier:
                    </Typography>
                    {peppolParticipant.special_identifier}
                  </Grid2>
                  <Grid2 size={6} display={'flex'} justifyContent={'space-between'}>
                    <Typography variant="subtitle" fontWeight={'bold'}>
                      Identifier:
                    </Typography>
                    {peppolParticipant.identifier}
                  </Grid2>
                  <Grid2 size={6} display={'flex'} justifyContent={'space-between'}>
                    <Typography variant="subtitle" fontWeight={'bold'}>
                      Endpoint ID:
                    </Typography>
                    {peppolParticipant.endpoint_id}
                  </Grid2>
                </Grid2>
              </Grid2>
            </CardContent>
          </Card>
        )
      )}
    </>
  );
}
