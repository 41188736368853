import BusinessProfileForm from '../components/BusinessProfileForm';
import {
  cancelBusinessProfileCreate,
  createBusinessProfileRequest,
} from '../../../api/businessProfiles/businessProfilesSlice';
import { useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { useEffect } from 'react';
import useDocumentTitle from '../../../hooks/useDocumentTitle';
import { Stack } from '@mui/material';

const initialData = {
  name: '',
  company_name: '',
  company_name_in_local: '',
  email: '',
  address1: '',
  address2: '',
  address3: '',
  city: '',
  country_code: '',
  state: '',
  postal_code: '',
  phone: '',
  registration_type: '',
  registration_number: '',
  tin: '',
  sst_registration_number: '-',
  tourism_tax_registration_number: '-',
  msic_code: '',
};

export default function CreateBusinessProfilePage() {
  useDocumentTitle('New Business Profile Form');
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const { isBusinessProfileCreated, error } = useSelector((state) => state.businessProfiles);

  useEffect(() => {
    if (isBusinessProfileCreated) {
      navigate('/business-profiles');
    }
  }, [isBusinessProfileCreated, navigate]);

  const handleSubmit = async (data) => {
    const payload = {
      ...data,
      company_name: data.company_name ? data.company_name : data.name,
      country_code: data.country_code.key,
      state: data.state.key,
      registration_type: data.registration_type.key,
      msic_code: data.msic_code.key,
    };

    delete payload.participant_id;
    await dispatch(createBusinessProfileRequest(payload));
  };

  const handleCancel = async () => {
    await dispatch(cancelBusinessProfileCreate());
    return navigate('/business-profiles');
  };

  return (
    <Stack direction="column" sx={{ mt: '-2rem' }}>
      <BusinessProfileForm
        onSubmit={handleSubmit}
        onCancel={handleCancel}
        initialData={initialData}
        apiErrors={error}
      ></BusinessProfileForm>
    </Stack>
  );
}
