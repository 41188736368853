import { takeLatest, call, put } from 'redux-saga/effects';
import { fetchStatesRequest, fetchStatesSuccess, fetchStatesFailure } from './statesSlice';
import { fetchCountryCodesRequest, fetchCountryCodesSuccess, fetchCountryCodesFailure } from './countryCodesSlice';
import { fetchMsicCodesRequest, fetchMsicCodesSuccess, fetchMsicCodesFailure } from './msicCodesSlice';
import { apiClient } from '../../utils/axios';
import { fetchUomCodesFailure, fetchUomCodesRequest, fetchUomCodesSuccess } from './uomSlice';
import { fetchCurrenciesFailure, fetchCurrenciesRequest, fetchCurrenciesSuccess } from './currencySlice';
import { fetchTaxTypesFailure, fetchTaxTypesRequest, fetchTaxTypesSuccess } from './taxTypesSlice';
import { fetchPaymentTermsFailure, fetchPaymentTermsRequest, fetchPaymentTermsSuccess } from './paymentMeansSlice';
import { fetchItemCodesFailure, fetchItemCodesRequest, fetchItemCodesSuccess } from './itemCodesSlice';
import {
  fetchAdditionalIdentifiersFailure,
  fetchAdditionalIdentifiersRequest,
  fetchAdditionalIdentifiersSuccess,
} from './additionalIdentifiersSlice';

function* fetchStatesSaga() {
  try {
    const response = yield call(apiClient.get, '/mys/state');
    yield put(fetchStatesSuccess(response.data));
  } catch (error) {
    yield put(fetchStatesFailure(error.message));
  }
}

function* fetchCountryCodesSaga() {
  try {
    const response = yield call(apiClient.get, '/mys/country');
    yield put(fetchCountryCodesSuccess(response.data));
  } catch (error) {
    yield put(fetchCountryCodesFailure(error.message));
  }
}

function* fetchMsicCodesSaga() {
  try {
    const response = yield call(apiClient.get, '/mys/msic');
    yield put(fetchMsicCodesSuccess(response.data));
  } catch (error) {
    yield put(fetchMsicCodesFailure(error.message));
  }
}

function* fetchUomCodesSaga() {
  try {
    const response = yield call(apiClient.get, '/mys/uom');
    yield put(fetchUomCodesSuccess(response.data));
  } catch (error) {
    yield put(fetchUomCodesFailure(error.message));
  }
}

function* fetchCurrencyCodesSaga() {
  try {
    const response = yield call(apiClient.get, '/mys/currency');
    yield put(fetchCurrenciesSuccess(response.data));
  } catch (error) {
    yield put(fetchCurrenciesFailure(error.message));
  }
}

function* fetchTaxTypesSaga() {
  try {
    const response = yield call(apiClient.get, '/mys/tax_type');
    yield put(fetchTaxTypesSuccess(response.data));
  } catch (error) {
    yield put(fetchTaxTypesFailure(error.message));
  }
}

function* fetchPaymentTermsSaga() {
  try {
    const response = yield call(apiClient.get, '/mys/payment_means');
    yield put(fetchPaymentTermsSuccess(response.data));
  } catch (error) {
    yield put(fetchPaymentTermsFailure(error.message));
  }
}

function* fetchItemCodesSaga() {
  try {
    const response = yield call(apiClient.get, '/mys/item_code');
    yield put(fetchItemCodesSuccess(response.data));
  } catch (error) {
    yield put(fetchItemCodesFailure(error.message));
  }
}

function* fetchAdditionalIdentifiersSaga() {
  try {
    const response = yield call(apiClient.get, '/mys/additional_identifiers');
    yield put(fetchAdditionalIdentifiersSuccess(response.data));
  } catch (error) {
    yield put(fetchAdditionalIdentifiersFailure(error.message));
  }
}

function* constantsSaga() {
  yield takeLatest(fetchStatesRequest.type, fetchStatesSaga);
  yield takeLatest(fetchCountryCodesRequest.type, fetchCountryCodesSaga);
  yield takeLatest(fetchMsicCodesRequest.type, fetchMsicCodesSaga);
  yield takeLatest(fetchUomCodesRequest.type, fetchUomCodesSaga);
  yield takeLatest(fetchCurrenciesRequest.type, fetchCurrencyCodesSaga);
  yield takeLatest(fetchTaxTypesRequest.type, fetchTaxTypesSaga);
  yield takeLatest(fetchPaymentTermsRequest.type, fetchPaymentTermsSaga);
  yield takeLatest(fetchItemCodesRequest.type, fetchItemCodesSaga);
  yield takeLatest(fetchAdditionalIdentifiersRequest.type, fetchAdditionalIdentifiersSaga);
}

export default constantsSaga;
