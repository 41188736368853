import React, { useEffect, useState } from 'react';
import { DataGrid } from '@mui/x-data-grid';
import { useDispatch, useSelector } from 'react-redux';
import { Box, Typography } from '@mui/material';
import { fetchCreditNotesRequest } from '../../../api/creditNotes/creditNotesSlice';
import { useNavigate } from 'react-router-dom';
import DraftDocumentActions from '../../selfBilledDraftDocuments/components/DraftDocumentActions';
import { formatSource } from '../../../utils/helper';
import { currencyFormatter } from '../../../utils/currencyFormat';
import { formatCurrency } from '../../../utils/formatDecimal';

const DraftCreditNoteTable = ({ searchQuery, searchFilter }) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { creditNotes, totalDocuments: totalCns } = useSelector((state) => state.creditNotes);
  const [page] = useState(0);
  const [limit] = useState(10);

  useEffect(() => {
    const query = { page: page + 1, limit, status: 'draft', document_type: '02' };
    if (searchQuery) {
      query[searchFilter] = searchQuery;
    }
    dispatch(fetchCreditNotesRequest({ query }));
  }, [dispatch, page, limit, searchQuery, searchFilter]);

  const columns = [
    {
      field: 'document_number',
      headerName: 'Credit Note No',
      flex: 1,
      renderCell: ({ row }) => (
        <Typography
          variant="body1"
          sx={{
            textDecoration: 'underline',
            height: '100%',
            display: 'flex',
            alignItems: 'center',
            pl: '0.7rem',
          }}
          onClick={() => navigate(`/drafts/credit-notes/${row.document_id}`)}
        >
          {row.document_number}
        </Typography>
      ),
    },
    {
      field: 'document_date',
      headerName: 'Submission Date',
      flex: 1,
    },
    {
      field: 'source',
      headerName: 'Submission channel',
      flex: 1,
      valueFormatter: (params) => formatSource(params),
    },
    {
      field: 'total_tax_amount',
      headerName: 'Tax',
      flex: 0.5,
      renderCell: ({ row }) => currencyFormatter(row.document_currency_code).format(formatCurrency(row.grand_total)),
    },
    {
      field: 'total_payable_amount',
      headerName: 'Total',
      flex: 0.5,
      renderCell: ({ row }) => currencyFormatter(row.document_currency_code).format(formatCurrency(row.grand_total)),
    },
    {
      field: 'row',
      headerName: 'Actions',
      flex: 0.3,
      sortable: false,
      renderCell: ({ row }) => (
        (<DraftDocumentActions handleDetailsClickAction={() => navigate(`/drafts/credit-notes/${row.document_id}`)} />),
        (
          <DraftDocumentActions
            handleRequestEDocumentAction={() =>
              navigate(`/documents/credit-notes/create`, {
                state: {
                  document: row,
                  from: location.pathname,
                },
              })
            }
          />
        )
      ),
    },
  ];

  return (
    <Box>
      <DataGrid
        rows={creditNotes}
        columns={columns}
        getRowId={(row) => row._id}
        paginationMode="server"
        rowCount={totalCns}
        initialState={{
          pagination: {
            paginationModel: {
              pageSize: limit,
            },
          },
        }}
        pageSizeOptions={[10, 25]}
        disableRowSelectionOnClick
        onPaginationModelChange={(model) => {
          dispatch(
            fetchCreditNotesRequest({
              query: {
                page: model.page + 1,
                limit: model.pageSize,
                status: 'draft',
              },
            }),
          );
        }}
      />
    </Box>
  );
};

export default DraftCreditNoteTable;
