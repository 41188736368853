import React, { useEffect } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { clearSelectedDebitNote, fetchDebitNoteById } from '../../../api/debitNotes/debitNotesSlice';
import { Box, Stack } from '@mui/material';
import TopBar from '../../../components/TopBar';
import useDocumentTitle from '../../../hooks/useDocumentTitle';
import DebitNoteForm from '../../debitNotes/components/DebitNoteForm';
import DetailsPageActionButtons from '../components/DraftDetailsPageActions';

export default function DraftsDebitNoteDetailsPage() {
  useDocumentTitle('Debit Note Details');
  const { document_id } = useParams();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const debitNote = useSelector((state) => state.debitNotes.selectedDebitNote);

  useEffect(() => {
    if (document_id) {
      dispatch(fetchDebitNoteById(document_id));
    }
    //The function which is returned by useEffect will act as cleanup of what useEffect does
    return () => {
      dispatch(clearSelectedDebitNote());
    };
  }, [dispatch, document_id]);
  if (!debitNote) return null;

  const handleBack = () => {
    navigate('/drafts?tab=debitNotes');
  };

  if (!debitNote) return null;

  return (
    <Stack direction="column" sx={{ mt: '-1rem' }}>
      <Box sx={{ pb: 4 }}>
        <TopBar title="Debit Note Details" subTitle={debitNote?.document_id}>
          <DetailsPageActionButtons onBack={handleBack} />
        </TopBar>
      </Box>
      <DebitNoteForm onSubmit={() => {}} onCancel={() => {}} initialData={debitNote} readOnly={true} />
    </Stack>
  );
}
