import { call, put, takeLatest } from 'redux-saga/effects';
import { apiClient } from '../../utils/axios';
import { fetchDashboardRequest, fetchDashboardSuccess, fetchDashboardFailure } from './dashboardSlice';

function* fetchDashboardSaga(action) {
  try {
    const { startDate, endDate } = action.payload;
    const response = yield call(apiClient.get, `/dashboard`, {
      params: { startDate, endDate },
    });

    yield put(fetchDashboardSuccess(response.data));
  } catch (error) {
    yield put(fetchDashboardFailure(error.response?.data?.message || 'Failed to fetch dashboard data'));
  }
}

export default function* dashboardSaga() {
  yield takeLatest(fetchDashboardRequest.type, fetchDashboardSaga);
}
