import React from 'react';
import { useSelector } from 'react-redux';
import { Backdrop, CircularProgress } from '@mui/material';
import { appLoadingState } from '../api/app/appStateSelector';

export default function Loader() {
  let loading = useSelector(appLoadingState);

  if (loading) {
    return (
      <Backdrop
        open={loading}
        sx={{
          color: '#fff',
          zIndex: (theme) => theme.zIndex.drawer + 1,
        }}
      >
        <CircularProgress color="inherit" />
      </Backdrop>
    );
  }
}
