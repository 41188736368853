import React from 'react';
import {
  Box,
  Button,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  IconButton,
  TextField,
  Typography,
} from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';

const CancelDocumentDialog = ({ open, onClose, onSubmit, reason, onReasonChange, maxLength }) => {
  const remaining = maxLength - reason.length;
  const progress = (reason.length / maxLength) * 100;

  return (
    <Dialog open={open} onClose={onClose}>
      <DialogTitle>
        <div
          style={{
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'center',
          }}
        >
          <Typography variant="h6">Cancel Document</Typography>
          <IconButton onClick={onClose}>
            <CloseIcon />
          </IconButton>
        </div>
      </DialogTitle>
      <DialogContent>
        <Typography variant="body2" gutterBottom>
          Please enter the reason for cancellation (max {maxLength} characters):
        </Typography>
        <Box position="relative">
          <TextField
            fullWidth
            multiline
            rows={4}
            variant="outlined"
            placeholder="Enter cancellation reason"
            value={reason}
            onChange={onReasonChange}
          />
          <Box
            position="absolute"
            bottom="8px"
            right="8px"
            display="flex"
            alignItems="center"
            justifyContent="center"
            zIndex={1}
          >
            <CircularProgress
              variant="determinate"
              value={progress > 100 ? 100 : progress}
              size={32}
              thickness={5}
              color={remaining < 0 ? 'error' : 'primary'}
            />
            <Typography
              variant="caption"
              component="div"
              sx={{
                position: 'absolute',
                fontWeight: 'bold',
                color: remaining < 0 ? 'error.main' : 'text.primary',
              }}
            >
              {remaining}
            </Typography>
          </Box>
        </Box>
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose} color="secondary" variant="outlined">
          Close
        </Button>
        <Button onClick={onSubmit} color="primary" variant="contained" disabled={remaining < 0 || !reason.trim()}>
          Cancel Document
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default CancelDocumentDialog;
