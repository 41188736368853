import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  additionalIdentifiers: [],
  loading: false,
  error: null,
};

const additionalIdentifiersSlice = createSlice({
  name: 'additionalIdentifiers',
  initialState,
  reducers: {
    fetchAdditionalIdentifiersRequest(state) {
      state.loading = true;
      state.error = null;
    },
    fetchAdditionalIdentifiersSuccess(state, action) {
      state.loading = false;
      state.additionalIdentifiers = action.payload;
    },
    fetchAdditionalIdentifiersFailure(state, action) {
      state.loading = false;
      state.error = action.payload;
    },
  },
});

export const {
  fetchAdditionalIdentifiersRequest,
  fetchAdditionalIdentifiersSuccess,
  fetchAdditionalIdentifiersFailure,
} = additionalIdentifiersSlice.actions;

export default additionalIdentifiersSlice.reducer;
