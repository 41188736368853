import React, { useCallback, useEffect, useState } from 'react';
import { Controller } from 'react-hook-form';
import { Autocomplete, Box, Button, CircularProgress, TextField } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { fetchSuppliersRequest } from '../../api/suppliers/suppliersSlice';
import { debounce } from 'lodash';

const SupplierSelect = ({ label = 'Billed by', name, control, errors, readOnly = false }) => {
  const dispatch = useDispatch();
  const { suppliers, loading } = useSelector((state) => state.suppliers);
  const [searchTerm, setSearchTerm] = useState();
  const navigate = useNavigate();

  const modifySearchTerm = useCallback(
    debounce((value) => {
      setSearchTerm(value);
    }, 500),
    [],
  );

  useEffect(() => {
    dispatch(
      fetchSuppliersRequest({
        query: {
          page: 1,
          limit: 10,
          name: searchTerm || undefined,
        },
      }),
    );
  }, [searchTerm, dispatch]);

  return (
    <Controller
      name={name}
      control={control}
      render={({ field }) => (
        <Box display="flex" alignItems="flex-start" gap={2}>
          <Autocomplete
            {...field}
            fullWidth
            loading={loading}
            readOnly={readOnly}
            options={suppliers || []}
            getOptionLabel={(option) =>
              `${option?.name || ''} | ${option?.email || ''} | ${option?.registration_number || ''}`
            }
            getOptionKey={(option) => option?._id}
            renderInput={(params) => (
              <TextField
                {...params}
                label={label}
                error={!!errors[name]}
                helperText={errors[name]?.message}
                slotProps={{
                  input: {
                    ...params.InputProps,
                    endAdornment: (
                      <React.Fragment>
                        {loading ? <CircularProgress color="inherit" size={20} /> : null}
                        {params.InputProps.endAdornment}
                      </React.Fragment>
                    ),
                  },
                }}
              />
            )}
            isOptionEqualToValue={(option, value) => option.id === value?.id} // Compare unique IDs
            onChange={(_, value) => field.onChange(value)}
            value={field.value || null}
            onInputChange={(event, value, reason) => {
              if (reason === 'input') {
                modifySearchTerm(value);
              }
            }}
            filterOptions={(x) => x}
          />
          {!readOnly && (
            <Button
              variant="contained"
              color="primary"
              sx={{ py: 1 }}
              onClick={() => navigate('/business-profiles/create')}
            >
              Add
            </Button>
          )}
        </Box>
      )}
    />
  );
};

export default SupplierSelect;
