import React from 'react';
import { Card, CardHeader, CardContent, Box, Divider, Grid2 as Grid, Typography, Skeleton } from '@mui/material';
import PieGraph, { COLORS } from './PieChart';

const ComplianceCard = ({ title, data, loading }) => {
  return (
    <Card elevation={3}>
      <CardHeader
        title={loading ? <Skeleton variant="text" width={150} height={40} /> : title}
        subheader={
          loading ? (
            <Skeleton variant="text" width={100} height={30} />
          ) : (
            `Compliance: ${((data.accepted / (data.accepted + data.rejected)) * 100).toFixed(2)}%`
          )
        }
        titleTypographyProps={{ variant: 'h6' }}
        sx={{ pb: 0 }}
      />
      <CardContent sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
        <Box sx={{ width: '100%', display: 'flex', justifyContent: 'center', mb: 2 }}>
          {loading ? <Skeleton variant="circular" width={200} height={200} /> : <PieGraph data={data} />}
        </Box>
        <Box mt={2} sx={{ width: '100%' }}>
          <Divider />
          <Grid container spacing={2} sx={{ mt: 1, px: 2, textAlign: 'center' }}>
            {['Accepted', 'Rejected', 'Submitted', 'Cancelled'].map((label, index) => (
              <Grid size={{ xs: 3 }} key={index}>
                <Typography variant="body2" color="text.secondary">
                  {loading ? <Skeleton variant="text" width={60} height={30} /> : label}
                </Typography>
                <Typography variant="h6" color={COLORS[label]}>
                  {loading ? <Skeleton variant="text" width={40} height={40} /> : data[label.toLowerCase()]}
                </Typography>
              </Grid>
            ))}
          </Grid>
        </Box>
      </CardContent>
    </Card>
  );
};

export default ComplianceCard;
