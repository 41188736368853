import { call, put, takeLatest } from 'redux-saga/effects';
import {
  cancelDebitNoteFailure,
  cancelDebitNoteRequest,
  cancelDebitNoteSuccess,
  debitNoteEDocFailure,
  debitNoteEDocRequest,
  debitNoteEDocSuccess,
  createDebitNoteFailure,
  createDebitNoteRequest,
  createDebitNoteSuccess,
  fetchDebitNoteById,
  fetchDebitNoteByIdFailure,
  fetchDebitNoteByIdSuccess,
  fetchDebitNotesFailure,
  fetchDebitNotesRequest,
  fetchDebitNotesSuccess,
} from './debitNotesSlice';
import { apiClient } from '../../utils/axios';
import { showSnackbar } from '../snackbarSlice';

function* fetchDebitNotesSaga(action) {
  try {
    const { query } = action.payload;
    const response = yield call(apiClient.get, '/debit-notes', { params: query });
    yield put(fetchDebitNotesSuccess(response.data));
  } catch (error) {
    yield put(fetchDebitNotesFailure(error.message));
  }
}

function* fetchDebitNoteByIdSaga(action) {
  try {
    const response = yield call(apiClient.get, `/debit-notes/${action.payload}`);
    yield put(fetchDebitNoteByIdSuccess(response.data));
  } catch (error) {
    yield put(fetchDebitNoteByIdFailure(error.message));
  }
}

function* createDebitNoteSaga(action) {
  try {
    const response = yield call(apiClient.post, '/debit-notes', action.payload);
    yield put(createDebitNoteSuccess(response.data));
    yield put(showSnackbar({ message: 'Debit Note created successfully!', severity: 'success' }));
  } catch (error) {
    yield put(createDebitNoteFailure(error.response?.data?.errors));
  }
}

function* debitNoteEDocSaga(action) {
  try {
    const response = yield call(apiClient.put, `/debit-notes/${action.payload.document_id}`, action.payload);
    yield put(debitNoteEDocSuccess(response.data));
    yield put(
      showSnackbar({
        message: 'Conversion request for Staged Debit Note to E-Debit Note accepted!',
        severity: 'success',
      }),
    );
  } catch (error) {
    yield put(debitNoteEDocFailure(JSON.stringify(error.response?.data?.errors)));
  }
}

function* cancelDebitNoteSaga(action) {
  try {
    const { url, reason } = action.payload;
    const data = { reason };
    const response = yield call(apiClient.put, url, data);
    yield put(cancelDebitNoteSuccess(response.data));
  } catch (error) {
    yield put(cancelDebitNoteFailure(error.response?.data?.errors));
  }
}

function* debitNotesSaga() {
  yield takeLatest(fetchDebitNotesRequest.type, fetchDebitNotesSaga);
  yield takeLatest(fetchDebitNoteById.type, fetchDebitNoteByIdSaga);
  yield takeLatest(createDebitNoteRequest.type, createDebitNoteSaga);
  yield takeLatest(debitNoteEDocRequest.type, debitNoteEDocSaga);
  yield takeLatest(cancelDebitNoteRequest.type, cancelDebitNoteSaga);
}

export default debitNotesSaga;
