import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { fetchRefundsRequest, markRefundCreated } from '../../../api/refunds/refundsSlice';
import TopBar from '../../../components/TopBar';
import useDocumentTitle from '../../../hooks/useDocumentTitle';
import RefundListPageActions from '../components/RefundListPageActions';
import { Stack } from '@mui/material';
import RefundTable from '../components/RefundTable';
import RefundFilters from '../components/RefundFilters';

const filters = [
  {
    type: 'search',
    options: [
      {
        key: 'document_number',
        placeholder: 'Refund Number',
      },
      {
        key: 'invoice_number',
        placeholder: 'Invoice Number',
      },
    ],
  },
];

const leadingActions = [
  {
    label: '+ Raise Refund',
    action: 'create',
    target: '/refunds/create',
  },
];

let defaultQuery = {
  document_type: '04',
  status: ['active', 'obsolete'],
};

function RefundListPage() {
  useDocumentTitle('Refund List');
  const dispatch = useDispatch();
  const [selectedFilter, setSelectedFilter] = useState(filters.length > 0 ? filters[0].options[0].key : '');
  const [searchTerm, setSearchTerm] = useState('');
  const [currentQuery, setCurrentQuery] = useState({});

  useEffect(() => {
    dispatch(markRefundCreated(false));
    dispatch(fetchRefundsRequest({ query: defaultQuery }));
  }, [dispatch]);

  const handleFilterChange = (event) => {
    setSelectedFilter(event.target.value);
    const searchBy = event.target.value;
    const query = { ...defaultQuery, page: 1, limit: 10 };
    if (searchTerm) {
      query[searchBy] = searchTerm;
    }
    dispatch(
      fetchRefundsRequest({
        query,
      }),
    );
  };

  const handleSearchChange = (event) => setSearchTerm(event.target.value);

  const handleSearchKeyPress = (event) => {
    const query = { ...defaultQuery, page: 1, limit: 10 };
    if (searchTerm) {
      query[selectedFilter] = searchTerm;
    }
    if (event.key === 'Enter') {
      setCurrentQuery(query);
      dispatch(
        fetchRefundsRequest({
          query,
        }),
      );
    }
  };

  return (
    <Stack direction="column" sx={{ mt: '-1rem' }}>
      <TopBar title="Refund List">
        <RefundListPageActions actions={leadingActions} />
      </TopBar>
      <RefundFilters
        filters={filters}
        selectedFilter={selectedFilter}
        onFilterChange={handleFilterChange}
        searchTerm={searchTerm}
        onSearchChange={handleSearchChange}
        onSearchKeyPress={handleSearchKeyPress}
      />
      <RefundTable searchQuery={currentQuery} />
    </Stack>
  );
}

export default RefundListPage;
