export const lineItemCalculation = (item) => {
  const baseAmount = item.quantity * item.unit_rate;

  const totalItemDiscount = item.discounts.reduce((total, discount) => {
    const discountValue = Number(discount.value);
    if (discount.type === 'percentage') {
      discount.amount = (baseAmount * discountValue) / 100;
    } else {
      discount.amount = discountValue;
    }
    return total + discount.amount;
  }, 0);

  const discountAmount = parseFloat(totalItemDiscount.toFixed(8));

  const chargeAmounts = (item.charges || []).reduce((total, charge) => {
    const charge_amount = Number(charge.value);
    if (charge.type === 'percentage') {
      charge.amount = (baseAmount * charge_amount) / 100;
    } else {
      charge.amount = charge_amount;
    }
    return total + charge.amount;
  }, 0);

  const netAmount = baseAmount - discountAmount + chargeAmounts;
  const taxAmount = item.taxes.reduce((total, tax) => {
    const taxValue = Number(tax.value);
    if (tax.type === 'percentage') {
      tax.amount = (netAmount * taxValue) / 100;
    } else {
      tax.amount = taxValue;
    }
    return total + tax.amount;
  }, 0);

  const totalAmount = netAmount + taxAmount;

  return {
    ...item,
    discount_amount: Number(discountAmount || 0),
    unit_rate: Number(item.unit_rate || 0),
    quantity: Number(item.quantity || 0),
    base_amount: Number(baseAmount.toFixed(2)),
    net_amount: Number(netAmount.toFixed(2)),
    charge_amount: Number(chargeAmounts.toFixed(8)),
    tax_amount: Number(taxAmount.toFixed(2)),
    total_amount: Number(totalAmount.toFixed(2)),
  };
};

const pricingEngineForDocuments = (items = [], charges = [], discounts = [], prePayment = {}) => {
  if (!Array.isArray(items)) {
    throw new Error("Expected 'items' to be an array.");
  }
  const subtotal = items.reduce((acc, item) => acc + item.quantity * item.unit_rate, 0);
  const totalTaxAmount = items.reduce((acc, item) => acc + Number(item.tax_amount), 0);

  const totalItemDiscount = items.reduce((acc, item) => {
    return acc + item.discount_amount;
  }, 0);

  const total_item_charges = items.reduce((acc, item) => {
    return acc + item.charge_amount;
  }, 0);

  const totalExcludingTax = subtotal - totalItemDiscount + total_item_charges;
  const totalIncludingTax = totalExcludingTax + Number(totalTaxAmount);
  const totalPayableAmount = totalIncludingTax - (prePayment.amount || 0);

  // Calculate total charges
  const updatedCharges = charges.map((charge) => {
    const chargeAmount = Number(charge.value);
    const updatedAmount =
      charge.type === 'percentage' ? ((totalIncludingTax * chargeAmount) / 100).toFixed(2) : chargeAmount.toFixed(2);

    return { ...charge, amount: parseFloat(updatedAmount) };
  });

  const header_charges = updatedCharges.reduce((total, charge) => total + charge.amount, 0);

  // Calculate total discounts
  const updatedDiscounts = discounts.map((discount) => {
    const discountAmount = Number(discount.value);
    const updatedAmount =
      discount.type === 'percentage'
        ? ((totalIncludingTax * discountAmount) / 100).toFixed(2)
        : discountAmount.toFixed(2);

    return { ...discount, amount: parseFloat(updatedAmount) };
  });

  const header_discount = updatedDiscounts.reduce((total, discount) => total + discount.amount, 0);

  return {
    subtotal,
    charges: updatedCharges,
    discounts: updatedDiscounts,
    headerCharges: Number(header_charges.toFixed(2)),
    headerDiscount: Number(header_discount.toFixed(2)),
    totalTaxAmount: Number(totalTaxAmount.toFixed(2)),
    totalItemDiscount: Number(totalItemDiscount.toFixed(2)),
    totalItemCharges: Number(total_item_charges.toFixed(2)),
    totalExcludingTax: Number(totalExcludingTax.toFixed(2)),
    totalIncludingTax: Number(totalIncludingTax.toFixed(2)),
    totalPayableAmount: Number(totalPayableAmount.toFixed(2)),
    grandTotal: Number((totalPayableAmount + header_charges - header_discount).toFixed(2)),
  };
};

export default pricingEngineForDocuments;
