import React from 'react';
import { ToggleButtonGroup, ToggleButton, Skeleton, Box } from '@mui/material';

const timeRanges = [
  { label: 'Last Week', value: 'week' },
  { label: 'Last Month', value: 'month' },
  { label: 'Last Quarter', value: 'quarter' },
];

const TimeRangeSelector = ({ timeRange, handleTimeRangeChange, loading }) => {
  return (
    <Box sx={{ display: 'flex', justifyContent: 'flex-end', mb: 2, alignItems: 'center' }}>
      {loading ? (
        <Skeleton variant="rectangular" width={300} height={40} />
      ) : (
        <ToggleButtonGroup value={timeRange} exclusive onChange={handleTimeRangeChange} aria-label="Time Range">
          {timeRanges.map((range) => (
            <ToggleButton key={range.value} value={range.value}>
              {range.label}
            </ToggleButton>
          ))}
        </ToggleButtonGroup>
      )}
    </Box>
  );
};

export default TimeRangeSelector;
