import React from 'react';
import { IconButton, Stack, Typography, useTheme } from '@mui/material';
import '../styles/TopBar.css';
import ContentCopy from '@mui/icons-material/ContentCopy';

const TopBar = ({ title, subTitle, children }) => {
  const theme = useTheme();

  const handleCopy = () => {
    navigator.clipboard.writeText(subTitle);
  };

  return (
    <Stack direction="row" sx={{ justifyContent: 'space-between', alignItems: 'center', mb: '1rem' }}>
      <Stack direction="row" sx={{ alignItems: 'center' }} spacing={2}>
        <Typography variant="h5" component="div">
          {title}
        </Typography>
        {subTitle && (
          <>
            <Typography variant="h6">| {subTitle}</Typography>
            <IconButton aria-label="copy" onClick={handleCopy}>
              <ContentCopy fontSize="small" />
            </IconButton>
          </>
        )}
      </Stack>
      {children}
    </Stack>
  );
};

export default TopBar;
