import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  bulkUploads: [],
  selectedBulkUpload: null,
  currentPage: 1,
  totalPages: 1,
  totalDocuments: 0,
  loading: false,
  isBulkUploadProceeded: false,
  isBulkUploadReportDownloaded: false,
  isTemplateDownloaded: false,
  error: null,
};

const bulkUploadSlice = createSlice({
  name: 'bulkUploads',
  initialState,
  reducers: {
    fetchBulkUploadRequest(state) {
      state.loading = true;
      state.error = null;
    },
    fetchBulkUploadSuccess(state, action) {
      state.loading = false;
      state.bulkUploads = action.payload.bulk_uploads;
      state.currentPage = action.payload.currentPage;
      state.totalPages = action.payload.totalPages;
      state.totalDocuments = action.payload.totalDocuments;
    },
    fetchBulkUploadFailure(state, action) {
      state.loading = false;
      state.error = action.payload;
    },
    fetchBulkUploadById(state) {
      state.loading = true;
      state.error = null;
    },
    fetchBulkUploadByIdSuccess(state, action) {
      state.loading = false;
      state.selectedBulkUpload = action.payload;
    },
    fetchBulkUploadByIdFailure(state, action) {
      state.loading = false;
      state.error = action.payload;
    },
    proceedBulkUploadRequest(state) {
      state.loading = true;
      state.error = null;
      state.isBulkUploadProceeded = false;
    },
    proceedBulkUploadSuccess(state) {
      state.loading = false;
      state.error = null;
      state.isBulkUploadProceeded = true;
    },
    proceedBulkUploadFailure(state, action) {
      state.loading = false;
      state.isBulkUploadProceeded = false;
      state.error = action.payload;
    },
    downloadBulkUploadReportRequest(state) {
      state.loading = true;
      state.error = null;
      state.isBulkUploadReportDownloaded = false;
    },
    downloadBulkUploadReportSuccess(state) {
      state.loading = false;
      state.error = null;
      state.isBulkUploadReportDownloaded = true;
    },
    downloadBulkUploadReportFailure(state, action) {
      state.loading = false;
      state.isBulkUploadReportDownloaded = false;
      state.error = action.payload;
    },
    markBulkUploadProceeded(state, action) {
      state.isBulkUploadProceeded = action.payload;
    },
    downloadTemplateRequest(state) {
      state.loading = true;
      state.error = null;
      state.isTemplateDownloaded = false;
    },
    downloadTemplateSuccess(state) {
      state.loading = false;
      state.error = null;
      state.isTemplateDownloaded = true;
    },
    downloadTemplateFailure(state, action) {
      state.loading = false;
      state.isTemplateDownloaded = false;
      state.error = action.payload;
    },
  },
});

export const {
  fetchBulkUploadRequest,
  fetchBulkUploadSuccess,
  fetchBulkUploadFailure,
  fetchBulkUploadById,
  fetchBulkUploadByIdSuccess,
  fetchBulkUploadByIdFailure,
  proceedBulkUploadRequest,
  proceedBulkUploadSuccess,
  proceedBulkUploadFailure,
  markBulkUploadProceeded,
  downloadBulkUploadReportRequest,
  downloadBulkUploadReportSuccess,
  downloadBulkUploadReportFailure,
  downloadTemplateRequest,
  downloadTemplateSuccess,
  downloadTemplateFailure,
} = bulkUploadSlice.actions;

export default bulkUploadSlice.reducer;
