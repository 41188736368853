import React, { useEffect } from 'react';
import useDocumentTitle from '../../../hooks/useDocumentTitle';
import { useDispatch, useSelector } from 'react-redux';
import CreditNoteForm from '../components/CreditNoteForm';
import { useLocation, useNavigate } from 'react-router-dom';
import {
  createCreditNoteRequest,
  creditNoteEDocRequest,
  markCreditNoteCreated,
} from '../../../api/creditNotes/creditNotesSlice';
import { Stack } from '@mui/material';
import moment from 'moment/moment';

export default function CreateCreditNotePage() {
  useDocumentTitle('Credit Notes Form');
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const location = useLocation();
  const { isCreditNoteCreated, error } = useSelector((state) => state.creditNotes);
  const document = location.state.document;

  useEffect(() => {
    dispatch(markCreditNoteCreated(false));
  }, [dispatch]);

  useEffect(() => {
    if (isCreditNoteCreated) {
      navigate('/documents/credit-notes');
    }
  }, [isCreditNoteCreated, navigate]);

  const onSubmit = async (data) => {
    const payload = {
      ...data,
      document_date: moment(data.document_date).format('YYYY-MM-DD'),
      payment_mode: data.payment_mode.value,
      item_details: data.item_details.map(({ id, ...item }) => ({
        ...item,
      })),
    };

    if (payload.status === 'draft') {
      payload.item_details.forEach((item) => {
        delete item._id;
      });
      await dispatch(creditNoteEDocRequest(payload));
    } else {
      await dispatch(createCreditNoteRequest(payload));
    }
  };

  const onCancel = async () => {
    navigate(location.state?.from || '/documents/credit-notes');
  };

  return (
    <Stack direction="column" sx={{ mt: '-2rem' }}>
      <CreditNoteForm initialData={document || {}} onSubmit={onSubmit} onCancel={onCancel} apiErrors={error} />
    </Stack>
  );
}
