import React, { useEffect } from 'react';
import useDocumentTitle from '../../../hooks/useDocumentTitle';
import { useDispatch, useSelector } from 'react-redux';
import { markBusinessProfileCreated } from '../../../api/businessProfiles/businessProfilesSlice';
import { markCustomerCreated } from '../../../api/customers/customersSlice';
import { useLocation, useNavigate } from 'react-router-dom';
import { createInvoiceRequest, invoiceEDocRequest } from '../../../api/invoices/invoicesSlice';
import SBInvoiceForm from '../components/SBInvoiceForm';
import { Stack } from '@mui/material';
import moment from 'moment/moment';

export default function CreateSBInvoicePage() {
  useDocumentTitle('Invoice Form');
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const location = useLocation();
  const { isInvoiceCreated } = useSelector((state) => state.invoices);
  const document = location.state?.document;

  useEffect(() => {
    dispatch(markBusinessProfileCreated(false));
    dispatch(markCustomerCreated(false));
  }, [dispatch]);

  useEffect(() => {
    if (isInvoiceCreated) {
      navigate('/self-billed-documents/invoices');
    }
  }, [isInvoiceCreated, navigate]);

  const onSubmit = async (data) => {
    const payload = {
      ...data,
      document_date: moment(data.document_date).format('YYYY-MM-DD'),
      payment_mode: data.payment_mode.value,
      item_details: data.item_details.map(({ id, ...item }) => ({
        ...item,
      })),
    };

    if (payload.status === 'draft') {
      payload.item_details.forEach((item) => {
        delete item._id;
      });
      await dispatch(invoiceEDocRequest(payload));
    } else {
      await dispatch(createInvoiceRequest(payload));
    }
  };

  const onCancel = async () => {
    navigate(location.state?.from || '/self-billed-documents/invoices');
  };

  return (
    <Stack direction="column" sx={{ mt: '-2rem' }}>
      <SBInvoiceForm initialData={document || {}} onSubmit={onSubmit} onCancel={onCancel} />
    </Stack>
  );
}
