import React from 'react';
import { Box, Grid2 as Grid, Stack, Typography, useTheme } from '@mui/material';
import { currencyFormatter } from '../../utils/currencyFormat';

const ImportExportDetailsCard = ({ document }) => {
  const theme = useTheme();

  return (
    <Box
      sx={{
        padding: theme.spacing(2),
        border: `1px solid ${theme.palette.divider}`,
        borderRadius: '4px',
        marginBottom: theme.spacing(2),
        backgroundColor: theme.palette.background.paper,
        display: 'flex',
        height: '100%',
        flexDirection: 'column',
        justifyContent: 'space-between',
      }}
    >
      <Typography
        variant="h6"
        sx={{
          fontWeight: theme.typography.fontWeightBold,
        }}
      >
        Import/Export Details
      </Typography>

      <Typography
        variant="subtitle1"
        sx={{
          color: theme.palette.text.disabled,
          marginBottom: theme.spacing(1.5),
        }}
      >
        Import/Export Info
      </Typography>

      <Stack spacing={1.25}>
        {/* Customs Form Reference Number */}
        {document.customs_form_1_9_reference_number && (
          <Grid
            container
            spacing={2}
            sx={{
              backgroundColor: theme.palette.action.hover,
            }}
          >
            <Grid size={6}>Reference Number of Customs Form No. 1,9 etc.:</Grid>
            <Grid size={6}>{document.customs_form_1_9_reference_number}</Grid>
          </Grid>
        )}

        {/* Incoterms */}
        {document.incoterms && (
          <Grid
            container
            spacing={2}
            sx={{
              backgroundColor: 'inherit',
            }}
          >
            <Grid size={6}>Incoterms:</Grid>
            <Grid size={6}>{document.incoterms}</Grid>
          </Grid>
        )}

        {/* FTA Information */}
        {document.fta && (
          <Grid
            container
            spacing={2}
            sx={{
              backgroundColor: theme.palette.action.hover,
            }}
          >
            <Grid item size={6}>
              Free Trade Agreement(FTA) Information:
            </Grid>
            <Grid item size={6}>
              {document.fta}
            </Grid>
          </Grid>
        )}

        {/* Authorization Number for Certified Exporter */}
        {document.authorization_number && (
          <Grid
            container
            spacing={2}
            sx={{
              backgroundColor: 'inherit',
            }}
          >
            <Grid item size={6}>
              Authorization Number for Certified Exporter (ATIGA No.):
            </Grid>
            <Grid item size={6}>
              {document.authorization_number}
            </Grid>
          </Grid>
        )}

        {/* Customs Form Reference Number 2 */}
        {document.customs_form_2_reference_number && (
          <Grid
            container
            spacing={2}
            sx={{
              backgroundColor: theme.palette.action.hover,
            }}
          >
            <Grid item size={6}>
              Reference Number of Customs Form No. 2:
            </Grid>
            <Grid item size={6}>
              {document.customs_form_2_reference_number}
            </Grid>
          </Grid>
        )}

        {/* Other Charges */}
        {document.other_charges && (
          <Grid
            container
            spacing={2}
            sx={{
              backgroundColor: 'inherit',
            }}
          >
            <Grid item size={6}>
              Other Charges:
            </Grid>
            <Grid item size={6}>
              {currencyFormatter().format(document.other_charges)}
            </Grid>
          </Grid>
        )}

        {/* Details of Other Charges */}
        {document.details_of_charge && (
          <Grid
            container
            spacing={2}
            sx={{
              backgroundColor: theme.palette.action.hover,
            }}
          >
            <Grid item size={6}>
              Details of Other Charges:
            </Grid>
            <Grid item size={6}>
              {document.details_of_charge}
            </Grid>
          </Grid>
        )}
      </Stack>
    </Box>
  );
};

export default ImportExportDetailsCard;
