import React, { useState } from 'react';
import { IconButton, Menu, MenuItem } from '@mui/material';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import { get } from 'lodash';
import { useNavigate } from 'react-router-dom';
import CancelDocumentDialog from '../../components/CancelDocumentDialog';
import { apiClient } from '../../utils/axios';
import { useDispatch, useSelector } from 'react-redux';
import { saveAs } from 'file-saver';
import { cancelInvoiceRequest, transmitInvoiceToPeppolRequest } from '../../api/invoices/invoicesSlice';
import { cancelCreditNoteRequest, transmitCNToPeppolRequest } from '../../api/creditNotes/creditNotesSlice';
import { cancelDebitNoteRequest } from '../../api/debitNotes/debitNotesSlice';
import { cancelRefundRequest } from '../../api/refunds/refundsSlice';
import { showSnackbar } from '../../api/snackbarSlice';

const documentlActions = {
  '01': 'invoices',
  11: 'invoices',
  '02': 'credit-notes',
  12: 'credit-notes',
  '03': 'debit-notes',
  13: 'debit-notes',
  '04': 'refunds',
  14: 'refunds',
};

const cancelActions = {
  '01': cancelInvoiceRequest,
  11: cancelInvoiceRequest,
  '02': cancelCreditNoteRequest,
  12: cancelDebitNoteRequest,
  '03': cancelDebitNoteRequest,
  13: cancelDebitNoteRequest,
  '04': cancelRefundRequest,
  14: cancelRefundRequest,
};

const transmitDocToPeppolActions = {
  '01': transmitInvoiceToPeppolRequest,
  '02': transmitCNToPeppolRequest,
  11: transmitInvoiceToPeppolRequest,
  12: transmitCNToPeppolRequest,
};

const SBDocs = [11, 12, 13, 14];

const DocumentActions = ({ row, documentStatus, documentType }) => {
  const [anchorEl, setAnchorEl] = useState(null);
  const basePathPrefix = SBDocs.includes(Number(documentType)) ? '/self-billed-documents' : '/documents';
  const basePath = `/${documentlActions[documentType]}`;
  const navigate = useNavigate();
  const pdfTemplates = useSelector((state) => state.pdfTemplates.pdfTemplates);
  const [open, setOpen] = useState(false);
  const [reason, setReason] = useState('');
  const maxLength = 300;
  const dispatch = useDispatch();

  // Download PDF function
  const downloadPdf = async (documentType, documentContent, documentId) => {
    dispatch(showSnackbar({ message: 'Downloading PDF...', severity: 'info' }));

    try {
      const template = pdfTemplates.find((t) => t.document_type === documentType);

      if (!template) {
        dispatch(showSnackbar({ message: `${documentType} template not found`, severity: 'error' }));
        return;
      }

      const response = await apiClient.get(`/${documentType.toLowerCase()}/${documentId}/download-pdf`, {
        params: { template_name: template.name },
        responseType: 'blob',
      });

      saveAs(response.data, response.headers.get('x-suggested-filename'));
      dispatch(showSnackbar({ message: 'PDF downloaded successfully!', severity: 'success' }));
    } catch (error) {
      dispatch(showSnackbar({ message: 'Failed to download PDF', severity: 'error' }));
      console.error(error);
    }
  };

  // Download XML function
  const downloadXml = (base64XML, documentNumber) => {
    if (!base64XML) {
      dispatch(showSnackbar({ message: 'No XML content available', severity: 'error' }));
      console.error('No XML content available');
      return;
    }

    try {
      dispatch(showSnackbar({ message: 'Downloading XML...', severity: 'info' }));
      const xmlContent = atob(base64XML);
      const blob = new Blob([xmlContent], { type: 'application/xml' });
      saveAs(blob, `${documentNumber}.xml`);
      dispatch(showSnackbar({ message: 'XML downloaded successfully!', severity: 'success' }));
    } catch (error) {
      console.error('Failed to download XML', error);
    }
  };

  const handleCancelDialogOpen = () => {
    setOpen(true);
  };

  const handleCancelDialogClose = () => {
    setOpen(false);
    setReason('');
  };

  // Cancel document function
  const handleCancelDocument = (documentType, document_type, documentId) => {
    const cancelAction = cancelActions[document_type];

    if (!cancelAction) {
      console.error('Invalid document type:', document_type);
      return;
    }

    dispatch(
      cancelAction({
        url: `/${documentType}/${documentId}/cancel`, // Update this URL as needed
        reason,
      }),
    );
    handleCancelDialogClose();
  };

  const handleTransmitDocToPeppol = (document_type, documentId) => {
    const transmitDocToPeppolAction = transmitDocToPeppolActions[documentType];

    if (!transmitDocToPeppolAction) {
      dispatch(
        showSnackbar({
          message: 'This functionality is not yet supported for the selected document type.',
          severity: 'info',
        }),
      );
      return;
    }

    dispatch(transmitDocToPeppolAction({ documentId }));
  };

  const handleReasonChange = (e) => setReason(e.target.value);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleCloseMenu = () => {
    setAnchorEl(null);
  };

  const onClickingDetails = () => {
    navigate(`${basePathPrefix}${basePath}/${row.document_id}`);
  };

  const onClickingEdit = () => {
    const documentCopy = { ...row };

    if (documentCopy.item_details && Array.isArray(documentCopy.item_details)) {
      documentCopy.item_details = documentCopy.item_details.map((item) => {
        const { _id, ...rest } = item;
        return rest;
      });
    }

    console.log('Modified documentCopy:', documentCopy);
    navigate(`${basePathPrefix}${basePath}/create`, { state: { document: documentCopy } });
  };

  return (
    <>
      <IconButton onClick={handleClick}>
        <MoreVertIcon />
      </IconButton>
      <Menu
        anchorEl={anchorEl}
        keepMounted
        open={Boolean(anchorEl)}
        onClose={handleCloseMenu}
        slotProps={{
          paper: {
            style: {
              boxShadow: '4px 4px 10px 0px rgba(0,0,0,0.2)',
            },
          },
        }}
      >
        <MenuItem onClick={onClickingDetails}>Details</MenuItem>
        {get(row, documentStatus.key) === 'Valid' && (
          <MenuItem onClick={() => downloadPdf(documentlActions[documentType], row, row.document_id)}>
            Download PDF
          </MenuItem>
        )}
        {get(row, documentStatus.key) === 'Valid' && (
          <MenuItem onClick={() => downloadXml(get(row, 'irbm_response.reported_invoice'), row.document_number)}>
            Download XML
          </MenuItem>
        )}
        {get(row, documentStatus.key) === 'Valid' && (
          <MenuItem onClick={() => handleTransmitDocToPeppol(documentType, row.document_id)}>
            Transmit Document over Peppol
          </MenuItem>
        )}
        {get(row, 'status') === 'active' &&
          (get(row, documentStatus.key) === 'Invalid' || get(row, documentStatus.key) === 'Cancelled') && (
            <MenuItem onClick={onClickingEdit}>Edit & Submit Document</MenuItem>
          )}
        {get(row, documentStatus.key) === 'Valid' && (
          <MenuItem onClick={handleCancelDialogOpen} style={{ color: 'red' }}>
            Cancel Document
          </MenuItem>
        )}
      </Menu>
      <CancelDocumentDialog
        open={open}
        onClose={handleCancelDialogClose}
        onSubmit={() => handleCancelDocument(documentlActions[documentType], documentType, row.document_id)}
        reason={reason}
        onReasonChange={handleReasonChange}
        maxLength={maxLength}
      />
    </>
  );
};

export default DocumentActions;
