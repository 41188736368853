import React from 'react';
import { Controller } from 'react-hook-form';
import { Autocomplete, TextField } from '@mui/material';
import { useSelector } from 'react-redux';

const PaymentMeansSelect = ({ name, label = 'Payment Mode', control, errors, readOnly = false }) => {
  const paymentMeans = useSelector((state) => state.paymentMeans.paymentMeans);
  const paymentMeansOptions = (paymentMeans.length > 0 ? paymentMeans : paymentMeans).map((item) => ({
    value: item.Code,
    label: item['Payment Method'],
  }));

  return (
    <Controller
      name={name}
      control={control}
      render={({ field }) => (
        <Autocomplete
          {...field}
          options={paymentMeansOptions}
          readOnly={readOnly}
          renderInput={(params) => (
            <TextField {...params} label={label} error={!!errors[name]} helperText={errors[name]?.message} />
          )}
          isOptionEqualToValue={(option, value) => option.value === value.value}
          onChange={(_, value) => field.onChange(value)}
          value={field.value || null} // handle default value
        />
      )}
    />
  );
};

export default PaymentMeansSelect;
