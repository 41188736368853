import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import BusinessProfileListFilters from '../components/BusinessProfileListFilters';
import {
  fetchBusinessProfilesRequest,
  markBusinessProfileCreated,
  markBusinessProfileEdited,
  resetSelectedBusinessProfile,
} from '../../../api/businessProfiles/businessProfilesSlice';
import TopBar from '../../../components/TopBar';
import BusinessListPageActions from '../components/BusinessListPageActions';
import useDocumentTitle from '../../../hooks/useDocumentTitle';
import { Stack } from '@mui/material';
import BusinessProfilesTable from '../components/BusinessProfilesTable';

const filters = [
  {
    type: 'search',
    options: [
      {
        key: 'name',
        placeholder: 'Name',
      },
      {
        key: 'email',
        placeholder: 'Email',
      },
    ],
  },
];

function BusinessProfileListPage() {
  useDocumentTitle('Business Profile List');
  const dispatch = useDispatch();
  const [selectedFilter, setSelectedFilter] = useState(filters.length > 0 ? filters[0].options[0].key : '');
  const [searchTerm, setSearchTerm] = useState('');
  const [currentQuery, setCurrentQuery] = useState({});

  useEffect(() => {
    dispatch(markBusinessProfileCreated(false));
    dispatch(markBusinessProfileEdited(false));
    dispatch(resetSelectedBusinessProfile());
    dispatch(fetchBusinessProfilesRequest({ page: 1, limit: 10 }));
  }, [dispatch]);

  const handleFilterChange = (event) => {
    setSelectedFilter(event.target.value);
    const searchBy = event.target.value;
    const query = { page: 1, limit: 10 };
    if (searchTerm) {
      query[searchBy] = searchTerm;
    }
    dispatch(fetchBusinessProfilesRequest({ query }));
  };

  const handleSearchChange = (event) => setSearchTerm(event.target.value);

  const handleSearchKeyPress = (event) => {
    const query = { page: 1, limit: 10 };
    if (searchTerm) {
      query[selectedFilter] = searchTerm;
    }
    if (event.key === 'Enter') {
      setCurrentQuery(query);
      dispatch(
        fetchBusinessProfilesRequest({
          query,
        }),
      );
    }
  };

  return (
    <Stack direction="column" sx={{ mt: '-1rem' }}>
      <TopBar title="Business Profiles">
        <BusinessListPageActions />
      </TopBar>
      <BusinessProfileListFilters
        filters={filters}
        selectedFilter={selectedFilter}
        onFilterChange={handleFilterChange}
        searchTerm={searchTerm}
        onSearchChange={handleSearchChange}
        onSearchKeyPress={handleSearchKeyPress}
      />
      <BusinessProfilesTable searchQuery={currentQuery} />
    </Stack>
  );
}

export default BusinessProfileListPage;
