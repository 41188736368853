import React from 'react';
import { Chip } from '@mui/material';

const SubscriptionStatusChip = ({ status }) => {
  console.log(status);
  if (status === 'active') {
    return <Chip label="Active" color="success" size="small" />;
  }
  if (status === 'trialing' || status === 'paused') {
    return <Chip label="On Trial" color="warning" size="small" />;
  }
  if (status === 'paused') {
    return <Chip label="Paused" color="warning" size="small" />;
  }
  if (status === 'canceled' || status === 'incomplete_expired') {
    return <Chip label="Cancelled" color="default" size="small"></Chip>;
  }
  if (status === 'past_due' || status === 'unpaid') {
    return <Chip label="Past Due" color="error" size="small"></Chip>;
  }
  if (status === 'incomplete') {
    return <Chip label="Incomplete" color="warning" size="small"></Chip>;
  }
  return <Chip label="Unknown State" color="error" size="small"></Chip>;
};

export default SubscriptionStatusChip;
