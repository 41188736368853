import React from 'react';
import Button from '@mui/material/Button';
import ItemFormModal from './ItemFormModal';

const AddLineItemButton = ({ errors, addItem, buttonText = '+ Add Line Item', name = 'item_details' }) => {
  const [open, setOpen] = React.useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  return (
    <React.Fragment>
      <Button variant="contained" onClick={handleOpen}>
        {buttonText}
      </Button>
      {errors[name] && <span style={{ color: 'red', marginLeft: '5px' }}>{errors[name]?.message}</span>}
      <ItemFormModal open={open} handleClose={handleClose} handleSubmit={addItem} />
    </React.Fragment>
  );
};

export default AddLineItemButton;
