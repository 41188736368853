import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { Stack } from '@mui/material';
import useDocumentTitle from '../../../hooks/useDocumentTitle';
import { fetchPeppolMessagesRequest } from '../../../api/peppolMessages/peppolMessagesSlice';
import PeppolMessagesTable from '../components/PeppolMessagesTable';
import TopBar from '../../../components/TopBar';

function PeppolMessagesListPage() {
  useDocumentTitle('Peppol Messages List');
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(fetchPeppolMessagesRequest({ url: '/peppol-messages', page: 1, limit: 10 }));
  }, [dispatch]);

  return (
    <Stack direction="column" sx={{ mt: '-1rem' }}>
      <TopBar title="Peppol Messages" />
      <PeppolMessagesTable />
    </Stack>
  );
}

export default PeppolMessagesListPage;
