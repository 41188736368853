import React, { useEffect } from 'react';
import { Box, Button, Divider, Fade, IconButton, Modal, Stack, TextField, Typography } from '@mui/material';
import { Controller, useForm } from 'react-hook-form';
import CloseIcon from '@mui/icons-material/Close';
import { yupResolver } from '@hookform/resolvers/yup';
import CustomTextInput from '../../../components/CustomTextInput';
import { PrePaymentValidation } from '../../../validations/PrePaymentValidation';

const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: '500px',
  bgcolor: 'background.paper',
  borderRadius: '15px',
  boxShadow: 24,
};

let defaultState = {
  amount: 0,
  date: '',
  time: '',
  reference_number: '',
};

export default function PrePaymentPopup({ open, onClose, readOnly, addPrePayment, initialValue = defaultState }) {
  const {
    handleSubmit: handlePopupSubmit,
    watch: popupWatch,
    control: popupControl,
    reset: resetPopupForm,
    formState: { errors: popupErrors },
  } = useForm({
    defaultValues: initialValue,
    resolver: yupResolver(PrePaymentValidation),
  });

  useEffect(() => {
    if (open) {
      resetPopupForm(initialValue);
    }
  }, [open, initialValue, resetPopupForm]);

  const handleFormSubmit = (data) => {
    const prePaymentData = { ...data };
    Object.keys(prePaymentData).forEach((key) => {
      if (!prePaymentData[key]) {
        delete prePaymentData[key];
      }
    });
    addPrePayment(prePaymentData);

    onClose();
  };

  return (
    <Modal
      open={open}
      onClose={(event, reason) => {
        if (reason !== 'backdropClick') {
          onClose();
        }
      }}
      className={'backdrop-model'}
    >
      <Fade in={open}>
        <Box sx={style}>
          <Box
            sx={{
              display: 'flex',
              justifyContent: 'space-between',
              alignItems: 'center',
              px: 2,
              py: 2,
              borderBottom: '1px solid #e0e0e0',
            }}
          >
            <Typography variant="h6">Pre Payment</Typography>
            <IconButton onClick={onClose}>
              <CloseIcon />
            </IconButton>
          </Box>
          <Divider />
          <Box sx={{ p: 2 }}>
            <Stack spacing={2}>
              <CustomTextInput
                name="amount"
                label="Prepayment Amount"
                control={popupControl}
                errors={popupErrors}
                readOnly={readOnly}
              />
              <Controller
                name="date"
                control={popupControl}
                render={({ field }) => (
                  <TextField
                    {...field}
                    id="pre-payment-date"
                    fullWidth
                    label="Prepayment Date"
                    type="date"
                    variant="outlined"
                    InputLabelProps={{
                      shrink: true,
                    }}
                  />
                )}
              />
              <Controller
                name="time"
                control={popupControl}
                render={({ field }) => (
                  <TextField
                    {...field}
                    id="pre-payment-time"
                    fullWidth
                    label="Prepayment Time"
                    type="time"
                    variant="outlined"
                    value={field.value ? field.value.slice(0, 5) : ''}
                    onChange={(event) => {
                      const time = event.target.value;
                      const formattedTime = time ? `${time}:00Z` : '';
                      field.onChange(formattedTime);
                    }}
                    InputLabelProps={{
                      shrink: true,
                    }}
                  />
                )}
              />
              <CustomTextInput
                name={'reference_number'}
                label={'Reference Number'}
                control={popupControl}
                errors={popupErrors}
                readOnly={readOnly}
              />
            </Stack>
          </Box>
          <Divider />
          <Box
            sx={{
              px: 2,
              py: 1,
              borderTop: '1px solid #e0e0e0',
              display: 'flex',
              justifyContent: 'flex-end',
              gap: 1,
            }}
          >
            <Button type="button" variant="contained" color="primary" onClick={handlePopupSubmit(handleFormSubmit)}>
              Add Pre Payment
            </Button>
          </Box>
        </Box>
      </Fade>
    </Modal>
  );
}
