import BusinessProfileForm from '../components/BusinessProfileForm';
import {
  cancelBusinessProfileCreate,
  editBusinessProfileRequest,
  fetchBusinessProfileById,
} from '../../../api/businessProfiles/businessProfilesSlice';
import { useNavigate, useParams } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { useEffect } from 'react';
import { Stack } from '@mui/material';

export default function EditBusinessProfilePage() {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const { profile_id } = useParams();
  const { isBusinessProfileEdited, selectedBusinessProfile, error, loading } = useSelector(
    (state) => state.businessProfiles,
  );

  useEffect(() => {
    if (profile_id) {
      dispatch(fetchBusinessProfileById(profile_id));
    }
  }, [dispatch, profile_id]);

  useEffect(() => {
    if (isBusinessProfileEdited) {
      navigate('/business-profiles');
    }
  }, [isBusinessProfileEdited, navigate]);

  const handleSubmit = (data) => {
    const payload = {
      ...data,
      company_name: data.company_name ? data.company_name : data.name,
      country_code: data.country_code.key,
      state: data.state.key,
      registration_type: data.registration_type.key,
      msic_code: data.msic_code.key,
    };

    if (data.participant_id) {
      const [identifier_code, rest] = data.participant_id.split(':');
      const [special_identifier, identifier] = rest.match(/(\d{2})(.*)/).slice(1);
      payload.peppol_participant = {
        identifier,
        special_identifier,
        identifier_code,
      };
    }
    delete payload.participant_id;
    dispatch(editBusinessProfileRequest(payload));
  };

  function handleCancel() {
    dispatch(cancelBusinessProfileCreate());
    return navigate('/business-profiles');
  }

  if (loading || !selectedBusinessProfile) {
    return null;
  }

  return (
    <Stack direction="column" sx={{ mt: '-2rem' }}>
      <BusinessProfileForm
        onSubmit={handleSubmit}
        onCancel={handleCancel}
        initialData={selectedBusinessProfile}
        edit={true}
        apiErrors={error}
      ></BusinessProfileForm>
    </Stack>
  );
}
