import { call, put, takeEvery, takeLatest } from 'redux-saga/effects';
import {
  cancelInvoiceFailure,
  cancelInvoiceRequest,
  cancelInvoiceSuccess,
  invoiceEDocFailure,
  invoiceEDocRequest,
  invoiceEDocSuccess,
  createInvoiceFailure,
  createInvoiceRequest,
  createInvoiceSuccess,
  fetchInvoiceById,
  fetchInvoiceByIdFailure,
  fetchInvoiceByIdSuccess,
  fetchInvoicesFailure,
  fetchInvoicesRequest,
  fetchInvoicesSuccess,
  transmitInvoiceToPeppolFailure,
  transmitInvoiceToPeppolRequest,
  transmitInvoiceToPeppolSuccess,
} from './invoicesSlice';
import { apiClient } from '../../utils/axios';
import { showSnackbar } from '../snackbarSlice';

function* fetchInvoicesSaga(action) {
  try {
    const { query } = action.payload;
    console.time('invoices_call');
    const response = yield call(apiClient.get, '/invoices', { params: query });
    console.timeEnd('invoices_call');
    yield put(fetchInvoicesSuccess(response.data));
  } catch (error) {
    yield put(fetchInvoicesFailure(error.message));
  }
}

function* fetchInvoiceByIdSaga(action) {
  try {
    const response = yield call(apiClient.get, `/invoices/${action.payload}`);
    yield put(fetchInvoiceByIdSuccess({ invoice: response.data }));
  } catch (error) {
    yield put(fetchInvoiceByIdFailure(error.message));
  }
}

function* createInvoiceSaga(action) {
  try {
    const response = yield call(apiClient.post, '/invoices', action.payload);
    yield put(createInvoiceSuccess(response.data));
    yield put(showSnackbar({ message: 'Invoice created successfully!', severity: 'success' }));
  } catch (error) {
    yield put(createInvoiceFailure(JSON.stringify(error.response?.data?.errors)));
  }
}

function* invoiceEDocSaga(action) {
  try {
    const response = yield call(apiClient.put, `/invoices/${action.payload.document_id}`, action.payload);
    yield put(invoiceEDocSuccess(response.data));
    yield put(
      showSnackbar({
        message: 'Conversion request for Staged Invoice to E-Invoice accepted!',
        severity: 'success',
      }),
    );
  } catch (error) {
    yield put(invoiceEDocFailure(JSON.stringify(error.response?.data?.errors)));
  }
}

function* cancelInvoiceSaga(action) {
  try {
    const { url, reason } = action.payload;
    const data = { reason };
    const response = yield call(apiClient.put, url, data);
    yield put(cancelInvoiceSuccess(response.data));
  } catch (error) {
    yield put(cancelInvoiceFailure(JSON.stringify(error.response?.data?.errors)));
  }
}

function* transmitInvoiceToPeppolSaga(action) {
  try {
    const { documentId } = action.payload;
    const response = yield call(apiClient.get, `/invoices/${documentId}/transmit-doc-over-peppol`);
    console.log('response', response);
    yield put(transmitInvoiceToPeppolSuccess(response.data));
    yield put(
      showSnackbar({
        message: response.data.message,
        severity: 'success',
      }),
    );
  } catch (error) {
    console.log('error', error);
    yield put(transmitInvoiceToPeppolFailure(JSON.stringify(error.message)));
    yield put(showSnackbar({ message: error.response?.data?.message, severity: 'error' }));
  }
}

function* invoiceSaga() {
  yield takeEvery(fetchInvoicesRequest.type, fetchInvoicesSaga);
  yield takeEvery(fetchInvoiceById.type, fetchInvoiceByIdSaga);
  yield takeLatest(createInvoiceRequest.type, createInvoiceSaga);
  yield takeLatest(invoiceEDocRequest.type, invoiceEDocSaga);
  yield takeLatest(cancelInvoiceRequest.type, cancelInvoiceSaga);
  yield takeLatest(transmitInvoiceToPeppolRequest.type, transmitInvoiceToPeppolSaga);
}

export default invoiceSaga;
