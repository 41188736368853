import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { fetchInvoicesRequest, markInvoiceCreated } from '../../../api/invoices/invoicesSlice';
import TopBar from '../../../components/TopBar';
import useDocumentTitle from '../../../hooks/useDocumentTitle';
import SBInvoiceFilters from '../components/SBInvoiceFilters';
import SBInvoiceListPageActions from '../components/SBInvoiceListPageActions';
import { Stack } from '@mui/material';
import SBInvoiceTable from '../components/SBInvoiceTable';

const filters = [
  {
    type: 'search',
    options: [
      {
        key: 'document_number',
        placeholder: 'Invoice Number',
      },
    ],
  },
];

let defaultQuery = { document_type: 11, status: ['active', 'obsolete'] };

function SBInvoiceListPage() {
  useDocumentTitle('Invoice List');
  const dispatch = useDispatch();
  const [selectedFilter, setSelectedFilter] = useState(filters.length > 0 ? filters[0].options[0].key : '');
  const [searchTerm, setSearchTerm] = useState('');
  const [currentQuery, setCurrentQuery] = useState({});

  useEffect(() => {
    dispatch(markInvoiceCreated(false));
    dispatch(fetchInvoicesRequest({ query: defaultQuery }));
  }, [dispatch]);

  const handleFilterChange = (event) => {
    setSelectedFilter(event.target.value);
    const searchBy = event.target.value;
    const query = { ...defaultQuery, page: 1, limit: 10 };
    if (searchTerm) {
      query[searchBy] = searchTerm;
    }
    dispatch(
      fetchInvoicesRequest({
        query,
      }),
    );
  };

  const handleSearchChange = (event) => setSearchTerm(event.target.value);

  const handleSearchKeyPress = (event) => {
    const query = { ...defaultQuery, page: 1, limit: 10 };
    if (searchTerm) {
      query[selectedFilter] = searchTerm;
    }
    if (event.key === 'Enter') {
      setCurrentQuery(query);
      dispatch(
        fetchInvoicesRequest({
          query,
        }),
      );
    }
  };

  return (
    <Stack direction="column" sx={{ mt: '-1rem' }}>
      <TopBar title="Self Billed Invoices">
        <SBInvoiceListPageActions />
      </TopBar>
      <SBInvoiceFilters
        filters={filters}
        selectedFilter={selectedFilter}
        onFilterChange={handleFilterChange}
        searchTerm={searchTerm}
        onSearchChange={handleSearchChange}
        onSearchKeyPress={handleSearchKeyPress}
      />
      <SBInvoiceTable searchQuery={currentQuery} />
    </Stack>
  );
}

export default SBInvoiceListPage;
