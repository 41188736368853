import { call, put, takeLatest } from 'redux-saga/effects';
import {
  cancelCreditNoteFailure,
  cancelCreditNoteRequest,
  cancelCreditNoteSuccess,
  creditNoteEDocFailure,
  creditNoteEDocRequest,
  creditNoteEDocSuccess,
  createCreditNoteFailure,
  createCreditNoteRequest,
  createCreditNoteSuccess,
  fetchCreditNoteById,
  fetchCreditNoteByIdFailure,
  fetchCreditNoteByIdSuccess,
  fetchCreditNotesFailure,
  fetchCreditNotesRequest,
  fetchCreditNotesSuccess,
  transmitCNToPeppolRequest,
  transmitCNToPeppolSuccess,
  transmitCNToPeppolFailure,
} from './creditNotesSlice';
import { apiClient } from '../../utils/axios';
import { showSnackbar } from '../snackbarSlice';
import {
  transmitInvoiceToPeppolFailure,
  transmitInvoiceToPeppolRequest,
  transmitInvoiceToPeppolSuccess,
} from '../invoices/invoicesSlice';

function* fetchCreditNotesSaga(action) {
  try {
    const { query } = action.payload;
    const response = yield call(apiClient.get, '/credit-notes', { params: query });
    yield put(fetchCreditNotesSuccess(response.data));
  } catch (error) {
    yield put(fetchCreditNotesFailure(error.message));
  }
}

function* fetchCreditNoteByIdSaga(action) {
  try {
    const response = yield call(apiClient.get, `/credit-notes/${action.payload}`);
    yield put(fetchCreditNoteByIdSuccess(response.data));
  } catch (error) {
    yield put(fetchCreditNoteByIdFailure(error.message));
  }
}

function* createCreditNoteSaga(action) {
  try {
    const response = yield call(apiClient.post, '/credit-notes', action.payload);
    yield put(createCreditNoteSuccess(response.data));
    yield put(showSnackbar({ message: 'Credit Note created successfully!', severity: 'success' }));
  } catch (error) {
    yield put(createCreditNoteFailure(error.response?.data?.errors));
  }
}

function* creditNoteEDocSaga(action) {
  try {
    const response = yield call(apiClient.put, `/credit-notes/${action.payload.document_id}`, action.payload);
    yield put(creditNoteEDocSuccess(response.data));
    yield put(
      showSnackbar({
        message: 'Conversion request for Staged Credit Note to E-Credit Note accepted!',
        severity: 'success',
      }),
    );
  } catch (error) {
    yield put(creditNoteEDocFailure(JSON.stringify(error.response?.data?.errors)));
  }
}

function* cancelCreditNoteSaga(action) {
  try {
    const { url, reason } = action.payload;
    const data = { reason };
    const response = yield call(apiClient.put, url, data);
    yield put(cancelCreditNoteSuccess(response.data));
  } catch (error) {
    yield put(cancelCreditNoteFailure(error.response?.data?.errors));
  }
}

function* transmitCNToPeppolSaga(action) {
  try {
    const { documentId } = action.payload;
    const response = yield call(apiClient.get, `/credit-notes/${documentId}/transmit-doc-over-peppol`);

    yield put(transmitCNToPeppolSuccess(response.data));
    yield put(
      showSnackbar({
        message: response.data.message,
        severity: 'success',
      }),
    );
  } catch (error) {
    yield put(transmitCNToPeppolFailure(JSON.stringify(error.message)));
    yield put(showSnackbar({ message: error.response?.data?.message, severity: 'error' }));
  }
}

function* creditNotesSaga() {
  yield takeLatest(fetchCreditNotesRequest.type, fetchCreditNotesSaga);
  yield takeLatest(fetchCreditNoteById.type, fetchCreditNoteByIdSaga);
  yield takeLatest(createCreditNoteRequest.type, createCreditNoteSaga);
  yield takeLatest(creditNoteEDocRequest.type, creditNoteEDocSaga);
  yield takeLatest(cancelCreditNoteRequest.type, cancelCreditNoteSaga);
  yield takeLatest(transmitCNToPeppolRequest.type, transmitCNToPeppolSaga);
}

export default creditNotesSaga;
