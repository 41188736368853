import React from 'react';
import { IconButton, Typography } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';

const Limit95Alert = ({ onClose }) => (
  <div style={{ textAlign: 'left', flex: 1 }}>
    <Typography variant="body1" sx={{ fontSize: '1.2rem', fontWeight: 'bold', textAlign: 'center', mr: '5rem' }}>
      You have reached 90% of your invoice usage limit for the current subscription period.
    </Typography>
    <IconButton
      aria-label="close"
      color="inherit"
      size="small"
      onClick={onClose}
      sx={{
        position: 'absolute',
        top: '0.7rem',
        right: '5rem',
      }}
    >
      <CloseIcon />
    </IconButton>
  </div>
);

export default Limit95Alert;
