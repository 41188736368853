import { createTheme } from '@mui/material/styles';

const lightTheme = createTheme({
  colorSchemes: { light: true, dark: false },
  palette: {
    primary: {
      dark: '#3741af',
      main: '#4f5efa',
      light: '#727efb',
    },
    secondary: {
      dark: '#b29420',
      main: '#ffd42f',
      light: '#ffdc58',
    },
  },
  components: {
    MuiTextField: {
      defaultProps: {
        size: 'small',
      },
    },
    MuiDrawer: {
      styleOverrides: {
        root: {
          boxShadow: '0px 0px 30px 0px rgba(0,0,0,0.1)',
        },
      },
    },
  },
  shape: {
    borderRadius: 6, // Set global border radius value
  },
});

export default lightTheme;
