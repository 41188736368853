import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { fetchCreditNotesRequest, markCreditNoteCreated } from '../../../api/creditNotes/creditNotesSlice';
import SBCreditNoteFilters from '../components/SBCreditNoteFilters';
import TopBar from '../../../components/TopBar';
import useDocumentTitle from '../../../hooks/useDocumentTitle';
import { Stack } from '@mui/material';
import SBCreditNoteTable from '../components/SBCreditNoteTable';
import SBCreditNoteListPageActions from '../components/SBCreditNoteListPageActions';

const filters = [
  {
    type: 'search',
    options: [
      {
        key: 'document_number',
        placeholder: 'Credit Note Number',
      },
      {
        key: 'invoice_number',
        placeholder: 'Invoice Number',
      },
    ],
  },
];

const leadingActions = [
  {
    label: '+ Raise Self Billed Credit Note',
    action: 'create',
    target: '/self-billed-documents/credit-notes/create',
  },
];
let defaultQuery = { document_type: 12, status: ['active', 'obsolete'] };

function SBCreditNoteListPage({ config }) {
  useDocumentTitle('Self Billed Credit Note List');
  const dispatch = useDispatch();
  const [selectedFilter, setSelectedFilter] = useState(filters.length > 0 ? filters[0].options[0].key : '');
  const [searchTerm, setSearchTerm] = useState('');
  const [currentQuery, setCurrentQuery] = useState({});

  useEffect(() => {
    dispatch(markCreditNoteCreated(false));
    dispatch(fetchCreditNotesRequest({ query: defaultQuery }));
  }, [dispatch]);

  const handleFilterChange = (event) => {
    setSelectedFilter(event.target.value);
    const searchBy = event.target.value;
    const query = { ...defaultQuery, page: 1, limit: 10 };
    if (searchTerm) {
      query[searchBy] = searchTerm;
    }
    dispatch(
      fetchCreditNotesRequest({
        query,
      }),
    );
  };

  const handleSearchChange = (event) => setSearchTerm(event.target.value);

  const handleSearchKeyPress = (event) => {
    const query = { ...defaultQuery, page: 1, limit: 10 };
    if (searchTerm) {
      query[selectedFilter] = searchTerm;
    }
    if (event.key === 'Enter') {
      setCurrentQuery(query);
      dispatch(
        fetchCreditNotesRequest({
          query,
        }),
      );
    }
  };

  return (
    <Stack direction="column" sx={{ mt: '-1rem' }}>
      <TopBar title="Self Billed Credit Notes List">
        <SBCreditNoteListPageActions actions={leadingActions} />
      </TopBar>
      <SBCreditNoteFilters
        filters={filters}
        selectedFilter={selectedFilter}
        onFilterChange={handleFilterChange}
        searchTerm={searchTerm}
        onSearchChange={handleSearchChange}
        onSearchKeyPress={handleSearchKeyPress}
      />
      <SBCreditNoteTable searchQuery={currentQuery} />
    </Stack>
  );
}

export default SBCreditNoteListPage;
