import CustomerForm from '../components/CustomerForm';
import { cancelCustomerCreate, createCustomerRequest } from '../../../api/customers/customersSlice';
import { useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { useEffect } from 'react';
import useDocumentTitle from '../../../hooks/useDocumentTitle';
import { Stack } from '@mui/material';

const initialData = {
  name: '',
  company_name: '',
  company_name_in_local: '',
  email: '',
  address1: '',
  address2: '',
  address3: '',
  city: '',
  country_code: '',
  state: '',
  postal_code: '',
  phone: '',
  registration_type: '',
  registration_number: '',
  tin: '',
  sst_registration_number: '-',
};

export default function CreateCustomersPage() {
  useDocumentTitle('New Customer Form');
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const { isCustomerCreated, error } = useSelector((state) => state.customers);

  useEffect(() => {
    if (isCustomerCreated) {
      navigate('/customers');
    }
  }, [isCustomerCreated, navigate]);

  const handleSubmit = (data) => {
    const payload = {
      ...data,
      company_name: data.company_name ? data.company_name : data.name,
      country_code: data.country_code.key,
      state: data.state.key,
      registration_type: data.registration_type.key,
      msic_code: data.msic_code.key,
    };

    delete payload.participant_id;
    dispatch(createCustomerRequest(payload));
  };

  function handleCancel() {
    dispatch(cancelCustomerCreate());
    return navigate('/customers');
  }

  return (
    <Stack direction="column" sx={{ mt: '-2rem' }}>
      <CustomerForm
        onSubmit={handleSubmit}
        onCancel={handleCancel}
        initialData={initialData}
        apiErrors={error}
      ></CustomerForm>
    </Stack>
  );
}
