import React from 'react';
import { Box, Grid2 as Grid, Stack, Typography, useTheme } from '@mui/material';

const ShippingDetailsCard = ({ document }) => {
  const theme = useTheme();

  // Construct the address statically
  const address =
    `${document.address1 || ''} ${document.address2 || ''} ${document.city || ''} ${document.state || ''} ${document.postal_code || ''}`.trim();

  return (
    <Box
      sx={{
        padding: theme.spacing(2),
        border: `1px solid ${theme.palette.divider}`,
        borderRadius: '4px',
        marginBottom: theme.spacing(2),
        backgroundColor: theme.palette.background.paper,
        display: 'flex',
        height: '100%',
        flexDirection: 'column',
        justifyContent: 'space-between',
      }}
    >
      <Typography
        variant="h6"
        sx={{
          fontWeight: theme.typography.fontWeightBold,
        }}
      >
        Shipping Details
      </Typography>

      <Typography
        variant="subtitle1"
        sx={{
          color: theme.palette.text.disabled,
          marginBottom: theme.spacing(1.5),
        }}
      >
        Recipients Info
      </Typography>

      <Stack spacing={1.25}>
        {/* Name Field */}
        {document.name && (
          <Grid
            container
            sx={{
              backgroundColor: theme.palette.action.hover,
            }}
          >
            <Grid size={6}>Name:</Grid>
            <Grid size={6}>{document.name}</Grid>
          </Grid>
        )}

        {/* Address Field */}
        {address && (
          <Grid
            container
            sx={{
              backgroundColor: 'inherit',
            }}
          >
            <Grid size={6}>Address:</Grid>
            <Grid size={6}>{address}</Grid>
          </Grid>
        )}

        {/* Country Field */}
        {document.country_code && (
          <Grid
            container
            sx={{
              backgroundColor: theme.palette.action.hover,
            }}
          >
            <Grid size={6}>Country:</Grid>
            <Grid size={6}>{document.country_code}</Grid>
          </Grid>
        )}

        {/* Registration Number Field */}
        {document.registration_number && (
          <Grid
            container
            sx={{
              backgroundColor: 'inherit',
            }}
          >
            <Grid size={6}>Registration Number:</Grid>
            <Grid size={6}>{document.registration_number}</Grid>
          </Grid>
        )}

        {/* TIN Field */}
        {document.tin && (
          <Grid
            container
            sx={{
              backgroundColor: theme.palette.action.hover,
            }}
          >
            <Grid size={6}>TIN:</Grid>
            <Grid size={6}>{document.tin}</Grid>
          </Grid>
        )}
      </Stack>
    </Box>
  );
};

export default ShippingDetailsCard;
