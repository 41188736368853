import { createSelector } from '@reduxjs/toolkit';

const countryCodesState = (state) => state.countryCodes;
const currencyState = (state) => state.currencies;
const itemCodeState = (state) => state.itemCodes;
const msicCodesState = (state) => state.msicCodes;
const paymentMeansState = (state) => state.paymentMeans;
const statesState = (state) => state.states;
const taxTypeState = (state) => state.taxTypes;
const uomCodesState = (state) => state.uomCodes;
const businessProfileState = (state) => state.businessProfiles;
const customersState = (state) => state.customers;
const suppliersState = (state) => state.suppliers;
const invoicesState = (state) => state.invoices;
const creditNotesState = (state) => state.creditNotes;
const debitNotesState = (state) => state.debitNotes;
const refundsState = (state) => state.refunds;
const bulkUploadState = (state) => state.bulkUploads;
const orgState = (state) => state.org;
const participantsState = (state) => state.participants;

export const appLoadingState = createSelector(
  [
    countryCodesState,
    currencyState,
    itemCodeState,
    msicCodesState,
    paymentMeansState,
    statesState,
    taxTypeState,
    uomCodesState,
    businessProfileState,
    customersState,
    suppliersState,
    invoicesState,
    creditNotesState,
    debitNotesState,
    refundsState,
    bulkUploadState,
    participantsState,
  ],
  (
    countryCodesState,
    currencyState,
    itemCodeState,
    msicCodesState,
    paymentMeansState,
    statesState,
    taxTypeState,
    uomCodesState,
    businessProfileState,
    customersState,
    suppliersState,
    invoicesState,
    creditNotesState,
    debitNotesState,
    refundsState,
    bulkUploadState,
    participantsState,
  ) =>
    countryCodesState.loading ||
    uomCodesState.loading ||
    currencyState.loading ||
    itemCodeState.loading ||
    taxTypeState.loading ||
    currencyState.loading ||
    bulkUploadState.loading ||
    businessProfileState.loading ||
    customersState.loading ||
    suppliersState.loading ||
    invoicesState.loading ||
    creditNotesState.loading ||
    debitNotesState.loading ||
    refundsState.loading ||
    participantsState.loading,
);

export const loadingPrerequisites = createSelector(
  [
    countryCodesState,
    currencyState,
    itemCodeState,
    msicCodesState,
    paymentMeansState,
    statesState,
    taxTypeState,
    uomCodesState,
    orgState,
  ],
  (
    countryCodesState,
    currencyState,
    itemCodeState,
    msicCodesState,
    paymentMeansState,
    statesState,
    taxTypeState,
    uomCodesState,
    orgState,
  ) =>
    countryCodesState.loading ||
    uomCodesState.loading ||
    currencyState.loading ||
    itemCodeState.loading ||
    taxTypeState.loading ||
    currencyState.loading ||
    refundsState.loading ||
    paymentMeansState.loading ||
    orgState.loading,
);
