import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  Alert,
  Box,
  Divider,
  FormControlLabel,
  FormGroup,
  Grid2 as Grid,
  Paper,
  Snackbar,
  Switch,
  Typography,
} from '@mui/material';
import { fetchOrgSettingsRequest, updateOrgSettingsRequest } from '../../../api/organizations/orgSlice';

const SftpSection = () => {
  const dispatch = useDispatch();
  const { settings } = useSelector((state) => state.org);

  const [sendToSftp, setSendToSftp] = useState(settings?.sftp?.send_pdf || false);
  const [snackbarOpen, setSnackbarOpen] = useState(false);

  useEffect(() => {
    dispatch(fetchOrgSettingsRequest());
  }, [dispatch]);

  useEffect(() => {
    if (settings?.sftp_config) {
      setSendToSftp(settings.sftp_config.send_pdf_to_sftp || false);
    }
  }, [settings]);

  const handleSubmit = (updatedSetting) => {
    const updatedSftpSettings = {
      send_pdf_to_sftp: sendToSftp,
      ...updatedSetting,
    };
    dispatch(
      updateOrgSettingsRequest({
        updates: { sftp_config: updatedSftpSettings },
      }),
    );
    setSnackbarOpen(true);
  };

  return (
    <Paper elevation={0} sx={{ p: '1rem' }}>
      <Box sx={{ mb: '1.5rem' }}>
        <Typography variant="h6">Auto PDF Generation</Typography>
        <Divider></Divider>
      </Box>
      <Grid container>
        <Grid size={12}>
          <FormGroup row>
            <FormControlLabel
              control={
                <Switch
                  checked={sendToSftp}
                  onChange={() => {
                    setSendToSftp(!sendToSftp);
                    handleSubmit({ send_pdf_to_sftp: !sendToSftp });
                  }}
                />
              }
              label="Send PDF to SFTP"
              labelPlacement="start"
              sx={{ pl: 0, ml: 0 }}
            />
          </FormGroup>
        </Grid>
      </Grid>
      <Snackbar
        open={snackbarOpen}
        autoHideDuration={1000}
        onClose={() => setSnackbarOpen(false)}
        anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
      >
        <Alert onClose={() => setSnackbarOpen(false)} severity="success" sx={{ width: '100%' }}>
          SFTP settings updated successfully!
        </Alert>
      </Snackbar>
    </Paper>
  );
};

export default SftpSection;
