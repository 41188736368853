import React from 'react';
import { Button } from '@mui/material';
import { useTheme } from '@mui/material/styles';

const DetailsPageActionButtons = ({ onBack }) => {
  const theme = useTheme();

  return (
    <>
      <Button
        key="back"
        variant="contained"
        color="primary"
        onClick={onBack}
        className="action-button"
        sx={{ marginLeft: theme.spacing(1) }}
      >
        Back
      </Button>
    </>
  );
};

export default DetailsPageActionButtons;
