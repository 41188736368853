import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { DatePicker, LocalizationProvider } from '@mui/x-date-pickers';
import React from 'react';
import { Controller } from 'react-hook-form';
import { TextField } from '@mui/material';

export default function SingleDatePicker({ control, name, label, errors, readOnly = false }) {
  return (
    <LocalizationProvider dateAdapter={AdapterDayjs}>
      <Controller
        name={name}
        control={control}
        render={({ field }) => (
          <DatePicker
            id={name}
            label={label}
            renderInput={(params) => <TextField {...field} fullWidth />}
            onChange={(date) => field.onChange(date)}
            value={field.value}
            readOnly={readOnly}
            slotProps={{
              textField: {
                fullWidth: true,
                error: !!errors.document_date,
                helperText: errors.document_date?.message,
              },
            }}
          />
        )}
      />
    </LocalizationProvider>
  );
}
