import React, { useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { clearSelectedInvoice, fetchInvoiceById } from '../../../api/invoices/invoicesSlice';
import { Stack } from '@mui/material';
import '../../../styles/DocumentDetails.css';
import TopBar from '../../../components/TopBar';
import InvoiceDetailsPageActionButtons from '../components/InvoiceDetailsPageActionButtons';
import useDocumentTitle from '../../../hooks/useDocumentTitle';
import InvoiceForm from '../components/InvoiceForm';
import IrbmStatusCard from '../../common/IrbmStatusCard';
import PeppolResponseStatusCard from '../../common/PeppolResponseStatusCard';

export default function InvoiceDetailsPage() {
  useDocumentTitle('Invoice Details');
  const { document_id } = useParams();
  const dispatch = useDispatch();
  const invoice = useSelector((state) => state.invoices.selectedInvoice);

  useEffect(() => {
    if (document_id) {
      dispatch(fetchInvoiceById(document_id));
    }
    //The function which is returned by useEffect will act as cleanup of what useEffect does
    return () => {
      dispatch(clearSelectedInvoice());
    };
  }, [dispatch, document_id]);
  if (!invoice) return null;

  return (
    <Stack direction="column" sx={{ mt: '-1rem' }}>
      {invoice && (
        <TopBar title="Invoice Details" subTitle={invoice?.document_id}>
          <InvoiceDetailsPageActionButtons />
        </TopBar>
      )}
      <IrbmStatusCard document={invoice} />
      <PeppolResponseStatusCard document={invoice} />

      <InvoiceForm onSubmit={() => {}} onCancel={() => {}} initialData={invoice} readOnly={true} />
    </Stack>
  );
}
