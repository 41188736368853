import React from 'react';
import { Typography } from '@mui/material';

const Limit100Alert = () => (
  <div style={{ textAlign: 'left', flex: 1 }}>
    <Typography variant="body1" sx={{ fontSize: '1.2rem', fontWeight: 'bold', textAlign: 'center', mr: '5rem' }}>
      You have reached 100% of your invoice usage limit for the current subscription period. Upgrade or renew to
      continue.
    </Typography>
  </div>
);

export default Limit100Alert;
