import { call, put, takeLatest } from 'redux-saga/effects';
import { apiClient } from '../../utils/axios';
import {
  downloadPeppolXmlFailure,
  downloadPeppolXmlRequest,
  downloadPeppolXmlSuccess,
  fetchPeppolMessagesFailure,
  fetchPeppolMessagesRequest,
  fetchPeppolMessagesSuccess,
} from './peppolMessagesSlice';
import { showSnackbar } from '../snackbarSlice';

function* fetchPeppolMessagesSaga(action) {
  try {
    const { query } = action.payload;
    const response = yield call(apiClient.get, '/peppol/incoming/message', { params: query });
    yield put(fetchPeppolMessagesSuccess(response.data));
  } catch (error) {
    yield put(fetchPeppolMessagesFailure(error.message));
  }
}

function* downloadPeppolXmlSaga(action) {
  try {
    const { message_id } = action.payload;
    const response = yield call(apiClient.get, `/peppol/incoming/message/${message_id}/xml`, {
      responseType: 'blob',
    });

    const url = window.URL.createObjectURL(new Blob([response.data]));
    const link = document.createElement('a');
    link.href = url;
    link.setAttribute('download', `${message_id}.xml`);
    document.body.appendChild(link);
    link.click();
    link.parentNode.removeChild(link);

    yield put(downloadPeppolXmlSuccess(response.data));
    yield put(showSnackbar({ message: 'XML downloaded successfully!', severity: 'success' }));
  } catch (error) {
    yield put(downloadPeppolXmlFailure(error.message));
    yield put(showSnackbar({ message: 'Failed to download XML', severity: 'error' }));
  }
}

function* peppolMessagesSaga() {
  yield takeLatest(fetchPeppolMessagesRequest.type, fetchPeppolMessagesSaga);
  yield takeLatest(downloadPeppolXmlRequest.type, downloadPeppolXmlSaga);
}

export default peppolMessagesSaga;
