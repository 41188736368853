import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  Box,
  Button,
  Divider,
  FormControlLabel,
  FormGroup,
  Grid2 as Grid,
  Paper,
  Switch,
  TextField,
  Typography,
} from '@mui/material';
import { fetchOrgSettingsRequest, updateOrgSettingsRequest } from '../../../api/organizations/orgSlice';
import DeleteIcon from '@mui/icons-material/Delete';
import IconButton from '@mui/material/IconButton';
import EditIcon from '@mui/icons-material/Edit';
import { showSnackbar } from '../../../api/snackbarSlice';
import { Controller, useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from 'yup';

const emailSchema = yup.object({
  email: yup.string().email('Invalid email format').required('Email is required'),
});

const EmailSection = () => {
  const dispatch = useDispatch();
  const { settings } = useSelector((state) => state.org);

  const [notificationSettings, setNotificationSettings] = useState({
    send_email_to_seller: false,
    send_email_to_buyer: false,
    additional_seller_recipients: [],
  });

  const [editableIndex, setEditableIndex] = useState(null);
  const {
    control,
    handleSubmit: validateEmail,
    formState: { errors, isValid }, // Add `isValid` to track form validity
    reset,
    setValue,
  } = useForm({
    resolver: yupResolver(emailSchema),
    mode: 'onChange', // Validate on change
    defaultValues: {
      email: '',
    },
  });

  useEffect(() => {
    dispatch(fetchOrgSettingsRequest());
  }, [dispatch]);

  useEffect(() => {
    if (settings?.email_notifications) {
      setNotificationSettings((prev) => ({
        ...prev,
        ...settings.email_notifications,
        additional_seller_recipients: settings.email_notifications.additional_seller_recipients || [],
      }));
    }
  }, [settings]);

  useEffect(() => {
    if (editableIndex !== null && notificationSettings.additional_seller_recipients[editableIndex] !== undefined) {
      setValue('email', notificationSettings.additional_seller_recipients[editableIndex]);
    }
  }, [editableIndex, notificationSettings.additional_seller_recipients, setValue]);

  const addContactEmail = async () => {
    if (editableIndex !== null) {
      const isValid = await validateEmail(onSubmitEmail)();
      if (!isValid) {
        return;
      }
    }
    reset({ email: '' });

    setNotificationSettings((prev) => ({
      ...prev,
      additional_seller_recipients: [...prev.additional_seller_recipients, ''],
    }));
    setEditableIndex(notificationSettings.additional_seller_recipients.length);
  };

  const handleEmailChange = (index, value) => {
    const newEmails = [...notificationSettings.additional_seller_recipients];
    newEmails[index] = value;
    setNotificationSettings((prev) => ({
      ...prev,
      additional_seller_recipients: newEmails,
    }));
  };

  const handleToggleChange = (field) => {
    const updatedSettings = {
      ...notificationSettings,
      [field]: !notificationSettings[field],
    };

    setNotificationSettings(updatedSettings);
    handleSubmit({ [field]: updatedSettings[field] });
  };

  const handleSubmit = (updatedSetting = {}) => {
    const updatedNotifications = {
      ...notificationSettings,
      additional_seller_recipients: notificationSettings.additional_seller_recipients.filter(
        (email) => email.trim() !== '',
      ),
      ...updatedSetting,
    };

    const invalidEmails = updatedNotifications.additional_seller_recipients.filter(
      (email) => email.trim() !== '' && !/^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(email),
    );

    if (invalidEmails.length > 0) {
      dispatch(
        showSnackbar({
          open: true,
          message: `Invalid email format: ${invalidEmails.join(', ')}`,
          severity: 'error',
        }),
      );
      return;
    }

    dispatch(
      updateOrgSettingsRequest({
        updates: { email_notifications: updatedNotifications },
      }),
    );

    dispatch(
      showSnackbar({
        open: true,
        message: 'Email settings updated successfully',
        severity: 'success',
      }),
    );

    setEditableIndex(null);
  };

  const onSubmitEmail = (data) => {
    const newEmails = [...notificationSettings.additional_seller_recipients];
    newEmails[editableIndex] = data.email;

    setNotificationSettings((prev) => ({
      ...prev,
      additional_seller_recipients: newEmails,
    }));

    handleSubmit({ additional_seller_recipients: newEmails });
  };

  const handleDeleteEmail = (index) => {
    const updatedEmails = notificationSettings.additional_seller_recipients.filter((_, i) => i !== index);

    setNotificationSettings((prev) => ({
      ...prev,
      additional_seller_recipients: updatedEmails,
    }));
    if (editableIndex === index) {
      setEditableIndex(null);
      reset({ email: '' });
    } else if (editableIndex !== null && editableIndex > index) {
      setEditableIndex(editableIndex - 1);
    }

    handleSubmit({ additional_seller_recipients: updatedEmails });
  };

  return (
    <Paper elevation={0} sx={{ p: '1rem' }}>
      <Typography variant="h6" sx={{ mb: '1.5rem' }}>
        Email Notification Preferences
        <Divider></Divider>
      </Typography>
      <Box>
        <Grid container>
          <Grid size={2.2}>
            <FormGroup>
              <FormControlLabel
                control={
                  <Switch
                    checked={notificationSettings.send_email_to_seller}
                    onChange={() => handleToggleChange('send_email_to_seller')}
                  />
                }
                label="Email Document to Sender"
                labelPlacement="start"
                sx={{ ml: 0, justifyContent: 'space-between', width: '100%' }}
              />
              <FormControlLabel
                control={
                  <Switch
                    checked={notificationSettings.send_email_to_buyer}
                    onChange={() => handleToggleChange('send_email_to_buyer')}
                  />
                }
                label="Email Document to Receiver"
                labelPlacement="start"
                sx={{ ml: 0, justifyContent: 'space-between', width: '100%' }}
              />
            </FormGroup>
          </Grid>
        </Grid>
      </Box>

      <Typography variant="h6" sx={{ mt: '1.5rem', mb: '1.5rem' }}>
        Additional Email Recipients
        <Divider></Divider>
      </Typography>

      <Grid container spacing={2}>
        {notificationSettings.additional_seller_recipients.map((email, index) => (
          <Grid container spacing={1} size={8} key={index}>
            <Grid size={6}>
              {editableIndex === index ? (
                <form onSubmit={validateEmail(onSubmitEmail)}>
                  <Controller
                    name="email"
                    control={control}
                    render={({ field }) => (
                      <TextField
                        {...field}
                        fullWidth
                        placeholder="Enter Contact Email"
                        label={`Email ${index + 1}`}
                        error={!!errors.email}
                        helperText={errors.email?.message}
                        sx={{
                          '& .MuiInputBase-root': {
                            backgroundColor: '#f5f5f5',
                          },
                          '& .MuiInputBase-input': {
                            color: 'black',
                          },
                          '& .MuiInputLabel-root': {
                            color: 'black',
                          },
                        }}
                      />
                    )}
                  />
                </form>
              ) : (
                <TextField
                  fullWidth
                  placeholder="Enter Contact Email"
                  label={`Email ${index + 1}`}
                  value={email}
                  onChange={(e) => handleEmailChange(index, e.target.value)}
                  disabled={true}
                  sx={{
                    '& .MuiInputBase-root.Mui-disabled': {
                      backgroundColor: '#f5f5f5',
                    },
                    '& .MuiInputBase-input.Mui-disabled': {
                      color: 'black',
                      WebkitTextFillColor: 'black',
                    },
                    '& .MuiInputLabel-root.Mui-disabled': {
                      color: 'black',
                    },
                  }}
                />
              )}
            </Grid>
            <Grid item>
              {editableIndex === index ? (
                <>
                  <Button
                    onClick={validateEmail(onSubmitEmail)}
                    variant="contained"
                    color="primary"
                    size="small"
                    sx={{ mr: 1 }}
                    disabled={!isValid} // Disable the button if the form is not valid
                  >
                    Save
                  </Button>
                </>
              ) : (
                <IconButton color="primary" onClick={() => setEditableIndex(index)}>
                  <EditIcon />
                </IconButton>
              )}
              <IconButton color="error" onClick={() => handleDeleteEmail(index)}>
                <DeleteIcon />
              </IconButton>
            </Grid>
          </Grid>
        ))}
      </Grid>

      <Box sx={{ mt: 2, display: 'flex' }}>
        <Button variant="outlined" color="primary" onClick={addContactEmail}>
          Add Another Email
        </Button>
      </Box>
    </Paper>
  );
};
export default EmailSection;
