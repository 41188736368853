export const profileIdMap = {
  288: 'MY PINT Self-Billing CreditNote v1.0 (busdox)',
  287: 'MY PINT Self-Billing Invoice v1.0 (busdox)',
  286: 'MY PINT CreditNote v1.0 (busdox)',
  285: 'MY PINT Invoice v1.0 (busdox)',
  275: 'MY PINT Self-Billing CreditNote v1.0* (wildcard)',
  274: 'MY PINT Self-Billing Invoice v1.0* (wildcard)',
  273: 'MY PINT CreditNote v1.0* (wildcard)',
  272: 'MY PINT Invoice v1.0* (wildcard)',
  261: 'Peppol PINT CreditNote v1.0* (wildcard)',
  257: 'Peppol PINT Invoice v1.0* (wildcard)',
};
