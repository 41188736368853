import React, { useState } from 'react';
import { DataGrid } from '@mui/x-data-grid';
import { useDispatch, useSelector } from 'react-redux';
import { fetchRefundsRequest } from '../../../api/refunds/refundsSlice';
import { Box, Typography, useTheme } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import { formatCurrency } from '../../../utils/formatDecimal';
import { currencyFormatter } from '../../../utils/currencyFormat';
import DocumentActions from '../../common/DocumentActions';

const getStatusClassName = (status) => {
  switch (status) {
    case 'Submitted':
      return 'document-status-submitted';
    case 'Valid':
      return 'document-status-valid';
    case 'Invalid':
      return 'document-status-invalid';
    case 'Cancelled':
      return 'document-status-cancelled';
    default:
      return 'transparent';
  }
};

const SBRefundTable = ({ searchQuery = {} }) => {
  const theme = useTheme();
  const { refunds, totalDocuments } = useSelector((state) => state.refunds);
  const dispatch = useDispatch();
  const [page] = useState(0);
  const [limit] = useState(10);
  const navigate = useNavigate();

  const columns = [
    {
      field: 'document_number',
      headerName: 'Self Billed Refund No',
      cellClassName: ({ row }) => getStatusClassName(row.irbm_response.status),
      flex: 1,
      renderCell: ({ row }) => {
        return (
          <Typography
            variant="body1"
            sx={{
              textDecoration: 'underline',
              height: '100%',
              display: 'flex',
              alignItems: 'center',
              pl: '0.7rem',
            }}
            onClick={() => navigate(`/self-billed-documents/refunds/${row.document_id}`)}
          >
            {row.document_number}
          </Typography>
        );
      },
    },
    {
      field: 'invoice_number',
      headerName: 'Invoice Number',
      flex: 1,
    },
    {
      field: 'billed_to',
      headerName: 'Customer Info',
      flex: 1,
      valueGetter: (params) => {
        return params.name;
      },
    },
    {
      field: 'document_date',
      headerName: 'Refund Date',
      flex: 1,
    },
    {
      field: 'grand_total',
      headerName: 'Amount',
      flex: 1,
      renderCell: ({ row }) => currencyFormatter(row.document_currency_code).format(formatCurrency(row.grand_total)),
    },
    {
      field: 'row',
      headerName: 'Actions',
      flex: 0.3,
      sortable: false,
      renderCell: ({ row }) => (
        <DocumentActions row={row} documentStatus={{ key: 'irbm_response.status' }} documentType={row.document_type} />
      ),
    },
  ];

  return (
    <Box>
      <DataGrid
        rows={refunds}
        columns={columns}
        getRowId={(row) => row._id}
        paginationMode="server"
        rowCount={totalDocuments}
        initialState={{
          pagination: {
            paginationModel: {
              pageSize: limit,
            },
          },
        }}
        pageSizeOptions={[10, 25]}
        disableRowSelectionOnClick
        onPaginationModelChange={(model) => {
          dispatch(
            fetchRefundsRequest({
              query: {
                ...searchQuery,
                document_type: 14,
                page: model.page + 1,
                limit: model.pageSize,
                status: ['active', 'obsolete'],
              },
            }),
          );
        }}
        sx={{
          '& .document-status-submitted': {
            paddingLeft: 0,
            borderLeft: `4px solid ${theme.palette.info.main}`,
          },
          '& .document-status-valid': { paddingLeft: 0, borderLeft: `4px solid ${theme.palette.success.main}` },
          '& .document-status-invalid': { paddingLeft: 0, borderLeft: `4px solid ${theme.palette.error.main}` },
          '& .document-status-cancelled': {
            paddingLeft: 0,
            borderLeft: `4px solid ${theme.palette.grey[500]}`,
          },
        }}
      />
    </Box>
  );
};

export default SBRefundTable;
