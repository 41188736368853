import React from 'react';
import { Paper, Typography, Box, useTheme } from '@mui/material';
import { get } from 'lodash';

const getPeppolTransmissionStatusBackgroundColor = (status) => {
  return status === 'SUCCESS' ? '#E6F4EA' : '#FEECEC';
};

const PeppolResponseStatusCard = ({ document }) => {
  const theme = useTheme();
  const transmissionResponse = get(document, 'peppol_response');
  const status = get(transmissionResponse, 'status');
  const messageId = get(transmissionResponse, 'message_id');

  if (!transmissionResponse || !status || !messageId) {
    return null;
  }

  return (
    <Paper
      sx={{
        margin: theme.spacing(0, 0.5, 3.5, 0.5),
        gap: theme.spacing(2),
        padding: theme.spacing(2),
        border: `1px solid ${theme.palette.divider}`,
        backgroundColor: getPeppolTransmissionStatusBackgroundColor(status),
        borderRadius: '4px',
      }}
    >
      <Typography>
        <strong>Peppol Transmission Status:</strong> {status}
      </Typography>
      <Typography>
        <strong>Message ID: </strong>
        {messageId}
      </Typography>
    </Paper>
  );
};

export default PeppolResponseStatusCard;
