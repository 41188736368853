import React, { useState } from 'react';
import { Box, Button, Typography } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import Modal from '@mui/material/Modal';
import { useSelector } from 'react-redux';

const useSubscriptionCheck = () => {
  const navigate = useNavigate();
  const subscription = useSelector((state) => state.org.org.subscription);
  const [openModal, setOpenModal] = useState(false);
  const [modalType, setModalType] = useState('');
  const modalStyle = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 580,
    bgcolor: 'background.paper',
    boxShadow: 24,
    p: 4,
    borderRadius: '8px',
  };

  const checkSubscription = () => {
    if (subscription?.status === 'past_due') {
      setModalType('overdue');
      setOpenModal(true);
      return false;
    }

    const isLimitReached =
      subscription.plan.type === 'prepaid'
        ? subscription?.currentInvoice?.documents_count >= subscription?.documents_limit
        : subscription.plan.type === 'postpaid' &&
          subscription.plan.pricing_model === 'licensed' &&
          subscription?.currentInvoice?.documents_count >= subscription?.documents_limit;

    if (isLimitReached) {
      setModalType('limitReached');
      setOpenModal(true);
      return false;
    }

    return true;
  };

  const SubscriptionModal = () => (
    <Modal open={openModal} onClose={() => setOpenModal(false)} className="backdrop-model">
      <Box sx={modalStyle}>
        {modalType === 'overdue' ? (
          <>
            <Typography variant="h6" component="h2" gutterBottom>
              Oops! Payment Due
            </Typography>
            <Typography variant="body1" gutterBottom>
              Looks like your invoice is overdue. Kindly clear the outstanding amount to continue.
            </Typography>
          </>
        ) : modalType === 'limitReached' ? (
          <>
            <Typography variant="h6" component="h2" gutterBottom>
              Invoice Usage Limit Reached
            </Typography>
            <Typography variant="body1" gutterBottom>
              You&#39;ve used up your invoice quota for this period. Upgrade your subscription to continue seamless
              access.
            </Typography>
          </>
        ) : null}
        <Box mt={2} display="flex" justifyContent="space-between">
          <Button
            variant="contained"
            onClick={() => {
              setOpenModal(false);
              navigate('/settings');
            }}
            sx={{ marginLeft: 'auto' }}
          >
            Manage Subscription
          </Button>
        </Box>
      </Box>
    </Modal>
  );

  return { checkSubscription, SubscriptionModal };
};

export default useSubscriptionCheck;
