import React from 'react';
import { Grid } from '@mui/material';
import SearchFilter from '../../../components/filters/SearchFilter';

const CreditNoteFilters = ({
  filters,
  selectedFilter,
  onFilterChange,
  searchTerm,
  onSearchChange,
  onSearchKeyPress,
}) => {
  const renderFilter = (filter) => {
    switch (filter.type) {
      case 'search':
        return (
          <SearchFilter
            key={filter.type}
            filter={filter}
            selectedFilter={selectedFilter}
            onFilterChange={onFilterChange}
            searchTerm={searchTerm}
            onSearchChange={onSearchChange}
            onSearchKeyPress={onSearchKeyPress}
          />
        );
      default:
        return null;
    }
  };

  return (
    <Grid item xs={12} container justifyContent="flex-end" spacing={1}>
      {filters.map((filter) => renderFilter(filter))}
    </Grid>
  );
};

export default CreditNoteFilters;
