import React, { useEffect, useState } from 'react';
import { Box, FormControl, MenuItem, Select, Stack, Tab, Tabs, TextField } from '@mui/material';
import SBDraftsInvoicesTable from '../components/SBDraftsInvoicesTable';
import SBCreditNotesTable from '../components/SBCreditNotesTable';
import SBDraftsDebitNotesTable from '../components/SBDraftsDebitNotesTable';
import SBDraftsRefundsTable from '../components/SBDraftsRefundsTable';
import { useLocation, useNavigate } from 'react-router-dom';

const tabs = [
  { key: 'invoices', label: 'Invoices' },
  { key: 'creditNotes', label: 'Credit Notes' },
  { key: 'debitNotes', label: 'Debit Notes' },
  { key: 'refunds', label: 'Refunds' },
];

const filters = [{ key: 'document_number', label: 'Document Number' }];

const SBDraftDocumentListPage = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const queryParams = new URLSearchParams(location.search);
  const initialTab = queryParams.get('tab') || 'invoices';

  const [selectedTab, setSelectedTab] = useState(initialTab);
  const [selectedFilter, setSelectedFilter] = useState(filters[0].key);
  const [searchQuery, setSearchQuery] = useState('');

  useEffect(() => {
    setSelectedTab(initialTab);
  }, [initialTab]);

  const handleTabChange = (event, newValue) => {
    setSelectedTab(newValue);
    navigate(`/self-billed-drafts?tab=${newValue}`);
  };

  const handleSearchKeyPress = (event) => {
    if (event.key === 'Enter') {
      setSearchQuery(event.target.value);
    }
  };

  return (
    <Stack direction="column" sx={{ mt: '-1rem' }}>
      <Box display="flex" justifyContent="space-between" alignItems="center" sx={{ mb: 2 }}>
        <Tabs value={selectedTab} onChange={handleTabChange}>
          {tabs.map((tab) => (
            <Tab key={tab.key} label={tab.label} value={tab.key} />
          ))}
        </Tabs>
        <Box display="flex" alignItems="center">
          <FormControl variant="outlined" sx={{ mr: 2, height: '56px' }}>
            <Select
              value={selectedFilter}
              onChange={(e) => setSelectedFilter(e.target.value)}
              sx={{ height: '40px' }}
              variant="outlined"
            >
              {filters.map((filter) => (
                <MenuItem key={filter.key} value={filter.key}>
                  {filter.label}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
          <TextField label="Search" variant="outlined" onKeyPress={handleSearchKeyPress} sx={{ height: '56px' }} />
        </Box>
      </Box>
      {selectedTab === 'invoices' && <SBDraftsInvoicesTable searchQuery={searchQuery} searchFilter={selectedFilter} />}
      {selectedTab === 'creditNotes' && <SBCreditNotesTable searchQuery={searchQuery} searchFilter={selectedFilter} />}
      {selectedTab === 'debitNotes' && (
        <SBDraftsDebitNotesTable searchQuery={searchQuery} searchFilter={selectedFilter} />
      )}
      {selectedTab === 'refunds' && <SBDraftsRefundsTable searchQuery={searchQuery} searchFilter={selectedFilter} />}
    </Stack>
  );
};

export default SBDraftDocumentListPage;
