import CustomerForm from '../components/CustomerForm';
import { cancelCustomerCreate, editCustomerRequest, fetchCustomerById } from '../../../api/customers/customersSlice';
import { useNavigate, useParams } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { useEffect } from 'react';
import { Stack } from '@mui/material';

export default function EditCustomerPage() {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const { profile_id } = useParams();
  const { isCustomerEdited, selectedCustomer, error, loading } = useSelector((state) => state.customers);

  useEffect(() => {
    if (profile_id) {
      dispatch(fetchCustomerById(profile_id));
    }
  }, [dispatch, profile_id]);

  useEffect(() => {
    if (isCustomerEdited) {
      navigate('/customers');
    }
  }, [isCustomerEdited, navigate]);

  const handleSubmit = (data) => {
    const payload = {
      ...data,
      company_name: data.company_name ? data.company_name : data.name,
      country_code: data.country_code.key,
      state: data.state.key,
      registration_type: data.registration_type.key,
      msic_code: data.msic_code.key,
    };

    if (data.participant_id) {
      const [identifier_code, rest] = data.participant_id.split(':');
      const [special_identifier, identifier] = rest.match(/(\d{2})(.*)/).slice(1);
      payload.peppol_participant = {
        identifier,
        special_identifier,
        identifier_code,
      };
    } else {
      payload.peppol_participant = {};
    }

    delete payload.participant_id;
    dispatch(editCustomerRequest(payload));
  };

  function handleCancel() {
    dispatch(cancelCustomerCreate());
    return navigate('/customers');
  }

  if (loading || !selectedCustomer) {
    return null;
  }

  return (
    <Stack direction="column" sx={{ mt: '-2rem' }}>
      <CustomerForm
        onSubmit={handleSubmit}
        onCancel={handleCancel}
        initialData={selectedCustomer}
        edit={true}
        apiErrors={error}
      ></CustomerForm>
    </Stack>
  );
}
