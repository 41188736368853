import React, { useState } from 'react';
import { DataGrid } from '@mui/x-data-grid';
import { useDispatch, useSelector } from 'react-redux';
import { Box, Button } from '@mui/material';
import FileDownloadOutlinedIcon from '@mui/icons-material/FileDownloadOutlined';
import { downloadPeppolXmlRequest, fetchPeppolMessagesRequest } from '../../../api/peppolMessages/peppolMessagesSlice';

const PeppolMessagesTable = () => {
  const { peppolMessages, totalDocuments } = useSelector((state) => state.peppolMessages);
  const [page] = useState(0);
  const [limit] = useState(10);
  const dispatch = useDispatch();

  const downloadXml = (params) => {
    return () => {
      dispatch(downloadPeppolXmlRequest({ message_id: params.message_id }));
    };
  };

  const columns = [
    {
      field: 'message_id',
      headerName: 'Message ID',
      flex: 1.2,
    },
    {
      field: 'sender_id',
      headerName: 'Sender',
      flex: 0.8,
    },
    {
      field: 'receiver_id',
      headerName: 'Receiver',
      flex: 0.8,
    },
    {
      field: 'created_at',
      headerName: 'Received at',
      flex: 0.6,
      renderCell: (param) => {
        console.log(param);
        return new Date(param.value).toLocaleString();
      },
    },
    {
      field: 'actions',
      headerName: 'Download XML',
      flex: 0.3,
      align: 'center',
      sortable: false,
      renderCell: (params) => {
        return <FileDownloadOutlinedIcon color="primary" onClick={downloadXml(params.row)} />;
      },
    },
  ];

  return (
    <Box>
      <DataGrid
        rows={peppolMessages}
        columns={columns}
        getRowId={(row) => row._id}
        paginationMode="server"
        rowCount={totalDocuments}
        initialState={{
          pagination: {
            paginationModel: {
              pageSize: limit,
            },
          },
        }}
        pageSizeOptions={[10, 25]}
        disableRowSelectionOnClick
        onPaginationModelChange={(model) => {
          dispatch(
            fetchPeppolMessagesRequest({
              query: {
                page: model.page + 1,
                limit: model.pageSize,
              },
            }),
          );
        }}
      />
    </Box>
  );
};

export default PeppolMessagesTable;
