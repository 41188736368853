import React from 'react';
import { Button } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import useSubscriptionCheck from '../../common/SubscriptionCheck';

function CustomerListPageActions() {
  const navigate = useNavigate();
  const { checkSubscription, SubscriptionModal } = useSubscriptionCheck();

  const handleCreateAction = () => {
    if (!checkSubscription()) {
      return;
    }
    navigate('/customers/create');
  };

  return (
    <>
      <Button key="add-new-customer" variant="contained" color="primary" onClick={handleCreateAction}>
        + Create New Customer
      </Button>
      <SubscriptionModal />
    </>
  );
}

export default CustomerListPageActions;
