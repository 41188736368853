import React, { useEffect, useState } from 'react';
import { Box, Button, Grid2 as Grid, TextField, useTheme } from '@mui/material';
import { Controller, useFieldArray, useForm } from 'react-hook-form';
import SingleDatePicker from '../../../components/form/SingleDatePicker';
import TopBar from '../../../components/TopBar';
import { useSelector } from 'react-redux';
import ProfileCard from '../../common/ProfileCard';
import { useLocation } from 'react-router-dom';
import { getCountryName, getStateName } from '../../../utils/helper';
import { yupResolver } from '@hookform/resolvers/yup';
import MYSRefundsValidation from '../../../validations/MYSRefundsValidation';
import dayjs from 'dayjs';
import BusinessProfileSelect from '../../../components/form/BusinessProfileSelect';
import CustomerSelect from '../../../components/form/CustomerSelect';
import CurrencySelect from '../../../components/form/CurrencySelect';
import ItemDetailsTable from '../../Invoices/components/ItemDetailsTable';
import PaymentMeansSelect from '../../../components/form/PaymentMeansSelect';
import pricingEngineForDocuments from '../../../utils/pricingEngineForDocuments';
import CustomTextInput from '../../../components/CustomTextInput';
import { find } from 'lodash';
import InvoiceElementButton from '../../Invoices/components/InvoiceElementButton';
import AddShippingDetailsButton from '../../Invoices/components/AddShippingDetailsButton';
import AddImportExportDataButton from '../../Invoices/components/AddImportExportDataButton';
import ImportExportDetailsCard from '../../common/ImportExportDetailsCard';
import ShippingDetailsCard from '../../common/ShippingDetailsCard';
import InvoiceSummarySection from '../../../components/SummarySection';

const buyerSection = [
  {
    label: 'Contact Person',
    key: 'name',
  },
  {
    label: 'Company Name',
    key: 'name',
  },
  {
    label: 'Address',
    key: '',
  },
  {
    label: 'Country',
    key: 'country_code',
  },
  {
    label: 'Phone',
    key: 'phone',
  },
  {
    label: 'Email',
    key: 'email',
  },
  {
    label: 'CR',
    key: 'registration_number',
  },
  {
    label: 'TIN',
    key: 'tin',
  },
  {
    label: 'VAT/GST No',
    key: 'sst_registration_number',
  },
];

export default function RefundForm({ initialData = {}, onSubmit, onCancel, readOnly, apiErrors = {} }) {
  const location = useLocation();
  const paymentMeans = useSelector((state) => state.paymentMeans.paymentMeans);
  const [chargeEditing, setChargeEditing] = useState(false);
  const [discountEditing, setDiscountEditing] = useState(false);
  const theme = useTheme();
  const countryCodes = useSelector((state) => state.countryCodes.countryCodes);
  const states = useSelector((state) => state.states.states);
  const { invoiceData } = location.state || {};
  const effectiveInvoiceTotal = invoiceData?.grand_total;

  const getPaymentModeOption = (value) => {
    if (value) {
      let found = find(paymentMeans, { Code: value });
      if (found) {
        return { label: found['Payment Method'], value: found.Code };
      }
    }

    return null;
  };

  const {
    register,
    control,
    getValues,
    setValue,
    watch,
    handleSubmit,
    setError,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(MYSRefundsValidation),
    defaultValues: {
      invoice_number: invoiceData?.document_number || initialData?.invoice_number,
      document_number: initialData?.document_number || '',
      document_id: initialData?.document_id || null,
      document_date: dayjs(initialData?.document_date) || null,
      document_time: new Date().toISOString().slice(11, 19) + 'Z',
      document_type: '04',
      transaction_type: 'B2B',
      source: 'web',
      payment_terms: invoiceData?.payment_terms || initialData?.payment_terms || '',
      payment_mode: getPaymentModeOption(invoiceData?.payment_mode || initialData?.payment_mode || null),
      item_details: (invoiceData?.item_details || initialData?.item_details || []).map(({ _id, id, ...item }) => ({
        ...item,
      })),
      document_currency_code: 'MYR',
      sub_total: invoiceData?.sub_total || initialData?.sub_total || 0,
      grand_total: invoiceData?.grand_total || initialData?.grand_total || 0,
      total_excluding_tax: invoiceData?.total_excluding_tax || initialData?.total_excluding_tax || 0,
      total_including_tax: invoiceData?.total_including_tax || initialData?.total_including_tax || 0,
      total_payable_amount: invoiceData?.total_payable_amount || initialData?.total_payable_amount || 0,
      total_tax_amount: invoiceData?.total_tax_amount || initialData?.total_tax_amount || 0,
      total_item_discount: invoiceData?.total_item_discount || initialData?.total_item_discount || 0,
      header_discount: invoiceData?.header_discount || initialData?.header_discount || 0,
      billed_by: invoiceData?.billed_by || initialData?.billed_by || null,
      billed_to: invoiceData?.billed_to || initialData?.billed_to || null,
      charges: invoiceData?.charges || initialData?.charges || [],
      discounts: invoiceData?.discounts || initialData?.discounts || [],
      pre_payment: invoiceData?.pre_payment || initialData?.pre_payment || {},
      shipped_to: initialData?.shipped_to || initialData?.shipped_to || {},
      import_export_data: initialData?.import_export_data || initialData?.import_export_data || {},
      status: initialData?.status || null,
    },
  });

  const {
    subtotal,
    charges,
    discounts,
    pre_payment,
    totalTaxAmount,
    totalItemDiscount,
    totalExcludingTax,
    totalIncludingTax,
    totalPayableAmount,
    totalItemCharges,
    grandTotal,
    headerCharges,
    headerDiscount,
  } = pricingEngineForDocuments(watch('item_details'), watch('charges'), watch('discounts'), watch('pre_payment'));

  useEffect(() => {
    setValue('sub_total', subtotal);
    setValue('header_charges', headerCharges);
    setValue('header_discount', headerDiscount);
    setValue('total_item_charges', totalItemCharges);
    setValue('total_excluding_tax', totalExcludingTax);
    setValue('total_including_tax', totalIncludingTax);
    setValue('total_item_discount', totalItemDiscount);
    setValue('total_payable_amount', totalPayableAmount);
    setValue('total_tax_amount', totalTaxAmount);
    setValue('grand_total', grandTotal);
  }, [watch('item_details'), watch('charges'), watch('pre_payment')]);
  const {
    fields: lineItems,
    replace: replaceLineItems,
    append: appendLineItem,
    remove: removeLineItem,
  } = useFieldArray({
    control,
    name: 'item_details',
  });

  const {
    fields: chargeFields,
    append: chargeAppend,
    remove: chargeRemove,
    replace: replaceCharge,
  } = useFieldArray({
    control: control,
    name: 'charges',
  });

  const {
    fields: discountFields,
    append: discountAppend,
    remove: discountRemove,
    replace: replaceDiscount,
  } = useFieldArray({
    control: control,
    name: 'discounts',
  });

  const addPrePayment = (prePaymentData) => {
    setValue('pre_payment', prePaymentData);
  };

  const addShippingData = (shippingData) => {
    setValue('shipped_to', shippingData);
  };

  const addImportExportData = (importExportData) => {
    setValue('import_export_data', importExportData);
  };

  const handleChargeEdit = () => {
    setChargeEditing(true);
  };

  const handleDiscountEdit = () => {
    setDiscountEditing(true);
  };

  return (
    <>
      {!readOnly && (
        <Box className="RefundDetails" sx={{ pt: 2, pb: 4 }}>
          <Grid
            container
            sx={{
              display: 'block',
            }}
          >
            <Grid>
              <TopBar title={'Refund Form'}>
                <Box>
                  <Button
                    key={'cancel'}
                    variant={'outlined'}
                    color={'primary'}
                    onClick={onCancel}
                    className="action-button"
                    sx={{ marginLeft: theme.spacing(1) }}
                  >
                    Cancel
                  </Button>
                  <Button
                    key={'save'}
                    variant={'contained'}
                    color={'primary'}
                    onClick={handleSubmit(onSubmit)}
                    className="action-button"
                    sx={{ marginLeft: theme.spacing(1) }}
                  >
                    Save
                  </Button>
                </Box>
              </TopBar>
            </Grid>
          </Grid>
        </Box>
      )}
      <Box className="RefundDetails">
        <Grid container spacing={2}>
          <Grid size={{ xs: 12, sm: 6, md: 4 }}>
            <CustomTextInput
              label="Refund Number"
              control={control}
              errors={errors}
              name={'document_number'}
              readOnly={readOnly}
            />
          </Grid>

          <Grid size={{ xs: 12, sm: 6, md: 4 }}>
            <SingleDatePicker
              control={control}
              name="document_date"
              label="Refund Date"
              readOnly={readOnly}
              errors={errors}
            />
          </Grid>

          <Grid size={{ xs: 12, sm: 6, md: 4 }}>
            <CustomTextInput
              label="Invoice Number"
              control={control}
              errors={errors}
              name={'invoice_number'}
              readOnly={true}
            />
          </Grid>

          {/* Payment Mode */}
          <Grid size={{ xs: 12, sm: 6, md: 4 }}>
            <PaymentMeansSelect name="payment_mode" control={control} errors={errors} readOnly={readOnly} />
          </Grid>

          {/* Payment Terms */}

          <Grid size={{ xs: 12, sm: 6, md: 4 }}>
            <Controller
              name="payment_terms"
              control={control}
              render={({ field }) => (
                <TextField
                  type="text"
                  id="payment_terms"
                  label="Payment Terms"
                  variant="outlined"
                  fullWidth
                  {...field}
                  error={!!errors.payment_terms}
                  helperText={errors.payment_terms?.message}
                  slotProps={{
                    input: {
                      readOnly: readOnly,
                    },
                  }}
                />
              )}
            />
          </Grid>
        </Grid>
        <Grid container spacing={2} sx={{ mt: 3 }}>
          <Grid size={{ xs: 12, sm: 6 }}>
            <BusinessProfileSelect name="billed_by" control={control} errors={errors} readOnly={true} />
            {watch('billed_by') && (
              <Box sx={{ mt: 2, fontSize: '0.875rem', color: 'black' }}>
                <ProfileCard
                  title="Billed By"
                  subtitle="Vendor Info"
                  fields={buyerSection}
                  document={{
                    ...watch('billed_by'),
                    state: getStateName(watch('billed_by').state, states),
                    country_code: getCountryName(watch('billed_by').country_code, countryCodes),
                  }}
                />
              </Box>
            )}
          </Grid>
          <Grid size={{ xs: 12, sm: 6 }}>
            <CustomerSelect name="billed_to" control={control} errors={errors} readOnly={true} />
            {watch('billed_to') && (
              <Box sx={{ mt: 2, fontSize: '0.875rem', color: 'black' }}>
                <ProfileCard
                  title="Billed To"
                  subtitle="Customer Info"
                  fields={buyerSection}
                  document={{
                    ...watch('billed_to'),
                    state: getStateName(watch('billed_to').state, states),
                    country_code: getCountryName(watch('billed_to').country_code, countryCodes),
                  }}
                />
              </Box>
            )}
          </Grid>
        </Grid>
        <Grid container spacing={2} sx={{ mt: 2 }}>
          <Grid size={{ xs: 12 }}>
            <Grid container sx={{ display: 'flex', justifyContent: 'space-between' }}>
              <Grid></Grid>
              <Grid size={{ md: 2 }}>
                <CurrencySelect control={control} errors={errors} name="document_currency_code" readOnly={true} />
              </Grid>
            </Grid>
            <ItemDetailsTable
              items={lineItems}
              remove={removeLineItem}
              replace={replaceLineItems}
              readOnly={readOnly}
            />
          </Grid>
        </Grid>
        <Grid container spacing={2} sx={{ mt: 2 }}>
          <Grid size={{ md: 6 }}>
            <Grid container spacing={1}>
              <Grid item md={12}>
                <InvoiceElementButton
                  control={control}
                  watch={watch}
                  chargeAppend={chargeAppend}
                  discountAppend={discountAppend}
                  chargeEditing={chargeEditing}
                  setChargeEditing={setChargeEditing}
                  discountEditing={discountEditing}
                  setDiscountEditing={setDiscountEditing}
                  replaceCharge={replaceCharge}
                  replaceDiscount={replaceDiscount}
                  addPrePayment={addPrePayment}
                  readOnly={readOnly}
                />
              </Grid>
              <Grid size={{ md: 12 }}>
                <AddShippingDetailsButton readOnly={readOnly} addShippingData={addShippingData} />
              </Grid>
              <Grid size={{ md: 12 }}>
                <AddImportExportDataButton readOnly={readOnly} addImportExportData={addImportExportData} />
              </Grid>
            </Grid>
          </Grid>
          <Grid size={{ md: 6 }}>
            <InvoiceSummarySection
              subtotal={subtotal}
              totalItemDiscount={totalItemDiscount}
              totalItemCharges={totalItemCharges}
              totalExcludingTax={totalExcludingTax}
              totalIncludingTax={totalIncludingTax}
              totalTaxAmount={totalTaxAmount}
              grandTotal={grandTotal}
              charges={watch('charges')}
              discounts={watch('discounts')}
              prePayment={watch('pre_payment')}
              documentCurrencyCode={getValues('document_currency_code')}
              readOnly={readOnly}
              handleChargeEdit={handleChargeEdit}
              handleDiscountEdit={handleDiscountEdit}
              chargeRemove={chargeRemove}
              discountRemove={discountRemove}
              addPrePayment={addPrePayment}
              getValues={getValues}
              watch={watch}
            />
          </Grid>
        </Grid>
        <Grid container spacing={2} sx={{ mt: 3 }}>
          <Grid size={{ xs: 12, sm: 6 }}>
            {watch('shipped_to') && Object.keys(watch('shipped_to')).length > 0 && (
              <Box sx={{ mt: 2, fontSize: '0.875rem', color: 'black' }}>
                <ShippingDetailsCard
                  document={{
                    ...watch('shipped_to'), // Use the selected value from the form state
                    state: getStateName(watch('shipped_to').state, states),
                    country_code: getCountryName(watch('shipped_to').country_code, countryCodes),
                  }}
                />
              </Box>
            )}
          </Grid>
          <Grid size={{ xs: 12, sm: 6 }}>
            {watch('import_export_data') && Object.keys(watch('import_export_data')).length > 0 && (
              <Box sx={{ mt: 2, fontSize: '0.875rem', color: 'black' }}>
                <ImportExportDetailsCard
                  document={{
                    ...watch('import_export_data'), // Use the selected value from the form state
                  }}
                />
              </Box>
            )}
          </Grid>
        </Grid>
      </Box>
    </>
  );
}
