import React, { useEffect, useState } from 'react';
import { Box, Button, Divider, Grid2 as Grid, Paper, Typography, useTheme } from '@mui/material';
import TopBar from '../../../components/TopBar';
import { useDispatch, useSelector } from 'react-redux';
import { find, isEmpty, map } from 'lodash';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import CustomTextInput from '../../../components/CustomTextInput';
import CountrySelect from '../../../components/form/CountrySelect';
import StateSelect from '../../../components/form/StateSelect';
import RegistrationTypeSelect from '../../../components/form/RegistrationTypeSelect';
import { CustomerValidation } from '../../../validations/CustomerValidation';
import { fetchParticipantRequest } from '../../../api/participants/participantsSlice';
import PeppolDetails from '../../../components/form/PeppolDetails';

export default function CustomerForm({
  onSubmit,
  onCancel,
  initialData = {},
  apiErrors = {},
  edit = false,
  readOnly = false,
}) {
  const theme = useTheme();
  const dispatch = useDispatch();
  const { countryCodes } = useSelector((state) => state.countryCodes);
  const { states } = useSelector((state) => state.states);
  const { msicCodes } = useSelector((state) => state.msicCodes);
  const participant = useSelector((state) => state.participants.participant);

  const [participantId, setParticipantId] = useState(initialData.participant_id || '');
  const [peppolParticipant, setPeppolParticipant] = useState(initialData.peppol_participant || {});
  const [loading, setLoading] = useState(false);
  const [loadingParticipant, setLoadingParticipant] = useState(false);

  let country = find(countryCodes, { Code: initialData.country_code });
  let state = find(states, { Code: initialData.state });
  let msicCode = find(msicCodes, { Code: initialData.msic_code });

  const {
    register,
    control,
    getValues,
    setValue,
    watch,
    handleSubmit,
    setError,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(CustomerValidation),
    defaultValues: {
      ...initialData,
      country_code: country ? { key: country.Code, value: country.Country } : '',
      state: state ? { key: state.Code, value: state.State } : '',
      msic_code: msicCode ? { key: msicCode.Code, value: msicCode.Description } : '',
      registration_type: initialData.registration_type
        ? {
            key: initialData.registration_type,
            value: initialData.registration_type,
          }
        : '',
      peppol_participant: initialData.peppol_participant ? initialData.peppol_participant : {},
      participant_id:
        initialData.peppol_participant?.identifier_code &&
        initialData.peppol_participant?.special_identifier &&
        initialData.peppol_participant?.identifier
          ? `${initialData.peppol_participant.identifier_code}:${initialData.peppol_participant.special_identifier}${initialData.peppol_participant.identifier}`
          : '',
    },
  });

  useEffect(() => {
    map(apiErrors, (value, key) => {
      setError(key, { message: value, type: 'string' });
    });
  }, [setError, apiErrors]);

  useEffect(() => {
    const watchParticipantId = watch((value, { name }) => {
      if (name === 'participant_id') {
        setParticipantId(value.participant_id);
        setLoadingParticipant(true);
      }
    });
    return () => watchParticipantId.unsubscribe();
  }, [watch]);

  useEffect(() => {
    setParticipantId(getValues('participant_id'));
    if (participantId) {
      handleFetchParticipants();
    }
  }, [participantId]);

  const handleFetchParticipants = async () => {
    if (!participantId) return;

    setLoading(true);
    setLoadingParticipant(true);

    const [identifier_code, rest] = participantId.split(':');
    const match = rest ? rest.match(/(\d{2})(.*)/) : [];
    const [special_identifier, identifier] = match ? match.slice(1) : [];

    try {
      await dispatch(fetchParticipantRequest({ identifier_code, identifier, special_identifier }));
    } catch (error) {
      console.error('Error fetching participant:', error);
    } finally {
      setLoading(false);
    }
  };

  const isPeppolEmpty = !peppolParticipant?.endpoint_id;

  useEffect(() => {
    setLoadingParticipant(false);
    if (!isEmpty(participant)) {
      const match = participant.idValue?.match(/(\d{2})(.*)/);
      const [special_identifier, identifier] = match ? match.slice(1) : [];

      setPeppolParticipant({
        scheme: participant?.idScheme,
        identifier: identifier,
        identifier_code: participant?.idType,
        special_identifier: special_identifier,
        endpoint_id: participant?.endpoint[0]?.url,
      });
    }
    if (participantId === '') {
      setPeppolParticipant({});
    }
  }, [participant]);

  return (
    <Paper elevation={0} sx={{ p: '1rem' }}>
      {!readOnly && (
        <Box sx={{ pt: 2, pb: 4 }}>
          <Grid
            container
            sx={{
              display: 'block',
            }}
          >
            <Grid>
              <TopBar title={'Customer Form'}>
                <Box>
                  <Button key={'cancel'} variant="outlined" onClick={onCancel} sx={{ marginLeft: theme.spacing(1) }}>
                    Cancel
                  </Button>
                  <Button
                    key={'save'}
                    variant="contained"
                    onClick={handleSubmit(onSubmit)}
                    sx={{ marginLeft: theme.spacing(1) }}
                  >
                    Save
                  </Button>
                </Box>
              </TopBar>
            </Grid>
          </Grid>
        </Box>
      )}
      <Box>
        <Grid container spacing={2}>
          <Grid size={{ xs: 12, sm: 6 }}>
            <CustomTextInput name="name" control={control} errors={errors} readOnly={readOnly} label={'Name'} />
          </Grid>
          <Grid size={{ xs: 12, sm: 6 }}>
            <CustomTextInput
              name="company_name"
              control={control}
              errors={errors}
              readOnly={readOnly}
              label={'Company Name'}
            />
          </Grid>
          <Grid size={{ xs: 12, sm: 6 }}>
            <CustomTextInput
              name="company_name_in_local"
              control={control}
              errors={errors}
              readOnly={readOnly}
              label={'Company Name in Local Language'}
            />
          </Grid>
          <Grid size={{ xs: 12, sm: 6 }}>
            <CustomTextInput name="email" control={control} errors={errors} readOnly={readOnly} label={'Email'} />
          </Grid>
        </Grid>

        <Typography variant="h6" sx={{ mb: '1.5rem', pt: '1rem' }}>
          Address
          <Divider></Divider>
        </Typography>

        <Grid container spacing={2}>
          <Grid size={{ xs: 12, sm: 6 }}>
            <CustomTextInput
              name="address1"
              control={control}
              errors={errors}
              readOnly={readOnly}
              label={'Address Line 1'}
            />
          </Grid>
          <Grid size={{ xs: 12, sm: 6 }}>
            <CustomTextInput
              name="address2"
              control={control}
              errors={errors}
              readOnly={readOnly}
              label={'Address Line 2'}
            />
          </Grid>
          <Grid size={{ xs: 12, sm: 6 }}>
            <CustomTextInput
              name="address3"
              control={control}
              errors={errors}
              readOnly={readOnly}
              label={'Address Line 3'}
            />
          </Grid>
          <Grid size={{ xs: 12, sm: 6 }}>
            <CustomTextInput name="city" control={control} errors={errors} readOnly={readOnly} label={'City'} />
          </Grid>
          <Grid size={{ xs: 12, sm: 6 }}>
            <CountrySelect
              name="country_code"
              control={control}
              errors={errors}
              readOnly={readOnly}
              label={'Country'}
            />
          </Grid>
          <Grid size={{ xs: 12, sm: 6 }}>
            <StateSelect errors={errors} control={control} name="state" readOnly={readOnly} label={'State'} />
          </Grid>
          <Grid size={{ xs: 12, sm: 6 }}>
            <CustomTextInput
              name="postal_code"
              control={control}
              errors={errors}
              readOnly={readOnly}
              label={'Postal Code'}
            />
          </Grid>
          <Grid size={{ xs: 12, sm: 6 }}>
            <CustomTextInput name="phone" control={control} errors={errors} readOnly={readOnly} label={'Phone'} />
          </Grid>
        </Grid>

        <Typography variant="h6" sx={{ mb: '1.5rem', pt: '1rem' }}>
          Business Identifiers
          <Divider></Divider>
        </Typography>

        <Grid container spacing={2}>
          <Grid size={{ xs: 12, sm: 6 }}>
            <RegistrationTypeSelect errors={errors} readOnly={readOnly} control={control} name="registration_type" />
          </Grid>
          <Grid size={{ xs: 12, sm: 6 }}>
            <CustomTextInput
              name="registration_number"
              control={control}
              errors={errors}
              readOnly={readOnly}
              label={'Registration Number'}
            />
          </Grid>
          <Grid size={{ xs: 12, sm: 6 }}>
            <CustomTextInput name="tin" control={control} errors={errors} readOnly={readOnly || edit} label={'TIN'} />
          </Grid>
          <Grid size={{ xs: 12, sm: 6 }}>
            <CustomTextInput
              name="sst_registration_number"
              control={control}
              errors={errors}
              readOnly={readOnly}
              label={'SST Registration Number'}
            />
          </Grid>
        </Grid>
        {(edit || readOnly) && (
          <PeppolDetails
            control={control}
            errors={errors}
            getValues={getValues}
            setParticipantId={setParticipantId}
            handleFetchParticipants={handleFetchParticipants}
            loading={loading}
            readOnly={readOnly}
            loadingParticipant={loadingParticipant}
            isPeppolEmpty={isPeppolEmpty}
            peppolParticipant={peppolParticipant}
          />
        )}
      </Box>
    </Paper>
  );
}
