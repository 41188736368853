import React from 'react';
import { Autocomplete, TextField } from '@mui/material';
import { Controller } from 'react-hook-form';
import { get } from 'lodash';

const RegistrationTypeSelect = ({ name, label = 'Registration Type', control, errors, readOnly = false }) => {
  const options = [
    {
      key: 'BRN',
      value: 'BRN',
    },
    {
      key: 'PASSPORT',
      value: 'PASSPORT',
    },
    {
      key: 'ARMY',
      value: 'ARMY',
    },
    {
      key: 'NRIC',
      value: 'NRIC',
    },
  ];

  return (
    <Controller
      name={name}
      control={control}
      render={({ field }) => {
        return (
          <Autocomplete
            {...field}
            options={options}
            readOnly={readOnly}
            getOptionLabel={(option) => option.value}
            renderInput={(params) => (
              <TextField
                {...params}
                label={label}
                error={!!get(errors, name, null)}
                helperText={get(errors, name, {})?.message}
              />
            )}
            isOptionEqualToValue={(option, value) => option.value === value.value}
            onChange={(_, value) => field.onChange(value)}
            value={field.value || null} // handle default value
          />
        );
      }}
    />
  );
};

export default RegistrationTypeSelect;
