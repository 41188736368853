import React from 'react';
import { Autocomplete, TextField } from '@mui/material';
import { Controller } from 'react-hook-form';
import { useSelector } from 'react-redux';
import { get } from 'lodash';

const CountrySelect = ({ name, label = 'Country', control, errors, readOnly = false }) => {
  const countryCodes = useSelector((state) => state.countryCodes.countryCodes);
  const countryCodeOptions = countryCodes.map((item) => {
    return { key: item.Code, value: item.Country };
  });

  return (
    <Controller
      name={name}
      control={control}
      render={({ field }) => {
        return (
          <Autocomplete
            {...field}
            options={countryCodeOptions}
            readOnly={readOnly}
            getOptionLabel={(option) => option.value}
            renderInput={(params) => (
              <TextField
                {...params}
                label={label}
                error={!!get(errors, name, null)}
                helperText={get(errors, name, {})?.message}
              />
            )}
            isOptionEqualToValue={(option, value) => option.value === value.value}
            onChange={(_, value) => field.onChange(value)}
            value={field.value || null} // handle default value
          />
        );
      }}
    />
  );
};

export default CountrySelect;
