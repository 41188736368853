import React, { useEffect } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { clearSelectedInvoice, fetchInvoiceById } from '../../../api/invoices/invoicesSlice';
import { Box, Stack } from '@mui/material';
import TopBar from '../../../components/TopBar';
import useDocumentTitle from '../../../hooks/useDocumentTitle';
import InvoiceForm from '../../Invoices/components/InvoiceForm';
import DetailsPageActionButtons from '../components/DraftDetailsPageActions';

export default function DraftsInvoiceDetailsPage() {
  useDocumentTitle('Invoice Details');
  const { document_id } = useParams();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const invoice = useSelector((state) => state.invoices.selectedInvoice);

  useEffect(() => {
    if (document_id) {
      dispatch(fetchInvoiceById(document_id));
    }
    //The function which is returned by useEffect will act as cleanup of what useEffect does
    return () => {
      dispatch(clearSelectedInvoice());
    };
  }, [dispatch, document_id]);
  if (!invoice) return null;

  const handleBack = () => {
    navigate('/drafts?tab=invoices');
  };

  if (!invoice) return null;

  return (
    <Stack direction="column" sx={{ mt: '-1rem' }}>
      <Box sx={{ pb: 4 }}>
        <TopBar title="Invoice Details" subTitle={invoice?.document_id}>
          <DetailsPageActionButtons onBack={handleBack} />
        </TopBar>
      </Box>
      <InvoiceForm onSubmit={() => {}} onCancel={() => {}} initialData={invoice} readOnly={true} />
    </Stack>
  );
}
