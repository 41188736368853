import React, { useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { clearSelectedDebitNote, fetchDebitNoteById } from '../../../api/debitNotes/debitNotesSlice';
import { Box, Stack } from '@mui/material';
import '../../../styles/DocumentDetails.css';
import TopBar from '../../../components/TopBar';
import useDocumentTitle from '../../../hooks/useDocumentTitle';
import DebitNoteForm from '../../debitNotes/components/DebitNoteForm';
import SBDebitNoteDetailsPageActionButton from '../components/SBDebitNoteDetailsPageActionButton';
import IrbmStatusCard from '../../common/IrbmStatusCard';

export default function SBDebitNoteDetailsPage({ config }) {
  useDocumentTitle('Self Billed Debit Note Details');
  const { document_id } = useParams();
  const dispatch = useDispatch();
  const debitNote = useSelector((state) => state.debitNotes.selectedDebitNote);

  useEffect(() => {
    if (document_id) {
      dispatch(fetchDebitNoteById(document_id));
    }
    //The function which is returned by useEffect will act as cleanup of what useEffect does
    return () => {
      dispatch(clearSelectedDebitNote());
    };
  }, [dispatch, document_id]);
  if (!debitNote) return null;

  return (
    <Stack direction="column" sx={{ mt: '-1rem' }}>
      {debitNote && (
        <Box sx={{ pb: 4 }}>
          <TopBar title="Self Billed Debit Note Details" subTitle={debitNote?.document_id}>
            <SBDebitNoteDetailsPageActionButton />
          </TopBar>
        </Box>
      )}

      <IrbmStatusCard document={debitNote} />

      <DebitNoteForm onSubmit={() => {}} onCancel={() => {}} initialData={debitNote} readOnly={true} />
    </Stack>
  );
}
