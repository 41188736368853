import * as React from 'react';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import Box from '@mui/material/Box';
import SubscriptionSection from '../components/SubscriptionSection';
import SftpSection from '../components/sftpSection';
import EmailSection from '../components/EmailSection';

export default function SettingsPage() {
  const [value, setValue] = React.useState('subscription');

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  return (
    <Box sx={{ width: '100%' }}>
      <Tabs
        value={value}
        onChange={handleChange}
        textColor="primary"
        indicatorColor="primary"
        aria-label="settings tabs"
      >
        <Tab value="subscription" label="Subscription & Billing" />
        <Tab value="sftp" label="SFTP Config" />
        <Tab value="email" label="Emails" />
      </Tabs>

      <Box sx={{ p: 2 }}>
        {value === 'subscription' && <SubscriptionSection />}
        {value === 'sftp' && <SftpSection />}
        {value === 'email' && <EmailSection />}
      </Box>
    </Box>
  );
}
