import { Box, Button, Divider, Fade, IconButton, Modal, Stack, Typography } from '@mui/material';
import React, { useEffect } from 'react';
import CloseIcon from '@mui/icons-material/Close';
import { useForm } from 'react-hook-form';
import CustomTextInput from '../../../components/CustomTextInput';
import { useSelector } from 'react-redux';
import CountrySelect from '../../../components/form/CountrySelect';
import StateSelect from '../../../components/form/StateSelect';
import RegistrationTypeSelect from '../../../components/form/RegistrationTypeSelect';

const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: '500px',
  bgcolor: 'background.paper',
  borderRadius: '15px',
  boxShadow: 24,
};

let defaultState = {
  name: '',
  address1: '',
  address2: '',
  address3: '',
  city: '',
  state: '',
  country_code: '',
  postal_code: '',
  registration_type: '',
  registration_number: '',
  tin: '',
};

export default function AddShippingDetailsPopup({
  open,
  handleClose,
  readOnly = false,
  initialData = defaultState,
  addShippingData,
}) {
  const { countryCodes } = useSelector((state) => state.countryCodes);
  const { states } = useSelector((state) => state.states);

  const {
    handleSubmit: handlePopupSubmit,
    watch: popupWatch,
    control: popupControl,
    setError,
    clearErrors,
    formState: { errors: popupErrors },
  } = useForm({
    defaultValues: initialData,
  });

  const registrationType = popupWatch('registration_type');
  const registrationNumber = popupWatch('registration_number');
  const tin = popupWatch('tin');

  useEffect(() => {
    const hasValue = registrationType || registrationNumber || tin;

    if (hasValue) {
      if (!registrationType)
        setError('registration_type', {
          type: 'required',
          message: 'Required',
        });
      else clearErrors('registration_type');

      if (!registrationNumber)
        setError('registration_number', {
          type: 'required',
          message: 'Required',
        });
      else clearErrors('registration_number');

      if (!tin) setError('tin', { type: 'required', message: 'Required' });
      else clearErrors('tin');
    } else {
      clearErrors(['registration_type', 'registration_number', 'tin']);
    }
  }, [registrationType, registrationNumber, tin, setError, clearErrors]);

  const handleFormSubmit = (data) => {
    const shippingData = {
      ...data,
      country_code: data.country_code.key,
      state: data.state.key,
      registration_type: data.registration_type.key,
    };
    Object.keys(shippingData).forEach((key) => {
      if (!shippingData[key]) {
        delete shippingData[key];
      }
    });
    addShippingData(shippingData);
    handleClose();
  };

  return (
    <Modal
      open={open}
      onClose={(event, reason) => {
        if (reason !== 'backdropClick') {
          handleClose();
        }
      }}
      className={'backdrop-model'}
    >
      <Fade in={open}>
        <Box sx={style}>
          <Box
            sx={{
              display: 'flex',
              justifyContent: 'space-between',
              alignItems: 'center',
              px: 2,
              py: 2,
              borderBottom: '1px solid #e0e0e0',
            }}
          >
            <Typography variant="h6">Shipping Details</Typography>
            <IconButton onClick={handleClose}>
              <CloseIcon />
            </IconButton>
          </Box>
          <Divider />
          <Box sx={{ p: 2 }}>
            <Stack spacing={2}>
              <CustomTextInput
                name="name"
                label="Recipient's Name"
                control={popupControl}
                errors={popupErrors}
                readOnly={readOnly}
              />
              <CustomTextInput
                name="address1"
                label="Address Line 1"
                control={popupControl}
                errors={popupErrors}
                readOnly={readOnly}
              />
              <CustomTextInput
                name="address2"
                label="Address Line 2"
                control={popupControl}
                errors={popupErrors}
                readOnly={readOnly}
              />
              <CustomTextInput
                name="address3"
                label="Address Line 3"
                control={popupControl}
                errors={popupErrors}
                readOnly={readOnly}
              />
              <CustomTextInput
                name="city"
                label="City"
                control={popupControl}
                errors={popupErrors}
                readOnly={readOnly}
              />
              <StateSelect name="state" label="State" control={popupControl} errors={popupErrors} readOnly={readOnly} />
              <CountrySelect
                name="country_code"
                control={popupControl}
                errors={popupErrors}
                readOnly={readOnly}
                label={'Country'}
              />
              <CustomTextInput
                name="postal_code"
                label="Postal Code"
                control={popupControl}
                errors={popupErrors}
                readOnly={readOnly}
              />
              <RegistrationTypeSelect
                name="registration_type"
                label="Registration Type"
                control={popupControl}
                errors={popupErrors}
                readOnly={readOnly}
              />
              <CustomTextInput
                name="registration_number"
                label="Registration Number"
                control={popupControl}
                errors={popupErrors}
                readOnly={readOnly}
              />
              <CustomTextInput name="tin" label="TIN" control={popupControl} errors={popupErrors} readOnly={readOnly} />
            </Stack>
          </Box>
          <Divider />
          <Box
            sx={{
              px: 2,
              py: 1,
              borderTop: '1px solid #e0e0e0',
              display: 'flex',
              justifyContent: 'flex-end',
              gap: 1,
            }}
          >
            <Button type="button" variant="contained" color="primary" onClick={handlePopupSubmit(handleFormSubmit)}>
              Add Shipping Data
            </Button>
          </Box>
        </Box>
      </Fade>
    </Modal>
  );
}
