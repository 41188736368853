import React from 'react';
import { Box, Typography, Button, Grid, IconButton } from '@mui/material';
import { CloudUpload, CheckCircle, Close } from '@mui/icons-material';

const FileUploadBox = ({ handleFileUpload, pdfFile, setPdfFile, errors }) => (
  <Box
    onDragOver={(e) => e.preventDefault()}
    onDrop={(e) => {
      e.preventDefault();
      handleFileUpload(e.dataTransfer.files[0]);
    }}
    style={{
      border: '2px dashed #ccc',
      borderRadius: '8px',
      textAlign: 'center',
      padding: '20px',
      backgroundColor: '#fafafa',
      width: '100%',
      margin: '0 auto',
    }}
  >
    {!pdfFile ? (
      <>
        <CloudUpload fontSize="large" color="action" />
        <Typography variant="body2" mt={1}>
          Drag & Drop PDF file here
        </Typography>
        <Typography variant="caption" color="textSecondary">
          Supported format: .pdf
        </Typography>
        <Box mt={2}>
          <input
            type="file"
            accept="application/pdf"
            style={{ display: 'none' }}
            id="pdf-file-input"
            onChange={(e) => handleFileUpload(e.target.files[0])}
          />
          <label htmlFor="pdf-file-input">
            <Button variant="contained" color="primary" component="span" size="small">
              Browse
            </Button>
          </label>
        </Box>
      </>
    ) : (
      <Grid
        justifyContent={'space-between'}
        alignItems="center"
        style={{
          border: '2px solid #4caf50',
          borderRadius: '8px',
          backgroundColor: '#e8f5e9',
          width: '40%',
          display: 'inline-flex',
        }}
      >
        <Grid
          display="flex"
          flexDirection="row"
          alignItems="center"
          justifyContent="space-evenly"
          sx={{ width: 'auto' }}
          gap={1}
        >
          <CheckCircle color="success" />
          <Typography variant="body2" style={{ fontWeight: 'bold', wordBreak: 'break-word', whiteSpace: 'normal' }}>
            {pdfFile.name}
          </Typography>
          <Typography variant="body2" style={{ color: '#4caf50', wordBreak: 'break-word', whiteSpace: 'normal' }}>
            File successfully uploaded
          </Typography>
        </Grid>
        <IconButton onClick={() => setPdfFile(null)}>
          <Close />
        </IconButton>
      </Grid>
    )}
    {errors.pdf_file && (
      <Typography variant="body2" mt={2} color="error">
        {errors.pdf_file}
      </Typography>
    )}
  </Box>
);

export default FileUploadBox;
