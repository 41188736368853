import React from 'react';
import { TextField } from '@mui/material';
import { Controller } from 'react-hook-form';
import { get } from 'lodash';

const CustomTextInput = ({ control, name, label, errors, type = 'text', readOnly }) => {
  return (
    <Controller
      name={name}
      control={control}
      render={({ field }) => (
        <TextField
          type={type}
          id={name}
          label={label}
          variant="outlined"
          fullWidth
          {...field}
          error={!!errors[name]}
          helperText={get(errors, name, {}).message}
          slotProps={{
            input: {
              readOnly: readOnly,
            },
          }}
        />
      )}
    />
  );
};

export default CustomTextInput;
