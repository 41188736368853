import React, { useEffect } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { clearSelectedRefund, fetchRefundById } from '../../../api/refunds/refundsSlice';
import { Box, Stack } from '@mui/material';
import TopBar from '../../../components/TopBar';
import useDocumentTitle from '../../../hooks/useDocumentTitle';
import SBDetailsPageActionButtons from '../components/SBDraftDetailsPageActions';
import SBRefundForm from '../../selfBilledRefunds/comopnents/SBRefundForm';

export default function SBDraftRefundDetailsPage() {
  useDocumentTitle('Self Billed Refund Details');
  const { document_id } = useParams();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const refund = useSelector((state) => state.refunds.selectedRefund);

  useEffect(() => {
    if (document_id) {
      dispatch(fetchRefundById(document_id));
    }
    //The function which is returned by useEffect will act as cleanup of what useEffect does
    return () => {
      dispatch(clearSelectedRefund());
    };
  }, [dispatch, document_id]);
  if (!refund) return null;

  const handleBack = () => {
    navigate('/self-billed-drafts?tab=refunds');
  };

  if (!refund) return null;

  return (
    <Stack direction="column" sx={{ mt: '-1rem' }}>
      <Box sx={{ pb: 4 }}>
        <TopBar title="Refund Details" subTitle={refund?.document_id}>
          <SBDetailsPageActionButtons onBack={handleBack} />
        </TopBar>
      </Box>
      <SBRefundForm onSubmit={() => {}} onCancel={() => {}} initialData={refund} readOnly={true} />
    </Stack>
  );
}
