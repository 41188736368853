import React, { useState } from 'react';
import { Button } from '@mui/material';
import AddImportExportDataPopup from './AddImportExportDataPopup';

export default function AddImportExportDataButton({ readOnly = false, addImportExportData }) {
  const [importExportDataPopupOpen, setImportExportDataPopupOpen] = useState(false);

  const handleOpen = () => {
    setImportExportDataPopupOpen(true);
  };

  const handleClose = () => {
    setImportExportDataPopupOpen(false);
  };

  return (
    <div>
      {!readOnly && (
        <Button variant="contained" onClick={handleOpen}>
          + Import Export Data
        </Button>
      )}
      <AddImportExportDataPopup
        open={importExportDataPopupOpen}
        onClose={handleClose}
        addImportExportData={addImportExportData}
        readOnly={readOnly}
      />
    </div>
  );
}
