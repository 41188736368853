import React from 'react';
import { Grid2 as Grid, IconButton, Typography } from '@mui/material';
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';
import { currencyFormatter } from '../utils/currencyFormat';

const NumberOrPercentageField = ({
  label,
  value,
  currencyCode,
  type,
  readOnly,
  onEdit,
  onDelete,
  editComponent: EditComponent,
}) => {
  if (!value) return null;

  return (
    <>
      <Grid size={6} display={'flex'} sx={{ alignItems: 'center' }}>
        <Typography>{label}</Typography>
        {!readOnly && (
          <>
            {EditComponent ? (
              <EditComponent />
            ) : (
              <IconButton aria-label="edit" onClick={onEdit} color="primary">
                <EditIcon />
              </IconButton>
            )}
            <IconButton aria-label="delete" onClick={onDelete} color="error">
              <DeleteIcon />
            </IconButton>
          </>
        )}
      </Grid>
      <Grid size={2} display={'flex'} sx={{ alignItems: 'center' }}>
        <Typography>{currencyCode}</Typography>
      </Grid>
      <Grid size={4} display={'flex'} sx={{ alignItems: 'center', justifyContent: 'flex-end' }}>
        <span>
          {type === 'percentage'
            ? `(${value.value}%) ${currencyFormatter().format(value.amount)}`
            : `${currencyFormatter().format(value.amount)}`}
        </span>
      </Grid>
    </>
  );
};

export default NumberOrPercentageField;
