import React, { useEffect } from 'react';
import useDocumentTitle from '../../../hooks/useDocumentTitle';
import { useDispatch, useSelector } from 'react-redux';
import { markBusinessProfileCreated } from '../../../api/businessProfiles/businessProfilesSlice';
import { useLocation, useNavigate } from 'react-router-dom';
import { createRefundRequest, markRefundCreated, refundEDocRequest } from '../../../api/refunds/refundsSlice';
import RefundForm from '../components/RefundForm';
import { Stack } from '@mui/material';
import moment from 'moment/moment';

export default function CreateRefundPage() {
  useDocumentTitle('Refund Form');
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const location = useLocation();
  const { isRefundCreated, error } = useSelector((state) => state.refunds);
  const document = location.state?.document;

  useEffect(() => {
    dispatch(markBusinessProfileCreated(false));
    dispatch(markRefundCreated(false));
  }, [dispatch]);

  useEffect(() => {
    if (isRefundCreated) {
      navigate('/documents/refunds');
    }
  }, [isRefundCreated, navigate]);

  const onSubmit = async (data) => {
    const payload = {
      ...data,
      document_date: moment(data.document_date).format('YYYY-MM-DD'),
      payment_mode: data.payment_mode.value,
      item_details: data.item_details.map(({ id, ...item }) => ({
        ...item,
      })),
    };

    if (payload.status === 'draft') {
      payload.item_details.forEach((item) => {
        delete item._id;
      });
      await dispatch(refundEDocRequest(payload));
    } else {
      await dispatch(createRefundRequest(payload));
    }
  };

  const onCancel = async () => {
    navigate(location.state?.from || '/documents/refunds');
  };

  return (
    <Stack direction="column" sx={{ mt: '-2rem' }}>
      <RefundForm initialData={document || {}} onSubmit={onSubmit} onCancel={onCancel} apiErrors={error} />
    </Stack>
  );
}
