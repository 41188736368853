import * as Yup from 'yup';

// Validation schemas
const chargeValidationSchema = Yup.object().shape({
  type: Yup.string().required('Charge type is required'),
  value: Yup.number().required('Charge value is required').min(0, 'Charge value must be at least 0'),
});

const discountValidationSchema = Yup.object().shape({
  type: Yup.string().required('Discount type is required'),
  value: Yup.number().required('Discount value is required').min(0, 'Discount value must be at least 0'),
});

const taxValidationSchema = Yup.object().shape({
  type: Yup.string().required('Tax type is required'),
  value: Yup.number().required('Tax value is required').min(0, 'Tax value must be at least 0'),
  category: Yup.object().required('Tax category is required'),
});

const lineItemValidationSchema = Yup.object().shape({
  item_code: Yup.object().required('Item classification code is required'),
  description: Yup.string().required('Description is required'),
  unit_of_measure: Yup.object().required('UOM code is required'),
  quantity: Yup.number().required('Quantity is required').min(1, 'Quantity must be at least 1'),
  unit_rate: Yup.number().required('Unit rate is required').min(0, 'Unit rate must be at least 0'),
  discount_amount: Yup.number().required('Discount is required').min(0, 'Discount must be at least 0'),
  charge_amount: Yup.number().required('Discount is required').min(0, 'Discount must be at least 0'),
  tax_amount: Yup.number().required('Discount is required').min(0, 'Discount must be at least 0'),
  discounts: Yup.array().of(discountValidationSchema),
  charges: Yup.array().of(chargeValidationSchema),
  taxes: Yup.array().of(taxValidationSchema),
});

export { chargeValidationSchema, discountValidationSchema, taxValidationSchema, lineItemValidationSchema };
