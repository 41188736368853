import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  businessProfiles: [],
  selectedBusinessProfile: null,
  currentPage: 1,
  totalPages: 1,
  totalDocuments: 0,
  loading: false,
  isBusinessCreated: false,
  isBusinessProfileCreated: false,
  isBusinessProfileEdited: false,
  error: null,
};

const businessProfilesSlice = createSlice({
  name: 'businessProfiles',
  initialState,
  reducers: {
    fetchBusinessProfilesRequest(state) {
      state.loading = true;
      state.error = null;
    },
    fetchBusinessProfilesSuccess(state, action) {
      state.loading = false;
      state.businessProfiles = action.payload.business_profiles;
      state.currentPage = action.payload.currentPage;
      state.totalPages = action.payload.totalPages;
      state.totalDocuments = action.payload.totalDocuments;
    },
    fetchBusinessProfilesFailure(state, action) {
      state.loading = false;
      state.error = action.payload;
    },
    fetchBusinessProfileById(state) {
      state.loading = true;
      state.error = null;
    },
    fetchBusinessProfileByIdSuccess(state, action) {
      state.loading = false;
      state.selectedBusinessProfile = action.payload;
    },
    fetchBusinessProfileByIdFailure(state, action) {
      state.loading = false;
      state.error = action.payload;
    },
    createBusinessProfileRequest(state) {
      state.loading = true;
      state.error = null;
      state.isBusinessProfileCreated = false;
    },
    editBusinessProfileRequest(state) {
      state.loading = true;
      state.error = null;
      state.isBusinessProfileEdited = false;
    },
    createBusinessProfileSuccess(state) {
      state.loading = false;
      state.error = null;
      state.isBusinessProfileCreated = true;
    },
    editBusinessProfileFailure(state, action) {
      state.loading = false;
      state.isBusinessProfileEdited = false;
      state.error = action.payload;
    },
    editBusinessProfileSuccess(state) {
      state.loading = false;
      state.error = null;
      state.isBusinessProfileEdited = true;
    },
    createBusinessProfileFailure(state, action) {
      state.loading = false;
      state.isBusinessProfileCreated = false;
      state.error = action.payload;
    },
    cancelBusinessProfileCreate(state) {
      state.loading = false;
      state.error = null;
      state.isBusinessProfileCreated = false;
    },
    markBusinessProfileCreated(state, action) {
      state.isBusinessProfileCreated = action.payload;
    },
    markBusinessProfileEdited(state, action) {
      state.isBusinessProfileEdited = action.payload;
    },
    resetSelectedBusinessProfile(state) {
      state.selectedBusinessProfile = null;
    },
  },
});

export const {
  fetchBusinessProfilesRequest,
  fetchBusinessProfilesSuccess,
  fetchBusinessProfilesFailure,
  fetchBusinessProfileById,
  fetchBusinessProfileByIdSuccess,
  fetchBusinessProfileByIdFailure,
  createBusinessProfileRequest,
  createBusinessProfileSuccess,
  createBusinessProfileFailure,
  editBusinessProfileRequest,
  editBusinessProfileFailure,
  editBusinessProfileSuccess,
  markBusinessProfileCreated,
  markBusinessProfileEdited,
  cancelBusinessProfileCreate,
  resetSelectedBusinessProfile,
} = businessProfilesSlice.actions;

export default businessProfilesSlice.reducer;
