import SupplierForm from '../components/SupplierForm';
import { cancelSupplierCreate, editSupplierRequest, fetchSupplierById } from '../../../api/suppliers/suppliersSlice';
import { useNavigate, useParams } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { useEffect } from 'react';
import { Stack } from '@mui/material';

export default function EditSupplierPage() {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const { profile_id } = useParams();
  const { isSupplierEdited, selectedSupplier, error, loading } = useSelector((state) => state.suppliers);

  useEffect(() => {
    if (profile_id) {
      dispatch(fetchSupplierById(profile_id));
    }
  }, [dispatch, profile_id]);

  useEffect(() => {
    if (isSupplierEdited) {
      navigate('/suppliers');
    }
  }, [isSupplierEdited, navigate]);

  const handleSubmit = (data) => {
    const payload = {
      ...data,
      company_name: data.company_name ? data.company_name : data.name,
      country_code: data.country_code.key,
      state: data.state.key,
      registration_type: data.registration_type.key,
      msic_code: data.msic_code.key,
    };

    if (data.participant_id) {
      const [identifier_code, rest] = data.participant_id.split(':');
      const [special_identifier, identifier] = rest.match(/(\d{2})(.*)/).slice(1);
      payload.peppol_participant = {
        identifier,
        special_identifier,
        identifier_code,
      };
    } else {
      payload.peppol_participant = {};
    }

    delete payload.participant_id;
    dispatch(editSupplierRequest(payload));
  };

  function handleCancel() {
    dispatch(cancelSupplierCreate());
    return navigate('/suppliers');
  }

  if (loading || !selectedSupplier) {
    return null;
  }

  return (
    <Stack direction="column" sx={{ mt: '-2rem' }}>
      <SupplierForm
        onSubmit={handleSubmit}
        onCancel={handleCancel}
        initialData={selectedSupplier}
        edit={true}
        apiErrors={error}
      ></SupplierForm>
    </Stack>
  );
}
