import React from 'react';
import { Paper, Box, Typography, Skeleton, Grid2 as Grid } from '@mui/material';

const SummaryBoxes = ({ summaryBoxes, loading }) => {
  return (
    <Grid container spacing={3} sx={{ mb: 4 }}>
      {summaryBoxes.map((box, index) => (
        <Grid size={{ xs: 12, md: 3 }} key={index}>
          <Paper
            elevation={3}
            sx={{
              p: 2,
              backgroundColor: box.color,
              height: '100%',
              transition: 'transform 0.2s',
              '&:hover': {
                transform: loading ? 'none' : 'translateY(-5px)',
                boxShadow: loading ? 3 : 6,
              },
            }}
          >
            <Box display="flex" alignItems="center" justifyContent="space-between">
              <Box>
                {loading ? (
                  <Skeleton variant="text" width={120} height={30} />
                ) : (
                  <Typography variant="subtitle1" color="text.secondary">
                    {box.title}
                  </Typography>
                )}
                {loading ? (
                  <Skeleton variant="text" width={80} height={50} sx={{ mt: 1 }} />
                ) : (
                  <Typography variant="h4" fontWeight="bold">
                    {box.value}
                  </Typography>
                )}
              </Box>
              {loading ? <Skeleton variant="circular" width={40} height={40} /> : box.icon}
            </Box>
          </Paper>
        </Grid>
      ))}
    </Grid>
  );
};

export default SummaryBoxes;
