import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import lightTheme from './theme';
import { fetchPdfTemplates } from './api/pdfTemplates/pdfTemplateSlice';
import { fetchCountryCodesRequest } from './api/constants/countryCodesSlice';
import { fetchMsicCodesRequest } from './api/constants/msicCodesSlice';
import { fetchStatesRequest } from './api/constants/statesSlice';
import { fetchOrgRequest } from './api/organizations/orgSlice';
import { fetchUomCodesRequest } from './api/constants/uomSlice';
import { fetchItemCodesRequest } from './api/constants/itemCodesSlice';
import { fetchTaxTypesRequest } from './api/constants/taxTypesSlice';
import { loadingPrerequisites } from './api/app/appStateSelector';
import { fetchPaymentTermsRequest } from './api/constants/paymentMeansSlice';
import { DashboardLayout, PageContainer } from '@toolpad/core';
import { Navigate, Route, Routes } from 'react-router-dom';
import { AppProvider } from '@toolpad/core/react-router-dom';
import Login from './Login';
import UserAccount from './components/UserAccount';
import logo from './assets/logo/marmin_logo.png';
import DescriptionIcon from '@mui/icons-material/Description';
import AuthenticatedRoutes from './routes/AuthenticatedRoutes';
import { BusinessCenterOutlined, DashboardOutlined } from '@mui/icons-material';
import PeopleOutlineOutlinedIcon from '@mui/icons-material/PeopleOutlineOutlined';
import CloudUploadOutlinedIcon from '@mui/icons-material/CloudUploadOutlined';
import SettingsIcon from '@mui/icons-material/Settings';
import EmailOutlinedIcon from '@mui/icons-material/EmailOutlined';
import BottomBar from './components/BottomBar/BottomBar';
import HourglassTopIcon from '@mui/icons-material/HourglassTop';
import Loader from './components/Loader';
import GlobalSnackbar from './components/GlobalSnackbar';

const NAVIGATION = [
  {
    segment: 'dashboard',
    title: 'MIS Dashboard',
    icon: <DashboardOutlined />,
  },
  {
    kind: 'divider',
  },
  {
    segment: 'business-profiles',
    title: 'Business Profiles',
    pattern: 'business-profiles{/:id}*',
    icon: <BusinessCenterOutlined />,
  },
  {
    kind: 'divider',
  },
  {
    kind: 'header',
    title: 'Sales',
  },
  {
    segment: 'customers',
    title: 'Customers',
    pattern: 'customers{/:id}*',
    icon: <PeopleOutlineOutlinedIcon />,
  },
  {
    segment: 'documents',
    title: 'Documents',
    icon: <DescriptionIcon />,
    children: [
      {
        segment: 'invoices',
        title: 'Invoices',
        pattern: 'invoices{/:id}*',
      },
      {
        segment: 'refunds',
        title: 'Refunds',
        pattern: 'refunds{/:id}*',
      },
      {
        segment: 'credit-notes',
        title: 'Credit Notes',
        pattern: 'credit-notes{/:id}*',
      },
      {
        segment: 'debit-notes',
        title: 'Debit Notes',
        pattern: 'debit-notes{/:id}*',
      },
    ],
  },
  {
    segment: 'drafts',
    title: 'Staged for Consolidation',
    icon: <HourglassTopIcon />,
  },
  {
    kind: 'divider',
  },
  {
    kind: 'header',
    title: 'Purchases',
  },
  {
    segment: 'suppliers',
    title: 'Suppliers',
    icon: <PeopleOutlineOutlinedIcon />,
    pattern: 'suppliers{/:id}*',
  },
  {
    segment: 'self-billed-documents',
    title: 'Self Billed Documents',
    icon: <DescriptionIcon />,
    children: [
      {
        segment: 'invoices',
        title: 'Invoices',
        pattern: 'invoices{/:id}*',
      },
      {
        segment: 'refunds',
        title: 'Refunds',
        pattern: 'refunds{/:id}*',
      },
      {
        segment: 'credit-notes',
        title: 'Credit Notes',
        pattern: 'credit-notes{/:id}*',
      },
      {
        segment: 'debit-notes',
        title: 'Debit Notes',
        pattern: 'debit-notes{/:id}*',
      },
    ],
  },
  {
    segment: 'self-billed-drafts',
    title: 'Staged for Consolidation',
    icon: <HourglassTopIcon />,
  },
  {
    kind: 'divider',
  },
  {
    segment: 'bulk-upload',
    title: 'Bulk Upload',
    icon: <CloudUploadOutlinedIcon />,
    pattern: 'bulk-upload{/:id}*',
  },
  {
    kind: 'divider',
  },
  {
    segment: 'peppol-messages',
    title: 'Incoming Peppol Messages',
    icon: <EmailOutlinedIcon />,
    pattern: 'peppol-messages{/:id}*',
  },
  {
    segment: 'settings',
    title: 'Settings',
    icon: <SettingsIcon />,
    pattern: 'settings',
  },
];

function App() {
  const isAuthenticated = useSelector((state) => state.auth.isAuthenticated);

  let loading = useSelector(loadingPrerequisites);

  const dispatch = useDispatch();

  useEffect(() => {
    if (isAuthenticated) {
      dispatch(fetchPdfTemplates());
      dispatch(fetchCountryCodesRequest({ search_key: 'country' }));
      dispatch(fetchMsicCodesRequest({ search_key: 'msic' }));
      dispatch(fetchUomCodesRequest({ search_key: 'uom' }));
      dispatch(fetchStatesRequest({ search_key: 'state' }));
      dispatch(fetchItemCodesRequest({ search_key: 'item_code' }));
      dispatch(fetchTaxTypesRequest({ search_key: 'tax_type' }));
      dispatch(fetchPaymentTermsRequest({ search_key: 'payment_means' }));
      dispatch(fetchOrgRequest());
    }
  }, [dispatch, isAuthenticated]);

  if (loading) {
    return <Loader />;
  }
  if (isAuthenticated) {
    return (
      <AppProvider theme={lightTheme}>
        <DashboardLayout
          slots={{ toolbarAccount: UserAccount }}
          navigation={NAVIGATION}
          branding={{
            title: '',
            homeUrl: '/documents/invoices',
            logo: <img src={logo} alt="Marmin logo" />,
          }}
        >
          <PageContainer maxWidth={'xxl'}>
            <Routes>
              <Route path="*" element={<Navigate to="/login" />} />
              <Route path="/" element={<Navigate to={isAuthenticated ? `/documents/invoices` : '/login'} replace />} />
              <Route path="/login" element={<Login redirectTo={`/documents/invoices`} />} />
              {isAuthenticated && AuthenticatedRoutes()}
            </Routes>
          </PageContainer>
          <BottomBar />
        </DashboardLayout>
        <Loader />
        <GlobalSnackbar />
      </AppProvider>
    );
  } else {
    return (
      <AppProvider>
        <Login redirectTo={'documents/invoices'} />
      </AppProvider>
    );
  }
}

export default App;
