import * as Yup from 'yup';

export const SupplierValidation = Yup.object().shape({
  name: Yup.string().required('Name is required'),
  company_name: Yup.string(),
  company_name_in_local: Yup.string(),
  email: Yup.string().oneOf([Yup.string().email(), 'NA']).required('Email is required'),
  address1: Yup.string().required('Address line 1 is required'),
  address2: Yup.string(),
  address3: Yup.string(),
  city: Yup.string().required('City is required'),
  country_code: Yup.object().required('Country is required'),
  state: Yup.object().required('State is required'),
  postal_code: Yup.string(),
  phone: Yup.string().required('Phone is required'),
  registration_type: Yup.object().required('Registration Type is required'),
  registration_number: Yup.string().required('Registration Number is required'),
  tin: Yup.string().required('TIN is required'),
  sst_registration_number: Yup.string(),
  tourism_tax_registration_number: Yup.string(),
  msic_code: Yup.object().required('MSIC Code is required'),
});
