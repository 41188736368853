import React from 'react';
import { PieChart, Pie, Cell, Legend } from 'recharts';
import theme from '../../../theme';

export const COLORS = {
  Accepted: `${theme.palette.success.main}`,
  Rejected: `${theme.palette.error.main}`,
  Submitted: `${theme.palette.info.main}`,
  Cancelled: `${theme.palette.grey[500]}`,
};

const renderCustomizedLabel = ({ cx, cy, midAngle, outerRadius, percent, name, value }) => {
  if (value === 0) return null;

  const RADIAN = Math.PI / 180;
  const x = cx + (outerRadius + 30) * Math.cos(-midAngle * RADIAN);
  const y = cy + (outerRadius + 30) * Math.sin(-midAngle * RADIAN);

  return (
    <text x={x} y={y} fill="black" textAnchor={x > cx ? 'start' : 'end'} dominantBaseline="central">
      {`${name}: ${value} (${(percent * 100).toFixed(1)}%)`}
    </text>
  );
};

const PieGraph = ({ data }) => {
  const chartData = [
    { name: 'Accepted', value: data.accepted },
    { name: 'Rejected', value: data.rejected },
    { name: 'Submitted', value: data.submitted },
    { name: 'Cancelled', value: data.cancelled },
  ].filter((entry) => entry.value > 0);

  return (
    <div className="flex flex-col items-center bg-white shadow-md rounded-2xl p-4">
      <PieChart width={590} height={400}>
        <Pie
          data={chartData}
          cx="50%"
          cy="50%"
          innerRadius={0}
          outerRadius={100}
          paddingAngle={1}
          dataKey="value"
          label={renderCustomizedLabel}
          labelLine={true}
        >
          {chartData.map((entry) => (
            <Cell key={entry.name} fill={COLORS[entry.name]} />
          ))}
        </Pie>
        <Legend />
      </PieChart>
    </div>
  );
};

export default PieGraph;
