import React from 'react';
import { Divider, Grid2 as Grid, Typography } from '@mui/material';
import { currencyFormatter } from '../utils/currencyFormat';
import NumberOrPercentageField from './NumberOrPercentageField';
import EditInvoicePrePaymentButton from '../resources/Invoices/components/EditInvoicePrePaymentButton';
import formatCurrencyWords from '../utils/formatCurrencyWords';

const InvoiceSummarySection = ({
  subtotal,
  totalItemDiscount,
  totalItemCharges,
  totalExcludingTax,
  totalIncludingTax,
  totalTaxAmount,
  grandTotal,
  charges,
  discounts,
  prePayment,
  documentCurrencyCode,
  readOnly,
  handleChargeEdit,
  handleDiscountEdit,
  chargeRemove,
  discountRemove,
  addPrePayment,
  getValues,
  watch,
}) => {
  return (
    <Grid container spacing={1.5}>
      <Grid size={6}>
        <Typography>Subtotal</Typography>
      </Grid>
      <Grid size={2}>
        <Typography>{documentCurrencyCode}</Typography>
      </Grid>
      <Grid size={4} style={{ textAlign: 'right' }}>
        <span>{currencyFormatter().format(subtotal)}</span>
      </Grid>

      <Grid size={6}>
        <Typography>Total Item Discount</Typography>
      </Grid>
      <Grid size={2}>
        <Typography>{documentCurrencyCode}</Typography>
      </Grid>
      <Grid size={4} style={{ textAlign: 'right' }}>
        <Typography variant="body1" color="error">
          -{currencyFormatter().format(totalItemDiscount)}
        </Typography>
      </Grid>

      <Grid size={6}>
        <Typography>Total Item Charge</Typography>
      </Grid>
      <Grid size={2}>
        <Typography>{documentCurrencyCode}</Typography>
      </Grid>
      <Grid size={4} style={{ textAlign: 'right' }}>
        <span>{currencyFormatter().format(totalItemCharges)}</span>
      </Grid>

      <Grid size={6}>
        <Typography sx={{ fontWeight: 'bold' }}>Total Excluding Tax</Typography>
      </Grid>
      <Grid size={2}>{documentCurrencyCode}</Grid>
      <Grid size={4} style={{ textAlign: 'right' }}>
        <span style={{ fontWeight: 'bold' }}>{currencyFormatter().format(totalExcludingTax)}</span>
      </Grid>

      <Grid size={12}>
        <Divider />
      </Grid>

      <Grid size={6}>
        <Typography>Total Tax Amount</Typography>
      </Grid>
      <Grid size={2}>{documentCurrencyCode}</Grid>
      <Grid size={4} style={{ textAlign: 'right' }}>
        <span>{currencyFormatter().format(totalTaxAmount)}</span>
      </Grid>

      <Grid size={6}>
        <Typography sx={{ fontWeight: 'bold' }}>Total Including Tax</Typography>
      </Grid>
      <Grid size={2}>{documentCurrencyCode}</Grid>
      <Grid size={4} style={{ textAlign: 'right' }}>
        <span style={{ fontWeight: 'bold' }}>{currencyFormatter().format(totalIncludingTax)}</span>
      </Grid>

      <Grid size={12}>
        <Divider />
      </Grid>

      {charges.length > 0 && (
        <NumberOrPercentageField
          label="Document-Level Charge"
          value={charges[0]}
          currencyCode={documentCurrencyCode}
          type={charges[0]?.type}
          readOnly={readOnly}
          onEdit={handleChargeEdit}
          onDelete={() => chargeRemove(0)}
        />
      )}

      {discounts.length > 0 && (
        <NumberOrPercentageField
          label="Document-Level Discount"
          value={discounts[0]}
          currencyCode={documentCurrencyCode}
          type={discounts[0]?.type}
          readOnly={readOnly}
          onEdit={handleDiscountEdit}
          onDelete={() => discountRemove(0)}
        />
      )}

      {prePayment?.amount > 0 && (
        <NumberOrPercentageField
          label="Pre Payment Amount"
          value={prePayment}
          currencyCode={documentCurrencyCode}
          type="amount"
          readOnly={readOnly}
          onDelete={() => addPrePayment({ amount: 0 })}
          editComponent={() => (
            <EditInvoicePrePaymentButton
              addPrePayment={addPrePayment}
              initialValue={getValues('pre_payment')}
              readOnly={readOnly}
            />
          )}
        />
      )}

      <Grid size={6}>
        <Typography variant="h6">Grand Total</Typography>
      </Grid>
      <Grid size={2}>
        <Typography variant="h6">{documentCurrencyCode}</Typography>
      </Grid>
      <Grid size={4} style={{ textAlign: 'right' }}>
        <Typography variant="h6"> {currencyFormatter().format(grandTotal)}</Typography>
      </Grid>

      <Grid size={12}>
        <Divider />
      </Grid>

      <Grid item size={12} style={{ textAlign: 'right' }}>
        <Typography variant="body1">{formatCurrencyWords(grandTotal, documentCurrencyCode)}</Typography>
      </Grid>
    </Grid>
  );
};

export default InvoiceSummarySection;
