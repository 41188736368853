import React from 'react';
import { Grid, TextField, MenuItem } from '@mui/material';
import { Controller } from 'react-hook-form';

export const specialIdentifiers = [
  { value: '01', label: 'SSM Number' },
  { value: '02', label: 'Sabah' },
  { value: '03', label: 'Sarawak' },
  { value: '04', label: 'Foreign Businesses' },
  { value: '05', label: 'Testing Purposes' },
  { value: '06', label: 'Others' },
  { value: '07', label: 'Statutory Body' },
  { value: '08', label: 'Local Authority' },
];

const ParticipantIdentifier = ({ control, errors, readonly }) => {
  return (
    <Grid container spacing={2} alignItems="center">
      <Grid item xs={12} sm={3}>
        Participant identifier:
      </Grid>
      <Grid item xs={12} sm={9} display="flex" gap={2}>
        <TextField
          label="Identifier Code"
          name="identifier_code"
          value="0230"
          fullWidth
          sx={{ backgroundColor: '#fbf8f8' }}
          disabled
        />
        <Controller
          name="special_identifier"
          control={control}
          render={({ field }) => (
            <TextField
              {...field}
              select
              label="Special Identifier"
              fullWidth
              error={!!errors.special_identifier}
              helperText={errors.special_identifier?.message}
              InputProps={{ readOnly: readonly }}
            >
              {specialIdentifiers.map((option) => (
                <MenuItem key={option.value} value={option.value}>
                  ({option.value}) - {option.label}
                </MenuItem>
              ))}
            </TextField>
          )}
        />
        <Controller
          name="identifier"
          control={control}
          render={({ field }) => (
            <TextField
              {...field}
              label="Identifier"
              fullWidth
              error={!!errors.identifier}
              helperText={errors.identifier?.message}
              InputProps={{ readOnly: readonly }}
            />
          )}
        />
      </Grid>
    </Grid>
  );
};

export default ParticipantIdentifier;
