import React from 'react';
import { Autocomplete, TextField } from '@mui/material';
import { Controller } from 'react-hook-form';
import { useSelector } from 'react-redux';

const ItemCodeSelect = ({ name, label = 'Item Classification Code', control, errors, readOnly = false }) => {
  const itemCodes = useSelector((state) => state.itemCodes.itemCodes);
  const itemCodesOptions = itemCodes.map((item) => {
    return { key: item.Code, value: item.Description };
  });

  return (
    <Controller
      name={name}
      control={control}
      render={({ field }) => (
        <Autocomplete
          {...field}
          options={itemCodesOptions}
          name={name}
          readOnly={readOnly}
          getOptionLabel={(option) => option.value}
          renderInput={(params) => (
            <TextField {...params} label={label} error={!!errors[name]} helperText={errors[name]?.message} />
          )}
          isOptionEqualToValue={(option, value) => option.value === value.value}
          onChange={(_, value) => field.onChange(value)}
          value={field.value || null} // handle default value
        />
      )}
    />
  );
};

export default ItemCodeSelect;
