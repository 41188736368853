import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  refunds: [],
  currentPage: 1,
  selectedRefund: null,
  totalPages: 1,
  totalDocuments: 0,
  isRefundCreated: false,
  isRefundCancelled: false,
  loading: false,
  error: null,
};

const refundsSlice = createSlice({
  name: 'refunds',
  initialState,
  reducers: {
    fetchRefundsRequest(state) {
      state.loading = true;
      state.error = null;
    },
    fetchRefundsSuccess(state, action) {
      state.loading = false;
      state.refunds = action.payload.refunds;
      state.currentPage = action.payload.currentPage;
      state.totalPages = action.payload.totalPages;
      state.totalDocuments = action.payload.totalDocuments;
    },
    fetchRefundsFailure(state, action) {
      state.loading = false;
      state.error = action.payload;
    },
    fetchRefundById(state, action) {
      state.loading = true;
      state.error = null;
    },
    clearSelectedRefund(state) {
      state.selectedRefund = null;
    },
    fetchRefundByIdSuccess(state, action) {
      state.loading = false;
      state.selectedRefund = action.payload;
    },
    fetchRefundByIdFailure(state, action) {
      state.loading = false;
      state.error = action.payload;
    },
    createRefundRequest(state) {
      state.loading = true;
      state.error = null;
    },
    createRefundSuccess(state, action) {
      state.loading = false;
      state.error = null;
      state.isRefundCreated = true;
    },
    createRefundFailure(state, action) {
      state.loading = false;
      state.error = action.payload;
    },
    refundEDocRequest(state) {
      state.loading = true;
      state.error = null;
    },
    refundEDocSuccess(state, action) {
      state.loading = false;
      state.error = null;
      state.isRefundCreated = true;
    },
    refundEDocFailure(state, action) {
      state.loading = false;
      state.error = action.payload;
    },
    markRefundCreated(state, action) {
      state.isRefundCreated = false;
    },
    cancelRefundRequest(state) {
      state.loading = true;
      state.error = null;
    },
    cancelRefundSuccess(state, action) {
      state.loading = false;
      state.error = null;
      state.isRefundCancelled = true;
    },
    cancelRefundFailure(state, action) {
      state.loading = false;
      state.error = action.payload;
    },
    markRefundCancelled(state, action) {
      state.isRefundCancelled = false;
    },
  },
});

export const {
  fetchRefundsRequest,
  fetchRefundsSuccess,
  fetchRefundsFailure,
  fetchRefundById,
  fetchRefundByIdSuccess,
  fetchRefundByIdFailure,
  createRefundRequest,
  createRefundSuccess,
  createRefundFailure,
  refundEDocRequest,
  refundEDocSuccess,
  refundEDocFailure,
  markRefundCreated,
  cancelRefundRequest,
  cancelRefundSuccess,
  cancelRefundFailure,
  markRefundCancelled,
  clearSelectedRefund,
} = refundsSlice.actions;

export default refundsSlice.reducer;
