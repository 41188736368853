import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { fetchDashboardRequest } from '../../../api/dashboard/dashboardSlice';
import TimeRangeSelector from './TimeRangeSelector';
import SummaryBoxes from './SummaryBoxes';
import ComplianceCard from './ComplianceCard';
import { Typography, Box, Grid2 as Grid, Paper } from '@mui/material';
import {
  DescriptionOutlined as DocumentIcon,
  CheckCircleOutline as AcceptedIcon,
  CancelOutlined as RejectedIcon,
  BlockOutlined as CancelledIcon,
} from '@mui/icons-material';
import theme from '../../../theme';
import { lighten } from '@mui/material/styles';

const ComplianceSection = () => {
  const dispatch = useDispatch();
  const { data, loading, error } = useSelector((state) => state.dashboard);
  const [timeRange, setTimeRange] = useState('week');
  const [filteredData, setFilteredData] = useState(null);

  useEffect(() => {
    const { startDate, endDate } = getDateRange(timeRange);
    dispatch(fetchDashboardRequest({ startDate, endDate }));
  }, [timeRange, dispatch]);

  useEffect(() => {
    if (data) {
      const filtered = filterDataByTimeRange(data, timeRange);
      setFilteredData(filtered);
    }
  }, [data, timeRange]);

  const handleTimeRangeChange = (event) => {
    setTimeRange(event.target.value);
  };

  const getDateRange = (range) => {
    const now = new Date();
    const endDate = now.toISOString().split('T')[0];

    let startDate = new Date(now);

    switch (range) {
      case 'week':
        startDate.setDate(now.getDate() - 7);
        break;
      case 'month':
        startDate.setMonth(now.getMonth() - 1);
        break;
      case 'quarter':
        startDate.setMonth(now.getMonth() - 3);
        break;
      default:
        startDate.setDate(now.getDate() - 7);
    }

    startDate = startDate.toISOString().split('T')[0];

    return { startDate, endDate };
  };

  const filterDataByTimeRange = (data) => {
    if (!data) return data;

    const filterComplianceData = (complianceData) => {
      return {
        ...complianceData,
        total: complianceData.total,
        accepted: complianceData.accepted,
        rejected: complianceData.rejected,
        submitted: complianceData.submitted,
        cancelled: complianceData.cancelled,
      };
    };

    return {
      sales_compliance: filterComplianceData(data.sales_compliance),
      purchase_compliance: filterComplianceData(data.purchase_compliance),
    };
  };

  if (error)
    return (
      <Paper elevation={3} sx={{ p: 3, bgcolor: '#fff4f4' }}>
        <Typography color="error" variant="h6">
          Error: {error}
        </Typography>
      </Paper>
    );

  if (!filteredData) return null;

  const totalDocuments = filteredData.sales_compliance.total + filteredData.purchase_compliance.total;
  const totalAccepted = filteredData.sales_compliance.accepted + filteredData.purchase_compliance.accepted;
  const totalRejected = filteredData.sales_compliance.rejected + filteredData.purchase_compliance.rejected;
  const totalCancelled = filteredData.sales_compliance.cancelled + filteredData.purchase_compliance.cancelled;

  const summaryBoxes = [
    {
      title: 'Total Documents',
      value: totalDocuments,
      icon: <DocumentIcon sx={{ fontSize: 40, color: `${theme.palette.info.main}` }} />,
      color: lighten(theme.palette.info.main, 0.8),
    },
    {
      title: 'Accepted Documents',
      value: totalAccepted,
      icon: <AcceptedIcon sx={{ fontSize: 40, color: `${theme.palette.success.main}` }} />,
      color: lighten(theme.palette.success.main, 0.88),
    },
    {
      title: 'Rejected Documents',
      value: totalRejected,
      icon: <RejectedIcon sx={{ fontSize: 40, color: `${theme.palette.error.main}` }} />,
      color: lighten(theme.palette.error.main, 0.88),
    },
    {
      title: 'Cancelled Documents',
      value: totalCancelled,
      icon: <CancelledIcon sx={{ fontSize: 40, color: `${theme.palette.grey[500]}` }} />,
      color: `${theme.palette.grey[100]}`,
    },
  ];

  return (
    <Box sx={{ p: 2 }}>
      <Typography variant="h5" gutterBottom sx={{ mb: 3, fontWeight: 'bold' }}>
        Compliance Overview
      </Typography>
      <TimeRangeSelector timeRange={timeRange} handleTimeRangeChange={handleTimeRangeChange} loading={loading} />
      <SummaryBoxes summaryBoxes={summaryBoxes} loading={loading} />
      <Grid container spacing={3}>
        <Grid size={{ xs: 12, md: 6 }}>
          <ComplianceCard title="Sales Documents" data={filteredData.sales_compliance} loading={loading} />
        </Grid>
        <Grid size={{ xs: 12, md: 6 }}>
          <ComplianceCard title="Purchase Documents" data={filteredData.purchase_compliance} loading={loading} />
        </Grid>
      </Grid>
    </Box>
  );
};

export default ComplianceSection;
