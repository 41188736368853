import React, { useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { fetchCustomerById } from '../../../api/customers/customersSlice';
import { Box, Stack } from '@mui/material';
import '../../../styles/DocumentDetails.css';
import TopBar from '../../../components/TopBar';
import CustomerDetailsPageActionButtons from '../components/CustomerDetailsPageActionButtons';
import useDocumentTitle from '../../../hooks/useDocumentTitle';
import CustomerForm from '../components/CustomerForm';

export default function CustomerDetailsPage() {
  useDocumentTitle('Customer Details');
  const { profile_id } = useParams();
  const dispatch = useDispatch();
  const customer = useSelector((state) => state.customers.selectedCustomer);

  useEffect(() => {
    if (profile_id) {
      dispatch(fetchCustomerById(profile_id));
    }
  }, [dispatch, profile_id]);

  if (!customer) return null;

  return (
    <Stack direction="column" sx={{ mt: '-1rem' }}>
      {customer && (
        <Box sx={{ pb: 4 }}>
          <TopBar title="Customer Details" subTitle={customer?.profile_id}>
            <CustomerDetailsPageActionButtons />
          </TopBar>
        </Box>
      )}

      <CustomerForm
        onSubmit={() => {}}
        apiErrors={{}}
        initialData={customer}
        readOnly={true}
        edit={false}
        onCancel={() => {}}
      />
    </Stack>
  );
}
