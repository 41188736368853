import React, { useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { Box, Stack } from '@mui/material';
import '../../../styles/DocumentDetails.css';
import TopBar from '../../../components/TopBar';
import { clearSelectedRefund, fetchRefundById } from '../../../api/refunds/refundsSlice';
import useDocumentTitle from '../../../hooks/useDocumentTitle';
import RefundDetailsPageActionButton from '../components/RefundDetailsPageActionButton';
import RefundForm from '../components/RefundForm';
import IrbmStatusCard from '../../common/IrbmStatusCard';

export default function RefundDetailsPage() {
  useDocumentTitle('Refund Details');
  const { document_id } = useParams();
  const dispatch = useDispatch();
  const refund = useSelector((state) => state.refunds.selectedRefund);

  useEffect(() => {
    if (document_id) {
      dispatch(fetchRefundById(document_id));
    }
    //The function which is returned by useEffect will act as cleanup of what useEffect does
    return () => {
      dispatch(clearSelectedRefund());
    };
  }, [dispatch, document_id]);
  if (!refund) return null;

  return (
    <Stack direction="column" sx={{ mt: '-1rem' }}>
      {refund && (
        <Box sx={{ pb: 4 }}>
          <TopBar title="Refund Details" subTitle={refund?.document_id}>
            <RefundDetailsPageActionButton />
          </TopBar>
        </Box>
      )}

      <IrbmStatusCard document={refund} />
      <RefundForm onSubmit={() => {}} onCancel={() => {}} initialData={refund} readOnly={true} />
    </Stack>
  );
}
