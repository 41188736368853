import React, { useState } from 'react';
import { Button, Dialog, DialogActions, DialogContent, DialogTitle, Divider } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import useSubscriptionCheck from '../../common/SubscriptionCheck';
import InvoiceSelect from '../../../components/form/InvoiceSelect';

function RefundListPageActions({ actions }) {
  const navigate = useNavigate();
  const [open, setOpen] = useState(false);
  const [searchTerm, setSearchTerm] = useState('');
  const [action, setAction] = useState(null);
  const [selectedInvoice, setSelectedInvoice] = useState(null);
  const { checkSubscription, SubscriptionModal } = useSubscriptionCheck();

  const handleAction = (action) => {
    if (action.action === 'create' && !checkSubscription()) {
      return;
    }

    switch (action.action) {
      case 'create':
        setOpen(true);
        setAction(action);
        break;
      default:
        console.log('Unknown action');
    }
  };

  const handleClose = () => {
    setOpen(false);
    setAction(null);
  };

  const handleContinue = () => {
    if (selectedInvoice) {
      navigate('/documents/refunds/create', { state: { invoiceData: selectedInvoice } });
    }
  };

  return (
    <>
      {actions.map((action, index) => (
        <Button key={index} variant="contained" color="primary" onClick={() => handleAction(action)}>
          + Raise Refund
        </Button>
      ))}

      <Dialog open={open} onClose={handleClose}>
        <DialogTitle>Select Invoice</DialogTitle>
        <Divider />
        <DialogContent>Select an invoice from the dropdown to continue.</DialogContent>
        <DialogContent>
          <InvoiceSelect
            onSelect={setSelectedInvoice}
            document_type={'01'}
            searchTerm={searchTerm}
            setSearchTerm={setSearchTerm}
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose} variant="outlined">
            Cancel
          </Button>
          <Button onClick={handleContinue} variant="contained" disabled={!selectedInvoice}>
            Continue
          </Button>
        </DialogActions>
      </Dialog>
      <SubscriptionModal />
    </>
  );
}

export default RefundListPageActions;
