import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  org: {},
  settings: null,
  loading: false,
  error: null,
  loadingSettings: false,
  errorSettings: null,
};

const orgSlice = createSlice({
  name: 'org',
  initialState,
  reducers: {
    fetchOrgRequest: (state) => {
      state.loading = true;
      state.error = null;
    },
    fetchOrgSuccess: (state, action) => {
      state.loading = false;
      state.error = null;
      state.org = action.payload;
    },
    fetchOrgFailure: (state, action) => {
      state.loading = false;
      state.error = action.payload;
      state.org = initialState.org;
    },
    fetchOrgSettingsRequest: (state) => {
      state.loadingSettings = true;
      state.errorSettings = null;
    },
    fetchOrgSettingsSuccess: (state, action) => {
      state.loadingSettings = false;
      state.errorSettings = null;
      state.settings = action.payload;
    },
    fetchOrgSettingsFailure: (state, action) => {
      state.loadingSettings = false;
      state.errorSettings = action.payload;
    },

    updateOrgSettingsRequest: (state) => {
      state.loadingSettings = true;
      state.errorSettings = null;
    },
    updateOrgSettingsSuccess: (state, action) => {
      state.loadingSettings = false;
      state.settings = action.payload;
    },
    updateOrgSettingsFailure: (state, action) => {
      state.loadingSettings = false;
      state.errorSettings = action.payload;
    },
  },
});

export const {
  fetchOrgRequest,
  fetchOrgFailure,
  fetchOrgSuccess,
  fetchOrgSettingsRequest,
  fetchOrgSettingsSuccess,
  fetchOrgSettingsFailure,
  updateOrgSettingsRequest,
  updateOrgSettingsSuccess,
  updateOrgSettingsFailure,
} = orgSlice.actions;

export default orgSlice.reducer;
