import Fade from '@mui/material/Fade';
import Box from '@mui/material/Box';
import { Divider, IconButton, Typography } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import Modal from '@mui/material/Modal';
import * as React from 'react';
import LineItemForm from './LineItemForm';

const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: '800px',
  bgcolor: 'background.paper',
  borderRadius: '15px',
  boxShadow: 24,
};

export default function ItemFormModal({ open, handleClose, handleSubmit, initialData }) {
  return (
    <Modal
      open={open}
      onClose={(event, reason) => {
        if (reason !== 'backdropClick') {
          handleClose();
        }
      }}
      className={'backdrop-model'}
    >
      <Fade in={open}>
        <Box sx={style}>
          <Box
            sx={{
              display: 'flex',
              justifyContent: 'space-between',
              alignItems: 'center',
              px: 2,
              py: 2,
              borderBottom: '1px solid #e0e0e0',
            }}
          >
            <Typography variant="h6" sx={{ ml: '1.5rem' }}>
              New line Item
            </Typography>
            <IconButton onClick={handleClose}>
              <CloseIcon />
            </IconButton>
          </Box>
          <Divider />
          <LineItemForm submitForm={handleSubmit} handleClose={handleClose} initialData={initialData} />
        </Box>
      </Fade>
    </Modal>
  );
}
