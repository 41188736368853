import { call, put, takeLatest } from 'redux-saga/effects';
import { apiClient } from '../../utils/axios';
import {
  createBusinessProfileFailure,
  createBusinessProfileRequest,
  createBusinessProfileSuccess,
  editBusinessProfileFailure,
  editBusinessProfileRequest,
  editBusinessProfileSuccess,
  fetchBusinessProfileById,
  fetchBusinessProfileByIdFailure,
  fetchBusinessProfileByIdSuccess,
  fetchBusinessProfilesFailure,
  fetchBusinessProfilesRequest,
  fetchBusinessProfilesSuccess,
} from './businessProfilesSlice';
import { showSnackbar } from '../snackbarSlice';

function* fetchBusinessProfilesSaga(action) {
  try {
    const { query } = action.payload;
    const response = yield call(apiClient.get, '/business-profiles', { params: query });
    yield put(fetchBusinessProfilesSuccess(response.data));
  } catch (error) {
    yield put(fetchBusinessProfilesFailure(error.message));
  }
}

function* fetchBusinessProfileByIdSaga(action) {
  try {
    const response = yield call(apiClient.get, `/business-profiles/${action.payload}`);
    yield put(fetchBusinessProfileByIdSuccess(response.data));
  } catch (error) {
    yield put(fetchBusinessProfileByIdFailure(error.message));
  }
}

function* editBusinessProfileRequestSaga(action) {
  try {
    const { _id, __v, ...sanitizedPayload } = action.payload;
    const response = yield call(apiClient.put, `/business-profiles/${sanitizedPayload.profile_id}`, sanitizedPayload);
    yield put(editBusinessProfileSuccess(response.data));
  } catch (error) {
    yield put(editBusinessProfileFailure(error.response.data.error));
  }
}

function* createBusinessProfileSaga(action) {
  try {
    const response = yield call(apiClient.post, '/business-profiles', action.payload);
    yield put(createBusinessProfileSuccess(response.data));
    yield put(showSnackbar({ message: 'Business Profile created successfully!', severity: 'success' }));
  } catch (error) {
    yield put(createBusinessProfileFailure(error.response.data.errors));
  }
}

function* businessProfilesSaga() {
  yield takeLatest(fetchBusinessProfilesRequest.type, fetchBusinessProfilesSaga);
  yield takeLatest(fetchBusinessProfileById.type, fetchBusinessProfileByIdSaga);
  yield takeLatest(createBusinessProfileRequest.type, createBusinessProfileSaga);
  yield takeLatest(editBusinessProfileRequest.type, editBusinessProfileRequestSaga);
}

export default businessProfilesSaga;
