import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import CustomerListFilters from '../components/CustomerListFilters';
import {
  fetchCustomersRequest,
  markCustomerCreated,
  markCustomerEdited,
  resetSelectedCustomer,
} from '../../../api/customers/customersSlice';
import CustomerListPageActions from '../components/CustomerListPageActions';
import useDocumentTitle from '../../../hooks/useDocumentTitle';
import CustomersTable from '../components/CustomersTable';
import { Stack } from '@mui/material';
import TopBar from '../../../components/TopBar';

const filters = [
  {
    type: 'search',
    options: [
      {
        key: 'name',
        placeholder: 'Name',
      },
      {
        key: 'email',
        placeholder: 'Email',
      },
    ],
  },
];

function CustomerListPage() {
  useDocumentTitle('Customer List');
  const dispatch = useDispatch();
  const [selectedFilter, setSelectedFilter] = useState(filters.length > 0 ? filters[0].options[0].key : '');
  const [searchTerm, setSearchTerm] = useState('');
  const [currentQuery, setCurrentQuery] = useState({});

  useEffect(() => {
    dispatch(markCustomerCreated(false));
    dispatch(markCustomerEdited(false));
    dispatch(resetSelectedCustomer());
    dispatch(fetchCustomersRequest({ url: '/customers', page: 1, limit: 10 }));
  }, [dispatch]);

  const handleFilterChange = (event) => {
    setSelectedFilter(event.target.value);
    const searchBy = event.target.value;
    const query = { page: 1, limit: 10 };
    if (searchTerm) {
      query[searchBy] = searchTerm;
    }
    dispatch(fetchCustomersRequest({ query }));
  };

  const handleSearchChange = (event) => setSearchTerm(event.target.value);

  const handleSearchKeyPress = (event) => {
    const query = { page: 1, limit: 10 };
    if (searchTerm) {
      query[selectedFilter] = searchTerm;
    }
    if (event.key === 'Enter') {
      setCurrentQuery(query);
      dispatch(
        fetchCustomersRequest({
          query,
        }),
      );
    }
  };

  return (
    <Stack direction="column" sx={{ mt: '-1rem' }}>
      <TopBar title="Customers">
        <CustomerListPageActions />
      </TopBar>
      <CustomerListFilters
        filters={filters}
        selectedFilter={selectedFilter}
        onFilterChange={handleFilterChange}
        searchTerm={searchTerm}
        onSearchChange={handleSearchChange}
        onSearchKeyPress={handleSearchKeyPress}
      />
      <CustomersTable searchQuery={currentQuery} />
    </Stack>
  );
}

export default CustomerListPage;
