export const getStateName = (code, states) => {
  const state = states.find((state) => state.Code === code);
  return state ? state.State : code;
};

export const getCountryName = (code, countryCodes) => {
  const country = countryCodes.find((country) => country.Code === code);
  return country ? country.Country : code;
};

const sourceMapping = {
  api: 'API',
  web: 'WEB',
  sftp: 'Bulk Upload - SFTP',
  bulk_upload: 'Bulk Upload - WebApp',
};

export const formatSource = (value) => {
  if (sourceMapping[value]) {
    return sourceMapping[value];
  }
  return value
    ?.split('_')
    .map((word) => word.charAt(0).toUpperCase() + word.slice(1))
    .join(' ');
};
