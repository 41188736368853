import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  participant: {},
  endpoint_id: '',
  loading: false,
  error: null,
  activeStep: 0,
  participantId: null,
};

const participantsSlice = createSlice({
  name: 'participants',
  initialState,
  reducers: {
    fetchParticipantRequest(state) {
      state.loading = true;
      state.error = null;
    },
    fetchParticipantSuccess(state, action) {
      state.loading = false;
      state.participant = action.payload;
    },
    fetchParticipantFailure(state, action) {
      state.loading = false;
      state.error = action.payload;
    },
    fetchEndpointIdRequest(state) {
      state.loading = true;
      state.error = null;
    },
    fetchEndpointIdSuccess(state, action) {
      state.loading = false;
      state.endpoint_id = action.payload;
    },
    fetchEndpointIdFailure(state, action) {
      state.loading = false;
      state.error = action.payload;
    },
    createParticipantRequest(state) {
      state.loading = true;
      state.error = null;
    },
    createParticipantSuccess(state, action) {
      state.loading = false;
    },
    createParticipantFailure(state, action) {
      state.loading = false;
      state.error = action.payload;
    },
    kycSignRequest(state) {
      state.loading = true;
      state.error = null;
    },
    kycSignSuccess(state) {
      state.loading = false;
    },
    kycSignFailure(state, action) {
      state.loading = false;
      state.error = action.payload;
    },
    activateSmlRequest(state) {
      state.loading = true;
      state.error = null;
    },
    activateSmlSuccess(state) {
      state.loading = false;
    },
    activateSmlFailure(state, action) {
      state.loading = false;
      state.error = action.payload;
    },
    activatePeppolDirectoryRequest(state) {
      state.loading = true;
      state.error = null;
    },
    activatePeppolDirectorySuccess(state) {
      state.loading = false;
    },
    activatePeppolDirectoryFailure(state, action) {
      state.loading = false;
      state.error = action.payload;
    },
    resetParticipant(state, action) {
      state.loading = false;
      state.participant = {};
    },
    setActiveStep(state, action) {
      const { profileId, step } = action.payload;
      state.activeStep = step;
      localStorage.setItem(`${profileId}`, step);
    },
    setParticipantId(state, action) {
      const { profileId, participantId } = action.payload;
      state.participantId = participantId;
      localStorage.setItem(`${profileId}`, participantId);
    },
  },
});

export const {
  fetchParticipantRequest,
  fetchParticipantSuccess,
  fetchParticipantFailure,
  fetchEndpointIdRequest,
  fetchEndpointIdSuccess,
  fetchEndpointIdFailure,
  createParticipantRequest,
  createParticipantSuccess,
  createParticipantFailure,
  kycSignRequest,
  kycSignSuccess,
  kycSignFailure,
  activateSmlRequest,
  activateSmlSuccess,
  activateSmlFailure,
  activatePeppolDirectoryRequest,
  activatePeppolDirectorySuccess,
  activatePeppolDirectoryFailure,
  resetParticipant,
  setActiveStep,
  setParticipantId,
} = participantsSlice.actions;

export default participantsSlice.reducer;
