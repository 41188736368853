import * as Yup from 'yup';

const ImportExportValidation = Yup.object().shape({
  other_charges: Yup.number()
    .typeError('Amount must be a number') // Ensures it's a number
    .test('max-decimals', 'Amount must have at most 2 decimal places', (value) => {
      return /^\d+(\.\d{1,2})?$/.test(value); // Regular expression to check for max 2 decimal places
    }),
});

export { ImportExportValidation };
