import React from 'react';
import { Route } from 'react-router-dom';
import InvoiceListPage from '../resources/Invoices/pages/InvoiceListPage';
import InvoiceDetailsPage from '../resources/Invoices/pages/InvoiceDetailsPage';
import CreateInvoicePage from '../resources/Invoices/pages/CreateInvoicePage';
import CreditNoteListPage from '../resources/creditNotes/pages/CreditNoteListPage';
import CreditNoteDetailsPage from '../resources/creditNotes/pages/CreditNoteDetailsPage';
import RefundListPage from '../resources/refunds/pages/RefundListPage';
import RefundDetailsPage from '../resources/refunds/pages/RefundDetailsPage';
import DebitNoteListPage from '../resources/debitNotes/pages/DebitNoteListPage';
import DebitNoteDetailsPage from '../resources/debitNotes/pages/DebitNoteDetailsPage';
import CustomerDetailsPage from '../resources/customers/pages/CustomerDetailsPage';
import SBCreditNoteListPage from '../resources/selfBilledCreditNotes/pages/SBCreditNoteListPage';
import SBCreditNoteDetailsPage from '../resources/selfBilledCreditNotes/pages/SBCreditNoteDetailsPage';
import SBRefundDetailsPage from '../resources/selfBilledRefunds/pages/SBRefundDetailsPage';
import SBRefundListPage from '../resources/selfBilledRefunds/pages/SBRefundListPage';
import EditCustomerPage from '../resources/customers/pages/EditCustomerPage';
import CustomerListPage from '../resources/customers/pages/CustomerListPage';
import CreateCustomersPage from '../resources/customers/pages/CreateCustomersPage';
import SupplierListPage from '../resources/suppliers/pages/SupplierListPage';
import SupplierDetailsPage from '../resources/suppliers/pages/SupplierDetailsPage';
import EditSupplierPage from '../resources/suppliers/pages/EditSupplierPage';
import CreateSuppliersPage from '../resources/suppliers/pages/CreateSuppliersPage';
import SettingsPage from '../resources/settings/pages/SettingsPage';
import BulkUploadListPage from '../resources/bulkUpload/pages/BulkUploadListPage';
import CreateCreditNotePage from '../resources/creditNotes/pages/CreateCreditNotePage';
import CreateDebitNotePage from '../resources/debitNotes/pages/CreateDebitNotePage';
import CreateRefundPage from '../resources/refunds/pages/CreateRefundPage';
import SBInvoiceListPage from '../resources/selfBilledInvoices/pages/SBInvoiceListPage';
import CreateSBInvoicePage from '../resources/selfBilledInvoices/pages/CreateSBInvoicePage';
import SBInvoiceDetailsPage from '../resources/selfBilledInvoices/pages/SBInvoiceDetailsPage';
import CreateSBCreditNotePage from '../resources/selfBilledCreditNotes/pages/CreateSBCreditNotePage';
import SBDebitNoteListPage from '../resources/selfBilledDebitNotes/pages/SBDebitNoteListPage';
import SBDebitNoteDetailsPage from '../resources/selfBilledDebitNotes/pages/SBDebitNoteDetailsPage';
import CreateSBDebitNotePage from '../resources/selfBilledDebitNotes/pages/CreateSBDebitNotePage';
import CreateSBRefundPage from '../resources/selfBilledRefunds/pages/CreateSBRefundPage';
import BusinessProfileListPage from '../resources/businessProfiles/pages/BusinessProfileListPage';
import BusinessProfileDetailsPage from '../resources/businessProfiles/pages/BusinessProfileDetailsPage';
import EditBusinessProfilePage from '../resources/businessProfiles/pages/EditBusinessProfilePage';
import CreateBusinessProfilePage from '../resources/businessProfiles/pages/CreateBusinessProfilePage';
import DraftDocumentListPage from '../resources/draftDocuments/pages/DraftDocumentListPage';
import SBDraftDocumentListPage from '../resources/selfBilledDraftDocuments/pages/SBDraftDocumentListPage';
import DraftsInvoiceDetailsPage from '../resources/draftDocuments/pages/DraftsInvoiceDetailsPage';
import DraftsCreditNoteDetailsPage from '../resources/draftDocuments/pages/DraftsCreditNoteDetailsPage';
import DraftsDebitNoteDetailsPage from '../resources/draftDocuments/pages/DraftsDebitNoteDetailsPage';
import DraftsRefundDetailsPage from '../resources/draftDocuments/pages/DraftsRefundDetailsPage';
import SBDraftInvoiceDetailsPage from '../resources/selfBilledDraftDocuments/pages/SBDraftInvoiceDetailsPage';
import SBDraftRefundDetailsPage from '../resources/selfBilledDraftDocuments/pages/SBDraftRefundDetailsPage';
import SBDraftDebitNoteDetailsPage from '../resources/selfBilledDraftDocuments/pages/SBDraftDebitNoteDetailsPage';
import SBDraftCreditNoteDetailsPage from '../resources/selfBilledDraftDocuments/pages/SBDraftCreditNoteDetailsPage';
import DashboardPage from '../resources/dashboard/pages/dashboardPage';
import PeppolMessagesListPage from '../resources/peppolMessages/pages/PeppolMessagesListPage';

const AuthenticatedRoutes = () => {
  let configuredFeatures = [];
  configuredFeatures.push(
    <React.Fragment key={'dashboard'}>
      <Route path={`/dashboard`} element={<DashboardPage />} />
    </React.Fragment>,

    <React.Fragment key={'business-profiles'}>
      <Route path={`/business-profiles`} element={<BusinessProfileListPage />} />
      <Route path={`/business-profiles/:profile_id`} element={<BusinessProfileDetailsPage />} />
      <Route path={`/business-profiles/:profile_id/edit`} element={<EditBusinessProfilePage />} />
      <Route path={`/business-profiles/create`} element={<CreateBusinessProfilePage />} />
    </React.Fragment>,

    <React.Fragment key={'customers'}>
      <Route path={`/customers`} element={<CustomerListPage />} />
      <Route path={`/customers/:profile_id`} element={<CustomerDetailsPage />} />
      <Route path={`/customers/:profile_id/edit`} element={<EditCustomerPage />} />
      <Route path={`/customers/create`} element={<CreateCustomersPage />} />
    </React.Fragment>,

    <React.Fragment key={'suppliers'}>
      <Route path={`/suppliers`} element={<SupplierListPage />} />
      <Route path={`/suppliers/:profile_id`} element={<SupplierDetailsPage />} />
      <Route path={`/suppliers/:profile_id/edit`} element={<EditSupplierPage />} />
      <Route path={`/suppliers/create`} element={<CreateSuppliersPage />} />
    </React.Fragment>,

    <React.Fragment key={'documents'}>
      <Route path={`/documents`} element={<InvoiceListPage />} />
      <Route path={`/documents/invoices`} element={<InvoiceListPage />} />
      <Route path={`/documents/invoices/:document_id`} element={<InvoiceDetailsPage />} />
      <Route path={`/documents/invoices/create`} element={<CreateInvoicePage />} />
      <Route path={`/documents/credit-notes`} element={<CreditNoteListPage />} />
      <Route path={`/documents/credit-notes/:document_id`} element={<CreditNoteDetailsPage />} />
      <Route path={`/documents/credit-notes/create`} element={<CreateCreditNotePage />} />
      <Route path={`/documents/debit-notes`} element={<DebitNoteListPage />} />
      <Route path={`/documents/debit-notes/:document_id`} element={<DebitNoteDetailsPage />} />
      <Route path={`/documents/debit-notes/create`} element={<CreateDebitNotePage />} />
      <Route path={`/documents/refunds`} element={<RefundListPage />} />
      <Route path={`/documents/refunds/:document_id`} element={<RefundDetailsPage />} />
      <Route path={`/documents/refunds/create`} element={<CreateRefundPage />} />
    </React.Fragment>,

    <React.Fragment key={'drafts'}>
      <Route path={`/drafts`} element={<DraftDocumentListPage />} />
      <Route path={`/drafts/invoices/:document_id`} element={<DraftsInvoiceDetailsPage />} />
      <Route path={`/drafts/credit-notes/:document_id`} element={<DraftsCreditNoteDetailsPage />} />
      <Route path={`/drafts/debit-notes/:document_id`} element={<DraftsDebitNoteDetailsPage />} />
      <Route path={`/drafts/refunds/:document_id`} element={<DraftsRefundDetailsPage />} />
    </React.Fragment>,

    <React.Fragment key={'self-billed-drafts'}>
      <Route path={`/self-billed-drafts`} element={<SBDraftDocumentListPage />} />
      <Route path={`/self-billed-drafts/invoices/:document_id`} element={<SBDraftInvoiceDetailsPage />} />
      <Route path={`/self-billed-drafts/refunds/:document_id`} element={<SBDraftRefundDetailsPage />} />
      <Route path={`/self-billed-drafts/credit-notes/:document_id`} element={<SBDraftCreditNoteDetailsPage />} />
      <Route path={`/self-billed-drafts/debit-notes/:document_id`} element={<SBDraftDebitNoteDetailsPage />} />
    </React.Fragment>,

    <React.Fragment key={'self-billed-documents'}>
      <Route path={`/self-billed-documents`} element={<SBInvoiceListPage />} />
      <Route path={`/self-billed-documents/invoices`} element={<SBInvoiceListPage />} />
      <Route path={`/self-billed-documents/invoices/:document_id`} element={<SBInvoiceDetailsPage />} />
      <Route path={`/self-billed-documents/invoices/create`} element={<CreateSBInvoicePage />} />
      <Route path={`/self-billed-documents/credit-notes`} element={<SBCreditNoteListPage />} />
      <Route path={`/self-billed-documents/credit-notes/:document_id`} element={<SBCreditNoteDetailsPage />} />
      <Route path={`/self-billed-documents/credit-notes/create`} element={<CreateSBCreditNotePage />} />
      <Route path={`/self-billed-documents/debit-notes`} element={<SBDebitNoteListPage />} />
      <Route path={`/self-billed-documents/debit-notes/:document_id`} element={<SBDebitNoteDetailsPage />} />
      <Route path={`/self-billed-documents/debit-notes/create`} element={<CreateSBDebitNotePage />} />
      <Route path={`/self-billed-documents/refunds`} element={<SBRefundListPage />} />
      <Route path={`/self-billed-documents/refunds/:document_id`} element={<SBRefundDetailsPage />} />
      <Route path={`/self-billed-documents/refunds/create`} element={<CreateSBRefundPage />} />
    </React.Fragment>,

    <React.Fragment key={'settings'}>
      <Route path={`/settings`} element={<SettingsPage />} />
    </React.Fragment>,

    <React.Fragment key={'bulk-upload'}>
      <Route path={`/bulk-upload`} element={<BulkUploadListPage />} />
      <Route path={`/bulk-upload/:id`} element={<SupplierDetailsPage />} />
    </React.Fragment>,

    <React.Fragment key={'peppol-messages'}>
      <Route path={`/peppol-messages`} element={<PeppolMessagesListPage />} />
    </React.Fragment>,
  );
  return configuredFeatures;
};
export default AuthenticatedRoutes;
