import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import InvoicesDueAlert from './InvoicesDueAlert';
import Limit95Alert from './Limit95Alert';
import OpenInvoicesAlert from './OpenInvoicesAlert';
import Limit100Alert from './Limit100Alert';
import { Alert, Button, Snackbar, Stack } from '@mui/material';
import { useNavigate } from 'react-router-dom';

const snackbarStyles = {
  position: 'relative',
  '&.MuiSnackbar-root': {
    width: '100%',
    bottom: '0',
    '& .MuiPaper-root': {
      width: '100%',
      justifyContent: 'center',
    },
  },
  '& .MuiAlert-icon': {
    alignItems: 'center',
  },
};

const BottomBar = () => {
  const subscription = useSelector((state) => state.org.org.subscription);
  const navigate = useNavigate();
  const [openSnackbar, setOpenSnackbar] = useState({
    invoicesDue: false,
    limit100: false,
    openInvoices: false,
    limit95: false,
  });

  useEffect(() => {
    if (!subscription) {
      return;
    }

    const isLimitReached =
      subscription.plan.type === 'prepaid'
        ? subscription?.currentInvoice?.documents_count >= subscription?.documents_limit
        : subscription.plan.type === 'postpaid' &&
          subscription.plan.pricing_model === 'licensed' &&
          subscription?.currentInvoice?.documents_count >= subscription?.documents_limit;

    const isLimit95 =
      subscription.plan.type === 'prepaid'
        ? subscription?.currentInvoice?.documents_count >= 0.9 * subscription?.documents_limit
        : subscription.plan.type === 'postpaid' &&
          subscription.plan.pricing_model === 'licensed' &&
          subscription?.currentInvoice?.documents_count >= 0.9 * subscription?.documents_limit;

    if (subscription.status === 'past_due') {
      setOpenSnackbar({ invoicesDue: true, limit100: false, openInvoices: false, limit95: false });
    } else if (isLimitReached) {
      setOpenSnackbar({ invoicesDue: false, limit100: true, openInvoices: false, limit95: false });
    } else if (subscription?.dueInvoices?.some((invoice) => invoice.status === 'open')) {
      setOpenSnackbar({ invoicesDue: false, limit100: false, openInvoices: true, limit95: false });
    } else if (isLimit95) {
      setOpenSnackbar({ invoicesDue: false, limit100: false, openInvoices: false, limit95: true });
    }
  }, [subscription]);

  return (
    <Snackbar
      open={openSnackbar.openInvoices || openSnackbar.invoicesDue || openSnackbar.limit95 || openSnackbar.limit100}
      sx={snackbarStyles}
      anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
    >
      <Alert severity="warning">
        <Stack direction="row" alignItems="center" justifyContent="space-between">
          {openSnackbar.invoicesDue && <InvoicesDueAlert />}
          {openSnackbar.openInvoices && (
            <OpenInvoicesAlert
              dueInvoices={subscription?.dueInvoices}
              onClose={() => setOpenSnackbar({ ...openSnackbar, openInvoices: false })}
            />
          )}
          {openSnackbar.limit95 && (
            <Limit95Alert onClose={() => setOpenSnackbar({ ...openSnackbar, limit95: false })} />
          )}
          {openSnackbar.limit100 && <Limit100Alert />}

          <Button color="primary" variant="contained" onClick={() => navigate('/settings')}>
            Manage Subscriptions
          </Button>
        </Stack>
      </Alert>
    </Snackbar>
  );
};

export default BottomBar;
