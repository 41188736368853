import React, { useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { clearSelectedCreditNote, fetchCreditNoteById } from '../../../api/creditNotes/creditNotesSlice';
import { Box, Stack } from '@mui/material';
import '../../../styles/DocumentDetails.css';
import TopBar from '../../../components/TopBar';
import CreditNoteDetailsPageActionButton from '../components/CreditNoteDetailsPageActionButton';
import useDocumentTitle from '../../../hooks/useDocumentTitle';
import CreditNoteForm from '../components/CreditNoteForm';
import IrbmStatusCard from '../../common/IrbmStatusCard';
import PeppolResponseStatusCard from '../../common/PeppolResponseStatusCard';

export default function CreditNoteDetailsPage() {
  useDocumentTitle('Credit Note Details');
  const { document_id } = useParams();
  const dispatch = useDispatch();
  const creditNote = useSelector((state) => state.creditNotes.selectedCreditNote);

  useEffect(() => {
    if (document_id) {
      dispatch(fetchCreditNoteById(document_id));
    }
    //The function which is returned by useEffect will act as cleanup of what useEffect does
    return () => {
      dispatch(clearSelectedCreditNote());
    };
  }, [dispatch, document_id]);
  if (!creditNote) return null;

  return (
    <Stack direction="column" sx={{ mt: '-1rem' }}>
      {creditNote && (
        <Box sx={{ pb: 4 }}>
          <TopBar title="Credit Note Details" subTitle={creditNote?.document_id}>
            <CreditNoteDetailsPageActionButton />
          </TopBar>
        </Box>
      )}
      <IrbmStatusCard document={creditNote} />
      <PeppolResponseStatusCard document={creditNote} />

      <CreditNoteForm onSubmit={() => {}} onCancel={() => {}} initialData={creditNote} readOnly={true} />
    </Stack>
  );
}
