import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  peppolMessages: [],
  currentPage: 1,
  totalPages: 1,
  totalDocuments: 0,
  loading: false,
  error: null,
};

const peppolMessagesSlice = createSlice({
  name: 'peppolMessages',
  initialState,
  reducers: {
    fetchPeppolMessagesRequest(state) {
      state.loading = true;
      state.error = null;
    },
    fetchPeppolMessagesSuccess(state, action) {
      state.loading = false;
      state.peppolMessages = action.payload.peppol_messages;
      state.currentPage = action.payload.currentPage;
      state.totalPages = action.payload.totalPages;
      state.totalDocuments = action.payload.totalDocuments;
    },
    fetchPeppolMessagesFailure(state, action) {
      state.loading = false;
      state.error = action.payload;
    },
    downloadPeppolXmlRequest(state) {
      state.loading = true;
      state.error = null;
    },
    downloadPeppolXmlSuccess(state) {
      state.loading = false;
    },
    downloadPeppolXmlFailure(state, action) {
      state.loading = false;
      state.error = action.payload;
    },
  },
});

export const {
  fetchPeppolMessagesRequest,
  fetchPeppolMessagesSuccess,
  fetchPeppolMessagesFailure,
  downloadPeppolXmlRequest,
  downloadPeppolXmlSuccess,
  downloadPeppolXmlFailure,
} = peppolMessagesSlice.actions;

export default peppolMessagesSlice.reducer;
