import { InputAdornment, TextField, ToggleButton, ToggleButtonGroup } from '@mui/material';
import { Controller } from 'react-hook-form';
import React from 'react';

const NumberOrPercentageInput = ({ value, type, id, label, control, error, helperText, decimal = 2 }) => {
  return (
    <Controller
      name={value}
      control={control}
      render={({ field }) => (
        <TextField
          {...field}
          id={id}
          type={'number'}
          fullWidth
          label={label}
          variant="outlined"
          error={error}
          helperText={helperText}
          sx={{
            '& .MuiInputBase-root': {
              paddingRight: 0,
            },
          }}
          onBlur={() => {
            if (field.value) {
              field.onChange(parseFloat(field.value).toString());
            }
          }}
          onChange={(event) => {
            const inputValue = event.target.value;
            const regex = new RegExp(`^\\d*.?\\d{0,${decimal}}$`);
            if (regex.test(inputValue) || inputValue === '') {
              field.onChange(inputValue);
            }
          }}
          slotProps={{
            input: {
              endAdornment: (
                <InputAdornment position="end">
                  <Controller
                    name={type}
                    control={control}
                    render={({ field: toggleField }) => (
                      <ToggleButtonGroup
                        value={toggleField.value || 'absolute'}
                        exclusive
                        onChange={(event, newValue) => {
                          if (newValue !== null) {
                            toggleField.onChange(newValue);
                          }
                        }}
                        size="small"
                      >
                        <ToggleButton value="absolute">Absolute</ToggleButton>
                        <ToggleButton value="percentage">Percentage</ToggleButton>
                      </ToggleButtonGroup>
                    )}
                  />
                </InputAdornment>
              ),
            },
          }}
        />
      )}
    />
  );
};

export default NumberOrPercentageInput;
