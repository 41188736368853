import React, { useState } from 'react';
import { Button, Menu, MenuItem } from '@mui/material';
import InvoiceChargePopup from './InvoiceChargePopup';
import PrePaymentPopup from './InvoicePrePaymentPopup';
import InvoiceDiscountPopup from './InvoiceDiscountPopup';

export default function InvoiceElement({
  watch,
  chargeAppend,
  discountAppend,
  setChargeEditing,
  chargeEditing,
  discountEditing,
  setDiscountEditing,
  replaceCharge,
  replaceDiscount,
  addPrePayment,
  readOnly = false,
}) {
  const [anchorEl, setAnchorEl] = useState(null);
  const [chargePopupOpen, setChargePopupOpen] = useState(false);
  const [discountPopupOpen, setDiscountPopupOpen] = useState(false);
  const [prePaymentPopupOpen, setPrePaymentPopupOpen] = useState(false);

  const handleClickButton = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleCloseDropdown = () => {
    setAnchorEl(null);
  };

  const handleSelectCharge = () => {
    setAnchorEl(null);
    setChargePopupOpen(true);
  };

  const handleSelectDiscount = () => {
    setAnchorEl(null);
    setDiscountPopupOpen(true);
  };

  const handleSelectPrePayment = () => {
    setAnchorEl(null);
    setPrePaymentPopupOpen(true);
  };

  return (
    <div>
      {!readOnly && (
        <Button variant="contained" onClick={handleClickButton}>
          + Invoice Element
        </Button>
      )}
      <Menu anchorEl={anchorEl} open={Boolean(anchorEl)} onClose={handleCloseDropdown}>
        {watch('charges').length === 0 && <MenuItem onClick={handleSelectCharge}>Charge</MenuItem>}
        {watch('discounts').length === 0 && <MenuItem onClick={handleSelectDiscount}>Discount</MenuItem>}
        {!watch('pre_payment')?.amount && <MenuItem onClick={handleSelectPrePayment}>Pre Payment</MenuItem>}
      </Menu>

      <InvoiceChargePopup
        watch={watch}
        open={chargePopupOpen}
        onClose={() => setChargePopupOpen(false)}
        openPopup={() => setChargePopupOpen(true)}
        chargeAppend={chargeAppend}
        setChargeEditing={setChargeEditing}
        chargeEditing={chargeEditing}
        replaceCharge={replaceCharge}
      />
      <InvoiceDiscountPopup
        watch={watch}
        open={discountPopupOpen}
        onClose={() => setDiscountPopupOpen(false)}
        openPopup={() => setDiscountPopupOpen(true)}
        discountAppend={discountAppend}
        setDiscountEditing={setDiscountEditing}
        discountEditing={discountEditing}
        replaceDiscount={replaceDiscount}
      />
      <PrePaymentPopup
        open={prePaymentPopupOpen}
        onClose={() => setPrePaymentPopupOpen(false)}
        readOnly={readOnly}
        addPrePayment={addPrePayment}
      />
    </div>
  );
}
