import * as React from 'react';
import { useState } from 'react';
import Box from '@mui/material/Box';
import { Grid2 as Grid, useTheme } from '@mui/material';
import { useSelector } from 'react-redux';
import { DataGrid, GridActionsCellItem } from '@mui/x-data-grid';
import ItemFormModal from '../../../components/form/ItemFormModal';
import { get } from 'lodash';
import { currencyFormatter } from '../../../utils/currencyFormat';

let initialCurrentItemState = { index: -1, item: null };

export default function ItemDetailsTable({ items, remove, replace, add, readOnly }) {
  let theme = useTheme();
  const [open, setOpen] = React.useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);
  const [currentItem, setCurrentItem] = useState(initialCurrentItemState);
  const itemCodes = useSelector((state) => state.itemCodes.itemCodes);
  const uomCodes = useSelector((state) => state.uomCodes.uomCodes);
  const taxTypes = useSelector((state) => state.taxTypes.taxTypes);

  const itemCodesOptions = itemCodes.map((item) => {
    return { key: item.Code, value: item.Description };
  });
  const uomCodesOptions = uomCodes.map((item) => {
    return { key: item.Code, value: item.Name };
  });
  const taxTypesOptions = taxTypes.map((item) => {
    return { key: item.Code, value: item.Description };
  });

  const handleEditClick = (item, index) => {
    handleOpen();
    const unitCode = uomCodesOptions.find((type) => type.key === item.unit_of_measure);
    const itemCode = itemCodesOptions.find((type) => type.key === item.item_code);
    const currentItem = {
      ...item,
      unit_of_measure: unitCode,
      item_code: itemCode,
      taxes: item?.taxes.map(({ category, ...rest }) => ({
        category: taxTypesOptions.find((type) => type.key === category),
        ...rest,
      })),
    };
    setCurrentItem({ index, item: currentItem });
  };

  const replaceItem = (data) => {
    if (currentItem.index === -1) {
      add(data);
    } else {
      const updatedItems = [...items];
      updatedItems[currentItem.index] = data;
      replace(updatedItems);
      setCurrentItem(initialCurrentItemState);
    }
  };

  const columns = [
    {
      field: 'item_code',
      headerName: 'Item Classification Code',
      flex: 1,
      minWidth: 100,
      headerClassName: 'sticky-header-1',
      cellClassName: 'sticky-cell-1',
      valueGetter: (params) => {
        return itemCodesOptions.find((type) => type.key === params)?.value;
      },
    },
    {
      field: 'description',
      headerName: 'Description',
      flex: 2,
      minWidth: 150,
      headerClassName: 'sticky-header-2',
      cellClassName: 'sticky-cell-2',
    },
    {
      field: 'unit_of_measure',
      headerName: 'UOM',
      flex: 1,
      minWidth: 100,
      valueGetter: (params) => {
        return uomCodesOptions.find((type) => type.key === params)?.value;
      },
    },
    {
      field: 'quantity',
      headerName: 'Quantity',
      flex: 1,
      minWidth: 100,
      renderCell: ({ value }) => currencyFormatter().format(value),
    },
    {
      field: 'unit_rate',
      headerName: 'Unit Rate',
      flex: 1,
      minWidth: 120,
      renderCell: ({ value }) => currencyFormatter().format(value),
    },
    {
      field: 'base_amount',
      headerName: 'Base Amount',
      flex: 1,
      minWidth: 120,
      renderCell: ({ value }) => currencyFormatter().format(value),
    },
    {
      field: 'discount_amount',
      headerName: 'Discount',
      flex: 1,
      minWidth: 120,
      renderCell: (params) => {
        let discountType = get(params, 'row.discounts[0].type', '');
        discountType = discountType === 'percentage' ? `(${params.row.discounts[0].value}%)` : '';
        return `${currencyFormatter().format(params.row.discount_amount)} ${discountType}`;
      },
    },
    {
      field: 'charge_amount',
      headerName: 'Charge',
      flex: 2,
      minWidth: 200,
      renderCell: (params) => {
        let chargeType = get(params, 'row.charges[0].type', '');
        chargeType = chargeType === 'percentage' ? `(${params.row.charges[0]?.value}%)` : '';
        return `${currencyFormatter().format(params.row.charge_amount || 0)} ${chargeType}`;
      },
    },
    {
      field: 'net_amount',
      headerName: 'Total Before Tax',
      flex: 1,
      minWidth: 140,
      renderCell: ({ value }) => currencyFormatter().format(value),
    },
    {
      field: 'tax_amount',
      headerName: 'Tax',
      flex: 1,
      minWidth: 100,
      renderCell: (params) => {
        let taxType = get(params, 'row.taxes[0].type', '');
        taxType = taxType ? `(${params.row.taxes[0]?.value}%)` : '';
        return `${currencyFormatter().format(params.row.tax_amount)}  ${taxType}`;
      },
    },
    {
      field: 'taxes',
      headerName: 'Tax Category',
      flex: 1,
      minWidth: 120,
      valueGetter: (params) => {
        if (!params.length) {
          return '';
        }
        return taxTypesOptions.find((type) => type.key === params[0].category)?.value;
      },
    },
    {
      field: 'total_amount',
      headerName: 'Total Amount',
      flex: 1,
      minWidth: 120,
      headerClassName: readOnly ? 'sticky-header-last' : 'sticky-header-second-last',
      cellClassName: readOnly ? 'sticky-header-last' : 'sticky-header-second-last',
      renderCell: ({ value }) => currencyFormatter().format(value),
    },
  ];

  if (!readOnly) {
    columns.push({
      field: 'actions',
      type: 'actions',
      headerName: 'Actions',
      headerClassName: 'sticky-header-last',
      cellClassName: 'sticky-header-last',
      width: 80,
      sortable: false,
      filterable: false,
      getActions: (params) => [
        <GridActionsCellItem
          key={`edit-${params.id}`}
          label="Edit"
          onClick={() => {
            const rowIndex = items.findIndex((item) => item.id === params.row.id);
            handleEditClick(params.row, rowIndex);
          }}
          showInMenu
        />,
        <GridActionsCellItem
          key={`remove-${params.id}`}
          label="Remove"
          onClick={() => {
            const rowIndex = items.findIndex((item) => item.id === params.row.id);
            remove(rowIndex);
          }}
          showInMenu
        />,
      ],
    });
  }

  return (
    <Grid container>
      <Grid size={{ md: 12 }}>
        <Box
          sx={{
            mt: 1,
            width: '100%',
            overflow: 'auto',
            display: 'grid',
          }}
        >
          <DataGrid
            rows={items}
            getRowId={(row) => row.id || row._id}
            columns={columns}
            hideFooter={true}
            style={{ width: '100%' }}
            disableSelectionOnClick
            disableColumnMenu
            disableColumnSorting
            disableVirtualization
            sx={{
              overflow: 'auto',
              '& .sticky-header-1, & .sticky-cell-1': {
                position: 'sticky',
                left: 0,
                zIndex: 4,
                backgroundColor: '#fff',
              },
              '& .sticky-header-2, & .sticky-cell-2': {
                position: 'sticky',
                left: 100,
                zIndex: 4,
                backgroundColor: '#fff',
              },
              '& .sticky-header-second-last, & .sticky-cell-second-last': {
                position: 'sticky',
                right: 79,
                zIndex: 4,
                backgroundColor: '#fff',
              },
              '& .sticky-header-last, & .sticky-cell-last': {
                position: 'sticky',
                right: -1,
                zIndex: 4,
                backgroundColor: '#fff',
                '& .MuiButtonBase-root': {
                  backgroundColor: theme.palette.primary.main,
                  color: theme.palette.primary.contrastText,
                },
              },
            }}
          />
        </Box>
      </Grid>
      <ItemFormModal open={open} handleClose={handleClose} handleSubmit={replaceItem} initialData={currentItem.item} />
    </Grid>
  );
}
