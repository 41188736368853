import React from 'react';
import {
  Box,
  Divider,
  Grid2 as Grid,
  LinearProgress,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from '@mui/material';
import { useSelector } from 'react-redux';
import SubscriptionStatusChip from './SubscriptionStatusChip';
import moment from 'moment';
import { Link } from 'react-router-dom';
import './SubscriptionSection.css';
import InvoiceStatusChip from './InvoiceStausChip';

const SubscriptionSection = () => {
  const subscription = useSelector((state) => state.org.org.subscription);
  const isUnlimited = subscription?.documents_limit === 0;
  const currentDocumentCount = subscription?.currentInvoice?.documents_count || 0;

  if (!subscription) {
    return null;
  }
  const progress =
    subscription?.documents_limit > 0
      ? (currentDocumentCount / subscription?.documents_limit) * 100
      : isUnlimited
        ? 100
        : 0;
  return (
    <Paper elevation={0} sx={{ p: '1rem' }}>
      <Box sx={{ mb: '1.5rem' }}>
        <Typography variant="h6">Subscription Details</Typography>
        <Divider></Divider>
      </Box>
      <Grid container spacing={2}>
        <Grid size={12}>
          <Typography variant="title">{subscription?.plan?.name}</Typography>{' '}
          <SubscriptionStatusChip status={subscription.status} />
        </Grid>
        <Grid size={2}>
          <Typography variant="subtitle1" className={'label'}>
            Current Period:
          </Typography>
        </Grid>
        <Grid size={10}>
          <Typography variant="subtitle1">
            {moment.unix(subscription.current_period_start).format('ll')} to{' '}
            {moment.unix(subscription.current_period_end).format('ll')}
          </Typography>
        </Grid>
        <Grid size={2}>
          <Typography variant="subtitle1" className={'label'}>
            Payment Buffer:
          </Typography>
        </Grid>
        <Grid size={10}>
          <Typography variant="subtitle1">0</Typography>
        </Grid>
        <Grid size={2}>
          <Typography variant="subtitle1" className={'label'}>
            Started:
          </Typography>
        </Grid>
        <Grid size={10}>
          <Typography variant="subtitle1">{moment.unix(subscription.start_date).format('ll')}</Typography>
        </Grid>
        <Grid size={2}>
          <Typography variant="subtitle1" className={'label'}>
            Next Invoice:
          </Typography>
        </Grid>
        <Grid size={10}>
          <Typography variant="subtitle1">{moment.unix(subscription.current_period_end).format('ll')}</Typography>
        </Grid>
      </Grid>
      <Box sx={{ mt: '1.5rem', mb: '1.5rem' }}>
        <Typography variant="h6">Due Invoices</Typography>
        <Divider></Divider>
      </Box>
      <TableContainer component={Box}>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell>Amount</TableCell>
              <TableCell>Status</TableCell>
              <TableCell>Due Date</TableCell>
              <TableCell>Created</TableCell>
              <TableCell>Payment Link</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {subscription.dueInvoices.length ? (
              subscription.dueInvoices.map((row) => (
                <TableRow key={row._id}>
                  <TableCell component="th" scope="row">
                    {row.amount.toLocaleString('en-US', {
                      style: 'currency',
                      currency: process.env.REACT_APP_STRIPE_CURRENCY,
                    })}
                  </TableCell>
                  <TableCell>
                    <InvoiceStatusChip
                      invoiceStatus={new Date() > new Date(row.due_date * 1000) ? 'Past Due' : 'Open'}
                    />
                  </TableCell>
                  <TableCell>{moment.unix(row.due_date).format('ll')}</TableCell>
                  <TableCell>{moment(row.createdAt).format('ll')}</TableCell>
                  <TableCell>
                    <Link underline="none" target="_blank" to={row.invoice_payment_url}>
                      Pay Now
                    </Link>
                  </TableCell>
                </TableRow>
              ))
            ) : (
              <TableRow>
                <TableCell colSpan={5} align={'center'}>
                  No due invoices
                </TableCell>
              </TableRow>
            )}
          </TableBody>
        </Table>
      </TableContainer>
      <Box sx={{ mt: '1.5rem', mb: '1.5rem' }}>
        <Typography variant="h6">Usage Tracking</Typography>
        <Divider></Divider>
      </Box>
      <Box sx={{ mt: 2 }}>
        <Grid container spacing={2}>
          <Grid item xs={2}>
            <Typography variant="subtitle1" className={'label'}>
              Document Usage:
            </Typography>
          </Grid>
          <Grid item xs={10}>
            <Typography variant="subtitle1">
              {currentDocumentCount} / {isUnlimited ? '∞' : subscription?.documents_limit}
              {isUnlimited && (
                <Typography component="span" color="primary" sx={{ ml: 1 }}>
                  (Unlimited)
                </Typography>
              )}
            </Typography>
          </Grid>
        </Grid>
        <Box sx={{ mt: 2, width: '40%' }}>
          <LinearProgress
            variant="determinate"
            value={progress}
            sx={{
              height: '12px',
              borderRadius: '6px',
              backgroundColor: isUnlimited ? '#f5f5f5' : '#e0e0e0',
              '& .MuiLinearProgress-bar': {
                backgroundColor: isUnlimited ? '#2196f3' : progress >= 90 ? '#f44336' : '#2196f3',
                borderRadius: '6px',
              },
            }}
          />
          {isUnlimited || currentDocumentCount > 0 ? (
            <>
              {!isUnlimited && (
                <>
                  {progress >= 90 ? (
                    <Typography variant="caption" color="error" sx={{ mt: 0.5, display: 'block' }}>
                      {progress.toFixed(1)}% used
                    </Typography>
                  ) : (
                    <Typography variant="caption" color="textSecondary" sx={{ mt: 0.5, display: 'block' }}>
                      {progress.toFixed(1)}% used
                    </Typography>
                  )}
                </>
              )}
            </>
          ) : (
            <Typography variant="caption" color="textSecondary" sx={{ mt: 0.5, display: 'block' }}>
              0% used
            </Typography>
          )}
        </Box>
      </Box>
    </Paper>
  );
};

export default SubscriptionSection;
