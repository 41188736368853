import React, { useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { fetchBusinessProfileById } from '../../../api/businessProfiles/businessProfilesSlice';
import { Box, Stack } from '@mui/material';
import '../../../styles/DocumentDetails.css';
import TopBar from '../../../components/TopBar';
import BusinessDetailsPageActionButtons from '../components/BusinessDetailsPageActionButtons';
import useDocumentTitle from '../../../hooks/useDocumentTitle';
import BusinessProfileForm from '../components/BusinessProfileForm';

export default function BusinessProfileDetailsPage() {
  useDocumentTitle('Business Profile Details');
  const { profile_id } = useParams();
  const dispatch = useDispatch();
  const businessProfile = useSelector((state) => state.businessProfiles.selectedBusinessProfile);

  useEffect(() => {
    if (profile_id) {
      dispatch(fetchBusinessProfileById(profile_id));
    }
  }, [dispatch, profile_id]);

  if (!businessProfile) return null;

  return (
    <Stack direction="column" sx={{ mt: '-1rem' }}>
      {businessProfile && (
        <Box sx={{ pb: 4 }}>
          <TopBar title="Business Profile Details" subTitle={businessProfile?.profile_id}>
            <BusinessDetailsPageActionButtons />
          </TopBar>
        </Box>
      )}

      <BusinessProfileForm
        onSubmit={() => {}}
        apiErrors={{}}
        initialData={businessProfile}
        readOnly={true}
        edit={false}
        onCancel={() => {}}
      />
    </Stack>
  );
}
