import { call, put, takeLatest } from 'redux-saga/effects';
import { apiClient } from '../../utils/axios';
import {
  fetchOrgFailure,
  fetchOrgRequest,
  fetchOrgSuccess,
  fetchOrgSettingsRequest,
  fetchOrgSettingsSuccess,
  fetchOrgSettingsFailure,
  updateOrgSettingsRequest,
  updateOrgSettingsSuccess,
  updateOrgSettingsFailure,
} from './orgSlice';

function* fetchOrg(action) {
  try {
    const response = yield call(apiClient.get, `/organization`);
    yield put(fetchOrgSuccess(response.data));
  } catch (error) {
    yield put(fetchOrgFailure(error.response?.data?.errors?.message || 'Login failed'));
  }
}

function* fetchOrgSettingsSaga() {
  try {
    const response = yield call(apiClient.get, `/organizations/settings`);
    yield put(fetchOrgSettingsSuccess(response.data));
  } catch (error) {
    yield put(fetchOrgSettingsFailure(error.response?.data?.message || 'Failed to fetch settings'));
  }
}

function* updateOrgSettingsSaga(action) {
  try {
    const { updates } = action.payload;
    const response = yield call(apiClient.patch, `/organizations/settings/email`, updates);
    yield put(updateOrgSettingsSuccess(response.data.email_notifications));
  } catch (error) {
    yield put(updateOrgSettingsFailure(error.response?.data?.message || 'Failed to update settings'));
  }
}

export default function* orgSaga() {
  yield takeLatest(fetchOrgRequest.type, fetchOrg);
  yield takeLatest(fetchOrgSettingsRequest.type, fetchOrgSettingsSaga);
  yield takeLatest(updateOrgSettingsRequest.type, updateOrgSettingsSaga);
}
