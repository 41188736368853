import { Box, Button, Divider, Fade, IconButton, Modal, Stack, Typography } from '@mui/material';
import React from 'react';
import { useForm } from 'react-hook-form';
import CloseIcon from '@mui/icons-material/Close';
import CustomTextInput from '../../../components/CustomTextInput';
import { yupResolver } from '@hookform/resolvers/yup';
import { ImportExportValidation } from '../../../validations/ImportExportValidation';

const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: '500px',
  bgcolor: 'background.paper',
  borderRadius: '15px',
  boxShadow: 24,
};

const defaultState = {
  customs_form_reference_number: '',
  incoterms: '',
  fta: '',
  authorization_number: '',
  other_charges: 0,
  details_of_charge: '',
};

export default function AddImportExportDataPopup({ open, onClose, readOnly = false, addImportExportData }) {
  const {
    handleSubmit: handlePopupSubmit,
    control: popupControl,
    formState: { errors: popupErrors },
  } = useForm({
    defaultValues: defaultState,
    resolver: yupResolver(ImportExportValidation),
  });

  const handleFormSubmit = (data) => {
    const importExportData = {
      ...data,
    };

    Object.keys(importExportData).forEach((key) => {
      if (!importExportData[key]) {
        delete importExportData[key];
      }
    });
    addImportExportData(importExportData);

    onClose();
  };

  return (
    <Modal
      open={open}
      onClose={(event, reason) => {
        if (reason !== 'backdropClick') {
          onClose();
        }
      }}
      className={'backdrop-model'}
    >
      <Fade in={open}>
        <Box sx={style}>
          <Box
            sx={{
              display: 'flex',
              justifyContent: 'space-between',
              alignItems: 'center',
              px: 2,
              py: 2,
              borderBottom: '1px solid #e0e0e0',
            }}
          >
            <Typography variant="h6">Import Export Data</Typography>
            <IconButton onClick={onClose}>
              <CloseIcon />
            </IconButton>
          </Box>
          <Divider />
          <Box sx={{ p: 2 }}>
            <Stack spacing={2}>
              <CustomTextInput
                name="customs_form_1_9_reference_number"
                label="Reference Number of Customs Form No. 1,9 etc."
                control={popupControl}
                errors={popupErrors}
                readOnly={readOnly}
              />
              <CustomTextInput
                name="incoterms"
                label="Incoterms"
                control={popupControl}
                errors={popupErrors}
                readOnly={readOnly}
              />
              <CustomTextInput
                name="fta"
                label="Free Trade Agreement(FTA) Information"
                control={popupControl}
                errors={popupErrors}
                readOnly={readOnly}
              />
              <CustomTextInput
                name="authorization_number"
                label="Authorization Number"
                control={popupControl}
                errors={popupErrors}
                readOnly={readOnly}
              />
              <CustomTextInput
                name="customs_form_2_reference_number"
                label="Reference Number of Customs Form No. 2"
                control={popupControl}
                errors={popupErrors}
                readOnly={readOnly}
              />
              <CustomTextInput
                name="other_charges"
                label="Other Charges"
                control={popupControl}
                errors={popupErrors}
                type="number"
                readOnly={readOnly}
              />
              <CustomTextInput
                name="details_of_charge"
                label="Details Of Charge"
                control={popupControl}
                errors={popupErrors}
                readOnly={readOnly}
              />
            </Stack>
          </Box>
          <Divider />
          <Box
            sx={{
              px: 2,
              py: 1,
              borderTop: '1px solid #e0e0e0',
              display: 'flex',
              justifyContent: 'flex-end',
              gap: 1,
            }}
          >
            <Button type="button" variant="contained" color="primary" onClick={handlePopupSubmit(handleFormSubmit)}>
              Add Import/Export Data
            </Button>
          </Box>
        </Box>
      </Fade>
    </Modal>
  );
}
