import React, { useEffect, useState } from 'react';
import { Box, Button, Divider, Grid2 as Grid, Paper, Typography, useTheme } from '@mui/material';
import TopBar from '../../../components/TopBar';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import CountrySelect from '../../../components/form/CountrySelect';
import StateSelect from '../../../components/form/StateSelect';
import RegistrationTypeSelect from '../../../components/form/RegistrationTypeSelect';
import MSICCodeSelect from '../../../components/form/MSICCodeSelect';
import { BusinessProfileValidation } from '../../../validations/BusinessProfileValidation';
import { find, isEmpty, map } from 'lodash';
import { useDispatch, useSelector } from 'react-redux';
import CustomTextInput from '../../../components/CustomTextInput';
import PeppolRegistrationSteps from '../../../components/form/PeppolRegistrationsSteps';
import { fetchParticipantRequest, resetParticipant } from '../../../api/participants/participantsSlice';
import PeppolDetails from '../../../components/form/PeppolDetails';

export default function BusinessProfileForm({
  onSubmit,
  onCancel,
  initialData = {},
  apiErrors = {},
  edit = false,
  readOnly = false,
}) {
  const theme = useTheme();
  const dispatch = useDispatch();
  const { countryCodes } = useSelector((state) => state.countryCodes);
  const { states } = useSelector((state) => state.states);
  const { msicCodes } = useSelector((state) => state.msicCodes);
  let country = find(countryCodes, { Code: initialData.country_code });
  let state = find(states, { Code: initialData.state });
  let msicCode = find(msicCodes, { Code: initialData.msic_code });

  const participant = useSelector((state) => state.participants.participant);

  const [participantId, setParticipantId] = useState(initialData.participant_id || '');
  const [peppolParticipant, setPeppolParticipant] = useState(initialData.peppol_participant || {});
  const [loading, setLoading] = useState(false);
  const [loadingParticipant, setLoadingParticipant] = useState(false);

  const {
    register,
    control,
    getValues,
    setValue,
    watch,
    handleSubmit,
    setError,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(BusinessProfileValidation),
    defaultValues: {
      ...initialData,
      country_code: country ? { key: country.Code, value: country.Country } : '',
      state: state ? { key: state.Code, value: state.State } : '',
      msic_code: msicCode ? { key: msicCode.Code, value: msicCode.Description } : '',
      registration_type: initialData.registration_type
        ? {
            key: initialData.registration_type,
            value: initialData.registration_type,
          }
        : '',
      peppol_participant: initialData.peppol_participant ? initialData.peppol_participant : {},
      participant_id:
        initialData.peppol_participant?.identifier_code &&
        initialData.peppol_participant?.special_identifier &&
        initialData.peppol_participant?.identifier
          ? `${initialData.peppol_participant.identifier_code}:${initialData.peppol_participant.special_identifier}${initialData.peppol_participant.identifier}`
          : '',
    },
  });

  useEffect(() => {
    map(apiErrors, (value, key) => {
      setError(key, { message: value, type: 'string' });
    });
  }, [setError, apiErrors]);

  useEffect(() => {
    const currentParticipantId = getValues('participant_id');
    setParticipantId(currentParticipantId);
    if (currentParticipantId) {
      handleFetchParticipants();
    }

    return () => {
      dispatch(resetParticipant());
    };
  }, [participantId]);

  useEffect(() => {
    if (participantId) {
      setValue('participant_id', participantId);
    }
  }, [participantId]);

  useEffect(() => {
    if (peppolParticipant?.identifier_code && peppolParticipant?.special_identifier && peppolParticipant?.identifier) {
      const newParticipantId = `${peppolParticipant.identifier_code}:${peppolParticipant.special_identifier}${peppolParticipant.identifier}`;
      setParticipantId(newParticipantId);
    }
  }, [peppolParticipant]);

  const handleFetchParticipants = async () => {
    if (!participantId) return;

    setLoading(true);
    setLoadingParticipant(true);

    const [identifier_code, rest] = participantId.split(':');
    const match = rest ? rest.match(/(\d{2})(.*)/) : [];
    const [special_identifier, identifier] = match ? match.slice(1) : [];

    try {
      await dispatch(fetchParticipantRequest({ identifier_code, identifier, special_identifier }));
    } catch (error) {
      console.error('Error fetching participant:', error);
    } finally {
      setLoading(false);
    }
  };

  const isPeppolEmpty = !peppolParticipant?.endpoint_id;

  useEffect(() => {
    setLoadingParticipant(false);
    if (!isEmpty(participant)) {
      const match = participant.idValue?.match(/(\d{2})(.*)/);
      const [special_identifier, identifier] = match ? match.slice(1) : [];

      setPeppolParticipant({
        scheme: participant?.idScheme,
        identifier: identifier,
        identifier_code: participant?.idType,
        special_identifier: special_identifier,
        endpoint_id: participant?.endpoint[0]?.url,
      });
    }
    if (participantId === '') {
      setPeppolParticipant({});
    }
  }, [participant]);

  const handleFormSubmit = (data) => {
    data.participant_id = participantId;
    onSubmit(data);
  };

  return (
    <Paper elevation={0} sx={{ p: '1rem' }}>
      {!readOnly && (
        <Box sx={{ pt: 2, pb: 4 }}>
          <Grid
            container
            sx={{
              display: 'block',
            }}
          >
            <Grid>
              <TopBar title={'Business Profile Form'}>
                <Box>
                  <Button key={'cancel'} variant="outlined" onClick={onCancel} sx={{ marginLeft: theme.spacing(1) }}>
                    Cancel
                  </Button>
                  <Button
                    key={'save'}
                    variant="contained"
                    onClick={handleSubmit(handleFormSubmit)}
                    sx={{ marginLeft: theme.spacing(1) }}
                  >
                    Save
                  </Button>
                </Box>
              </TopBar>
            </Grid>
          </Grid>
        </Box>
      )}
      <Box>
        <Grid container spacing={2}>
          <Grid size={{ xs: 12, sm: 6 }}>
            <CustomTextInput name="name" control={control} errors={errors} readOnly={readOnly} label={'Name'} />
          </Grid>
          <Grid size={{ xs: 12, sm: 6 }}>
            <CustomTextInput
              name="company_name"
              control={control}
              errors={errors}
              readOnly={readOnly}
              label={'Company Name'}
            />
          </Grid>
          <Grid size={{ xs: 12, sm: 6 }}>
            <CustomTextInput
              name="company_name_in_local"
              control={control}
              errors={errors}
              readOnly={readOnly}
              label={'Company Name in Local Language'}
            />
          </Grid>
          <Grid size={{ xs: 12, sm: 6 }}>
            <CustomTextInput name="email" control={control} errors={errors} readOnly={readOnly} label={'Email'} />
          </Grid>
        </Grid>

        <Typography variant="h6" sx={{ mb: '1.5rem', pt: '1rem' }}>
          Address
          <Divider></Divider>
        </Typography>

        <Grid container spacing={2}>
          <Grid size={{ xs: 12, sm: 6 }}>
            <CustomTextInput
              name="address1"
              control={control}
              errors={errors}
              readOnly={readOnly}
              label={'Address Line 1'}
            />
          </Grid>
          <Grid size={{ xs: 12, sm: 6 }}>
            <CustomTextInput
              name="address2"
              control={control}
              errors={errors}
              readOnly={readOnly}
              label={'Address Line 2'}
            />
          </Grid>
          <Grid size={{ xs: 12, sm: 6 }}>
            <CustomTextInput
              name="address3"
              control={control}
              errors={errors}
              readOnly={readOnly}
              label={'Address Line 3'}
            />
          </Grid>
          <Grid size={{ xs: 12, sm: 6 }}>
            <CustomTextInput name="city" control={control} errors={errors} readOnly={readOnly} label={'City'} />
          </Grid>
          <Grid size={{ xs: 12, sm: 6 }}>
            <CountrySelect
              name="country_code"
              control={control}
              errors={errors}
              readOnly={readOnly}
              label={'Country'}
            />
          </Grid>
          <Grid size={{ xs: 12, sm: 6 }}>
            <StateSelect errors={errors} control={control} name="state" readOnly={readOnly} label={'State'} />
          </Grid>
          <Grid size={{ xs: 12, sm: 6 }}>
            <CustomTextInput
              name="postal_code"
              control={control}
              errors={errors}
              readOnly={readOnly}
              label={'Postal Code'}
            />
          </Grid>
          <Grid size={{ xs: 12, sm: 6 }}>
            <CustomTextInput name="phone" control={control} errors={errors} readOnly={readOnly} label={'Phone'} />
          </Grid>
        </Grid>

        <Typography variant="h6" sx={{ mb: '1.5rem', pt: '1rem' }}>
          Business Identifiers
          <Divider></Divider>
        </Typography>

        <Grid container spacing={2}>
          <Grid size={{ xs: 12, sm: 6 }}>
            <RegistrationTypeSelect errors={errors} readOnly={readOnly} control={control} name="registration_type" />
          </Grid>
          <Grid size={{ xs: 12, sm: 6 }}>
            <CustomTextInput
              name="registration_number"
              control={control}
              errors={errors}
              readOnly={readOnly}
              label={'Registration Number'}
            />
          </Grid>
          <Grid size={{ xs: 12, sm: 6 }}>
            <CustomTextInput name="tin" control={control} errors={errors} readOnly={readOnly || edit} label={'TIN'} />
          </Grid>
          <Grid size={{ xs: 12, sm: 6 }}>
            <CustomTextInput
              name="sst_registration_number"
              control={control}
              errors={errors}
              readOnly={readOnly}
              label={'SST Registration Number'}
            />
          </Grid>
          <Grid size={{ xs: 12, sm: 6 }}>
            <CustomTextInput
              name="tourism_tax_registration_number"
              control={control}
              errors={errors}
              readOnly={readOnly}
              label={'Tourism Tax Registration Number'}
            />
          </Grid>
          <Grid size={{ xs: 12, sm: 6 }}>
            <MSICCodeSelect readOnly={readOnly} errors={errors} control={control} name="msic_code" />
          </Grid>
        </Grid>

        {edit && (
          <Typography variant="h6" sx={{ mb: '1.5rem', pt: '1rem' }}>
            Peppol Registration
            <Divider></Divider>
          </Typography>
        )}

        {edit && isPeppolEmpty && (
          <Grid container spacing={2}>
            <Grid size={{ xs: 12, sm: 12 }}>
              <PeppolRegistrationSteps
                profileId={initialData?.profile_id}
                control={control}
                errors={errors}
                getValues={getValues}
                setParticipantId={setParticipantId}
                handleFetchParticipants={handleFetchParticipants}
                loading={loading}
                readOnly={readOnly}
                loadingParticipant={loadingParticipant}
                isPeppolEmpty={isPeppolEmpty}
                peppolParticipant={peppolParticipant}
              />
            </Grid>
          </Grid>
        )}

        {(readOnly || (edit && !isPeppolEmpty)) && (
          <Grid container spacing={2}>
            <Grid size={{ xs: 12, sm: 12 }}>
              <PeppolDetails
                control={control}
                errors={errors}
                getValues={getValues}
                setParticipantId={setParticipantId}
                handleFetchParticipants={handleFetchParticipants}
                loading={loading}
                readOnly={true}
                loadingParticipant={loadingParticipant}
                isPeppolEmpty={isPeppolEmpty}
                peppolParticipant={peppolParticipant}
              />
            </Grid>
          </Grid>
        )}
      </Box>
    </Paper>
  );
}
