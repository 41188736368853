import React from 'react';
import { useNavigate } from 'react-router-dom';
import { Button } from '@mui/material';
import { useTheme } from '@mui/material/styles';

const CreditNoteDetailsPageActionButton = () => {
  const theme = useTheme();
  const navigate = useNavigate();

  const onClickBackButton = () => {
    return navigate('/documents/credit-notes');
  };

  return (
    <>
      <Button
        key="back"
        variant="contained"
        color="primary"
        onClick={onClickBackButton}
        className="action-button"
        sx={{ marginLeft: theme.spacing(1) }}
      >
        Back
      </Button>
    </>
  );
};

export default CreditNoteDetailsPageActionButton;
