import React, { useEffect } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { clearSelectedCreditNote, fetchCreditNoteById } from '../../../api/creditNotes/creditNotesSlice';
import { Box, Stack } from '@mui/material';
import TopBar from '../../../components/TopBar';
import useDocumentTitle from '../../../hooks/useDocumentTitle';
import SBDetailsPageActionButtons from '../components/SBDraftDetailsPageActions';
import SBCreditNoteForm from '../../selfBilledCreditNotes/components/SBCreditNoteForm';

export default function SBDraftCreditNoteDetailsPage() {
  useDocumentTitle('Self Billed Credit Note Details');
  const { document_id } = useParams();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const creditNote = useSelector((state) => state.creditNotes.selectedCreditNote);

  useEffect(() => {
    if (document_id) {
      dispatch(fetchCreditNoteById(document_id));
    }
    //The function which is returned by useEffect will act as cleanup of what useEffect does
    return () => {
      dispatch(clearSelectedCreditNote());
    };
  }, [dispatch, document_id]);
  if (!creditNote) return null;

  const handleBack = () => {
    navigate('/self-billed-drafts?tab=creditNotes');
  };

  if (!creditNote) return null;

  return (
    <Stack direction="column" sx={{ mt: '-1rem' }}>
      <Box sx={{ pb: 4 }}>
        <TopBar title="Credit Note Details" subTitle={creditNote?.document_id}>
          <SBDetailsPageActionButtons onBack={handleBack} />
        </TopBar>
      </Box>
      <SBCreditNoteForm onSubmit={() => {}} onCancel={() => {}} initialData={creditNote} readOnly={true} />
    </Stack>
  );
}
