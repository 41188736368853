import React, { useState } from 'react';
import { Button } from '@mui/material';
import AddShippingDetailsPopup from './AddShippingDetailsPopup';

export default function AddShippingDetailsButton({ readOnly = false, addShippingData }) {
  const [shippingDetailsPopupOpen, setShippingDetailsPopupOpen] = useState(false);

  const handleClickButton = () => {
    setShippingDetailsPopupOpen(true);
  };

  const handleClose = () => {
    setShippingDetailsPopupOpen(false);
  };

  return (
    <div>
      {!readOnly && (
        <Button variant="contained" onClick={handleClickButton}>
          + Shipping Details
        </Button>
      )}
      <AddShippingDetailsPopup
        open={shippingDetailsPopupOpen}
        handleClose={handleClose}
        addShippingData={addShippingData}
      />
    </div>
  );
}
