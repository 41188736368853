import { call, put, takeLatest } from 'redux-saga/effects';
import { apiClient } from '../../utils/axios';
import {
  downloadBulkUploadReportFailure,
  downloadBulkUploadReportRequest,
  downloadBulkUploadReportSuccess,
  downloadTemplateFailure,
  downloadTemplateRequest,
  downloadTemplateSuccess,
  fetchBulkUploadById,
  fetchBulkUploadByIdFailure,
  fetchBulkUploadByIdSuccess,
  fetchBulkUploadFailure,
  fetchBulkUploadRequest,
  fetchBulkUploadSuccess,
  proceedBulkUploadFailure,
  proceedBulkUploadRequest,
  proceedBulkUploadSuccess,
} from './bulkUploadSlice';

function* fetchBulkUploadSaga(action) {
  try {
    const { query } = action.payload;
    const response = yield call(apiClient.get, '/bulk-uploads', { params: query });
    yield put(fetchBulkUploadSuccess(response.data));
  } catch (error) {
    yield put(fetchBulkUploadFailure(error.message));
  }
}

function* fetchBulkUploadByIdSaga(action) {
  try {
    const response = yield call(apiClient.get, `/bulk-uploads/${action.payload}`);
    yield put(fetchBulkUploadByIdSuccess(response.data));
  } catch (error) {
    yield put(fetchBulkUploadByIdFailure(error.message));
  }
}

function* proceedBulkUploadSaga(action) {
  try {
    const formData = new FormData();
    formData.append('file', action.payload.file);

    const response = yield call(apiClient.post, '/bulk-uploads', formData, {
      headers: {
        'Content-Type': 'multipart/form-data',
      },
    });
    yield put(proceedBulkUploadSuccess(response.data));
  } catch (error) {
    yield put(proceedBulkUploadFailure(error.response.data.error));
  }
}

function* downloadBulkUploadReportSaga(action) {
  try {
    const { query } = action.payload;
    const { id } = action.payload;

    const response = yield call(apiClient.get, `/bulk-uploads/${id}/download`, { params: query, responseType: 'blob' });
    const url = window.URL.createObjectURL(new Blob([response.data]));
    const link = document.createElement('a');
    link.href = url;
    link.setAttribute('download', response.headers.get('x-suggested-filename'));
    document.body.appendChild(link);
    link.click();
    link.parentNode.removeChild(link);
    yield put(downloadBulkUploadReportSuccess());
  } catch (error) {
    yield put(downloadBulkUploadReportFailure(error.message));
  }
}

function* downloadTemplateSaga(action) {
  try {
    const format = action.payload.format || 'xlsx';
    const response = yield call(apiClient.get, `/bulk-uploads/template/download?format=${format}`, {
      responseType: 'blob',
    });
    const url = window.URL.createObjectURL(new Blob([response.data]));
    const link = document.createElement('a');
    link.href = url;
    link.setAttribute('download', `b2b_bulk_upload_template.${format}`);
    document.body.appendChild(link);
    link.click();
    link.parentNode.removeChild(link);
    yield put(downloadTemplateSuccess());
  } catch (error) {
    yield put(downloadTemplateFailure(error.message));
  }
}

function* businessProfilesSaga() {
  yield takeLatest(fetchBulkUploadRequest.type, fetchBulkUploadSaga);
  yield takeLatest(fetchBulkUploadById.type, fetchBulkUploadByIdSaga);
  yield takeLatest(proceedBulkUploadRequest.type, proceedBulkUploadSaga);
  yield takeLatest(downloadBulkUploadReportRequest.type, downloadBulkUploadReportSaga);
  yield takeLatest(downloadTemplateRequest.type, downloadTemplateSaga);
}

export default businessProfilesSaga;
