import React, { useState } from 'react';
import { IconButton, Menu, MenuItem } from '@mui/material';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import { useDispatch } from 'react-redux';
import { downloadBulkUploadReportRequest } from '../../../api/bulkUpload/bulkUploadSlice';
import { showSnackbar } from '../../../api/snackbarSlice';

const BulkUploadActionsMenu = ({ row }) => {
  const dispatch = useDispatch();
  const [anchorEl, setAnchorEl] = useState(null);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleDownloadFile = (downloadType) => {
    dispatch(showSnackbar({ message: 'Downloading...', severity: 'info' }));
    dispatch(
      downloadBulkUploadReportRequest({
        query: {
          downloadType: downloadType,
        },
        id: row?._id,
      }),
    );
    handleClose();
  };

  const actionsDisabled = row.status === 'upload_failed' || row.status === 'uploaded';

  return (
    <>
      <IconButton onClick={handleClick} disabled={actionsDisabled}>
        <MoreVertIcon />
      </IconButton>
      <Menu
        anchorEl={anchorEl}
        keepMounted
        open={Boolean(anchorEl)}
        onClose={handleClose}
        slotProps={{
          paper: {
            style: {
              boxShadow: '4px 4px 10px 0px rgba(0,0,0,0.2)',
            },
          },
        }}
      >
        {row.error_documents_count > 0 && <MenuItem onClick={() => handleDownloadFile('error')}>Error Report</MenuItem>}
        {row.processed_documents_count > 0 && (
          <MenuItem onClick={() => handleDownloadFile('success')}>Success Report</MenuItem>
        )}
        <MenuItem onClick={() => handleDownloadFile('log')}>Logs</MenuItem>
        <MenuItem onClick={() => handleDownloadFile('original')}>Original File</MenuItem>
      </Menu>
    </>
  );
};

export default BulkUploadActionsMenu;
