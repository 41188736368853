import React, { useState } from 'react';
import EditIcon from '@mui/icons-material/Edit';
import { IconButton } from '@mui/material';
import PrePaymentPopup from './InvoicePrePaymentPopup';

const EditInvoicePrePaymentButton = ({ initialValue, addPrePayment, readOnly }) => {
  const [prePaymentPopupOpen, setPrePaymentPopupOpen] = useState(false);

  return (
    <div>
      <IconButton aria-label="edit" onClick={() => setPrePaymentPopupOpen(true)} color={'primary'}>
        <EditIcon />
      </IconButton>
      <PrePaymentPopup
        open={prePaymentPopupOpen}
        onClose={() => setPrePaymentPopupOpen(false)}
        readOnly={readOnly}
        addPrePayment={addPrePayment}
        initialValue={initialValue}
      />
    </div>
  );
};

export default EditInvoicePrePaymentButton;
