import React, { useCallback, useEffect } from 'react';
import { Autocomplete, CircularProgress, TextField } from '@mui/material';
import { useDispatch, useSelector } from 'react-redux';
import { fetchInvoicesRequest } from '../../api/invoices/invoicesSlice';
import { debounce } from 'lodash';

const InvoiceSelect = ({ onSelect, document_type, searchTerm, setSearchTerm }) => {
  const dispatch = useDispatch();
  const { invoices, loading } = useSelector((state) => state.invoices);

  const modifySearchTerm = useCallback(
    debounce((value) => {
      setSearchTerm(value);
    }, 500),
    [],
  );

  useEffect(() => {
    dispatch(
      fetchInvoicesRequest({
        query: {
          document_type: document_type,
          page: 1,
          limit: 10,
          document_number: searchTerm || undefined,
        },
      }),
    );
  }, [searchTerm, dispatch]);

  return (
    <Autocomplete
      options={invoices}
      loading={loading}
      getOptionLabel={(option) =>
        `${option?.document_number} | ${option?.document_date} | ${option?.grand_total} | ${option?.billed_by?.name}`
      }
      getOptionKey={(option) => option?._id}
      renderInput={(params) => (
        <TextField
          {...params}
          label="Select Invoice"
          variant="outlined"
          InputProps={{
            ...params.InputProps,
            endAdornment: (
              <>
                {loading ? <CircularProgress color="inherit" size={20} /> : null}
                {params.InputProps.endAdornment}
              </>
            ),
          }}
        />
      )}
      onChange={(_, value) => onSelect(value)}
      onInputChange={(event, value, reason) => {
        if (reason === 'input') {
          modifySearchTerm(value);
        }
      }}
      filterOptions={(x) => x}
    />
  );
};

export default InvoiceSelect;
